<template>
    <div class="login-details-container">
        <CForm @submit.prevent="handleFormSubmit" autocomplete="off">
          <CRow class="mb-3">
            <CFormLabel
              class="col-sm-4 col-form-label"
              for="card-login-email">
              Email:
            </CFormLabel>

            <CFormInput
              id="card-login-email"
              name="card-login-email"
              type="email"
              placeholder="Enter email"
              :value="cardDetailsFormData.cardLoginEmail"
              @input="handleInputDataChange($event, cardDriver.dataMappings.cardLoginEmail.clientKey)"/>
          </CRow>

          <CRow class="mb-3">
            <CFormLabel
              class="col-sm-4 col-form-label"
              for="card-login-password">
              Password:
            </CFormLabel>

            <CFormInput
              id="card-login-password"
              name="card-login-password"
              type="password"
              placeholder="Enter password"
              :value="cardDetailsFormData.cardLoginPassword"
              @input="handleInputDataChange($event, cardDriver.dataMappings.cardLoginPassword.clientKey)"/>
          </CRow>

          <CRow class="mb-3 action-buttons">
                <CButton
                    color="info"
                    size="sm"
                    variant="outline"
                    @click="handleCancel">
                    Cancel
                </CButton>

                <CButton
                    class="save-button"
                    color="info"
                    size="sm"
                    type="submit">
                    Save
                </CButton>
            </CRow>
        </CForm>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { routeNames } from '@/config/routes';
import cardDriver from '@/drivers/CardDriver';

export default {
  name: 'LoginDetails',
  data () {
    return {
      cardDriver,
      cardDetailsFormData: cardDriver.createClientFormData(this.card, [
        cardDriver.dataMappings.cardLoginEmail.clientKey,
        cardDriver.dataMappings.cardLoginPassword.clientKey,
      ]),
    };
  },
  props: {
    card: Object,
  },
  methods: {
    ...mapActions(['updateCardLoginDetails']),
    ...mapMutations(['addAlert']),
    handleInputDataChange (value, propertyChanged) {
      this.cardDetailsFormData[propertyChanged] = value;
    },
    handleCancel () {
      this.$router.push({
        name: routeNames.SINGLE_CARD,
        cardId: this.card.cardId,
      });
    },
    handleFormSubmit () {
      this.updateCardLoginDetails({
        card: {
          cardId: this.card.cardId,
          ...this.cardDetailsFormData,
        },
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Login Details Edited Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Card Login Details Edit Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .login-details-container {
      max-width: 700px;
      background-color: var(--white);
      padding: 30px 80px 10px;

      input {
        background-color: var(--background-grey);
      }
    }

    .col-form-label {
        padding: 0;
        font-size: 14px;
        font-weight: 600;
    }

    .action-buttons {
        justify-content: right;
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }
</style>

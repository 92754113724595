import { decode } from 'js-base64';
import { userPermissions } from '@/config/security';
import User from '@/models/User';
import authenticationDriver from '../drivers/AuthenticationDriver';

class UserService {
    currentUser = null;

    createUserFromToken (token) {
      const authenticationData = JSON.parse(decode(token).split('}')[1] + '}');

      const { username, email, role, id } = authenticationDriver.formatServerData(authenticationData);

      return new User(username, email, role, id);
    }

    setCurrentUser (user) {
      this.currentUser = user;
    }

    getUsername () {
      return this.currentUser?.getUsername();
    }

    getUserEmail () {
      return this.currentUser?.getEmail();
    }

    getUserId () {
      return this.currentUser?.getId();
    }

    getCurrentUserPermissions () {
      return userPermissions[this.currentUser?.getRole()];
    }

    getLandingRoute () {
      return this.getCurrentUserPermissions().routes[0];
    }

    hasRouteAccess (route) {
      return this.getCurrentUserPermissions().routes.includes(route);
    }

    hasFeatureAccess (feature) {
      return this.getCurrentUserPermissions().features.includes(feature);
    }

    addRouteAccess (route) {
      this.getCurrentUserPermissions().routes.push(route);
    }

    addFeatureAccess (feature) {
      this.getCurrentUserPermissions().features.push(feature);
    }
};

export default new UserService();

<template>
  <div class="card-list-wrapper">
      <template v-if="cards.length">
        <CardItem
          v-for="(card, index) in cards"
          :key="index"
          :card="card"/>
      </template>

      <div v-if="!cards.length && !isFetchingInProgress" class="no-cards-message">
        This Account Has no Cards
      </div>

      <AddCardItem
        v-if="userCanAddCards && isAddCardItemDisplayed"
        :accountId="account.accountId"/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { features } from '@/config/features';
import userService from '@/services/UserService';
import CardItem from '@/components/admin/shared/CardItem';
import AddCardItem from '@/components/admin/accounts/single-account-view/AddCardItem';

export default {
  name: 'Cards',
  components: {
    CardItem,
    AddCardItem,
  },
  props: {
    account: Object,
    isAddCardItemDisplayed: Boolean,
  },
  computed: {
    ...mapGetters(['getCardsById']),
    ...mapState({
      isFetchingInProgress: state => state.isFetchingCards,
    }),
    userCanAddCards () {
      return userService.hasFeatureAccess(features.ADD_CARD);
    },
    cards () {
      return this.getCardsById({
        accountId: this.account.accountId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/sidebar';

    .card-list-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: $standard-border-width;
        justify-content: space-between;
    }

    .no-cards-message {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
</style>

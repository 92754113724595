import axios from 'axios';
import { endpoints } from '@/config/endpoints';
import accountDriver from '@/drivers/AccountDriver';
import adminAccountDriver from '@/drivers/AdminAccountDriver';
import cardDriver from '@/drivers/CardDriver';
import cardTypeDriver from '@/drivers/CardTypeDriver';

class AccountService {
  async getAllAccounts () {
    try {
      const { data } = await axios.get(`${endpoints.accounts}`);

      const formatedData = data?.content?.map(account => {
        return accountDriver.formatServerData(account);
      });

      return formatedData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAllAdminAccounts () {
    try {
      const { data } = await axios.get(`${endpoints.adminAccounts}`);

      const formatedData = data?.content?.map(account => {
        return adminAccountDriver.formatServerData(account);
      });

      return formatedData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUserAccounts (userId) {
    try {
      const { data } = await axios.get(`${endpoints.managedAccounts}/${userId}`);

      const formatedData = data?.content?.map(account => accountDriver.formatServerData(account));

      return formatedData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createAccount (accountName) {
    try {
      const { data } = await axios.post(`${endpoints.accounts}`, {
        [accountDriver.dataMappings.accountName.serverKey]: accountName,
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createAdminAccount (accountName) {
    try {
      const { data } = await axios.post(`${endpoints.adminAccounts}`, {
        [adminAccountDriver.dataMappings.accountName.serverKey]: accountName,
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAccount (account) {
    try {
      const formatedData = accountDriver.formatClientData(account);

      const { data } = await axios.put(`${endpoints.accounts}/${account.accountId}`, formatedData);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAdminAccount (accountData) {
    try {
      const formatedData = adminAccountDriver.formatClientData(accountData);

      const { data } = await axios.put(`${endpoints.adminAccounts}`, formatedData);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAccountLoginDetails (account) {
    try {
      const formatedData = accountDriver.formatClientData(account);

      const { data } = await axios.put(`${endpoints.accounts}/${account.accountId}/login-details`, formatedData);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAccountThemes (account) {
    try {
      const { data } = await axios.post(`${endpoints.accounts}/${account.accountId}/themes`, account.themes);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteAccount (accountId) {
    try {
      const { data } = await axios.delete(`${endpoints.accounts}/${accountId}`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteAdminAccount (accountId) {
    try {
      const { data } = await axios.delete(`${endpoints.adminAccounts}/${accountId}`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAllCards () {
    try {
      const { data } = await axios.get(endpoints.cards);

      const formatedData = data?.content?.map(card => cardDriver.formatServerData(card));

      return formatedData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getUserCards (userId) {
    try {
      const { data } = await axios.get(`${endpoints.managedCards}/${userId}`);

      const formatedData = data?.content?.map(card => cardDriver.formatServerData(card));

      return formatedData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getCardTypeById (id) {
    try {
      const { data } = await axios.get(`${endpoints.cards}/${id}`);

      const formatedData = cardTypeDriver.formatServerData(data.content);

      return formatedData;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createCard (accountId, cardOwnerName) {
    try {
      const { data } = await axios.post(`${endpoints.cards}`, {
        [cardDriver.dataMappings.accountId.serverKey]: accountId,
        [cardDriver.dataMappings.cardOwnerName.serverKey]: cardOwnerName,
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async changeCardOwnerName (cardId, cardOwnerName) {
    try {
      const { data } = await axios.patch(`${endpoints.cards}/${cardId}/name`, {
        [cardDriver.dataMappings.cardOwnerName.serverKey]: cardOwnerName,
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async changeCardOwnership (cardId, newAccountId) {
    try {
      const { data } = await axios.patch(`${endpoints.cards}/${cardId}/ownership`, {
        [cardDriver.dataMappings.accountId.serverKey]: newAccountId,
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCard (cardId, cardTypeId, cardType) {
    try {
      const formatedData = cardTypeDriver.formatClientData(cardType);

      const { data } = await axios.put(`${endpoints.cards}/${cardId}/info/${cardTypeId}`, formatedData);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setActiveCard (cardId, cardTypeId) {
    try {
      const { data } = await axios.put(`${endpoints.cards}/${cardId}/info/${cardTypeId}/active`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateCardLoginDetails (card) {
    try {
      const formatedData = cardDriver.formatClientData(card);

      const { data } = await axios.put(`${endpoints.cards}/${card.cardId}/login-details`, formatedData);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteCard (cardId) {
    try {
      const { data } = await axios.delete(`${endpoints.cards}/${cardId}`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addCardType (cardId) {
    try {
      const { data } = await axios.put(`${endpoints.cards}/${cardId}/new-card-type`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteCardType (cardId, cardTypeId) {
    try {
      const { data } = await axios.delete(`${endpoints.cards}/${cardId}/info/${cardTypeId}`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addCardPhoto (cardId) {
    try {
      const formData = new FormData();
      const imageFile = document.querySelector('#card-photo');

      formData.append('card-photo', imageFile.files[0]);

      const { data } = await axios.patch(`${endpoints.cards}/${cardId}/profile-picture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveAndroidCard (cardId, color) {
    try {
      const { data } = await axios.post('/api/wallets/google', { cardId, color });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getStatistics () {
    try {
      const { data } = await axios.get(endpoints.statistics);

      return data.content;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadTexture (account) {
    try {
      const formData = new FormData();
      const imageFile = document.querySelector('#custom-texture');

      formData.append('texture', imageFile.files[0]);
      const { data } = await axios.post(`${endpoints.accounts}/${account.accountId}/themes/texture`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteTexture (account, textureName) {
    try {
      const { data } = await axios.delete(`${endpoints.accounts}/${account.accountId}/themes/texture/${textureName}`);

      return data.message;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default new AccountService();

<template>
  <CForm @submit.prevent="handleCardCreate">
    <div class="add-card-item">
        <div class="add-card-input-wrapper">
            <CFormInput
                placeholder="Enter Card Name"
                required
                v-model="newCardOwnerName"/>
        </div>

        <div class="add-card-date">
            {{ currentDate }}
        </div>

        <div class="action-buttons-wrapper">
            <CButton
                type="submit"
                color="info">
                Add
            </CButton>
        </div>
    </div>
  </CForm>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'AddCardItem',
  data () {
    return {
      newCardOwnerName: '',
      currentDate: DateTime.now().toFormat('dd.MM.yyyy'),
    };
  },
  props: {
    accountId: String,
  },
  methods: {
    ...mapActions(['createCard']),
    ...mapMutations(['addAlert']),
    handleCardCreate () {
      this.createCard({
        accountId: this.accountId,
        cardOwnerName: this.newCardOwnerName,
      })
        .then(() => {
          this.newCardOwnerName = '';
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Added Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Card Add Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';
  @import '@/assets/scss/sidebar';

  .add-card-item {
    background-color: var(--white);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    width: 100%;
    font-size: 14px;
  }

  .add-card-date {
    color: var(--light-grey);
    margin-left: 5px;
  }

  .add-card-input-wrapper {
    width: 50%;

    input {
     background-color: var(--background-grey);
     border: none;
     border-radius: 20px;
     font-size: 14px;
    }
  }

  .action-buttons-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: end;
    width: 40%;
    max-width: 200px;

    button {
        font-size: 12px;
        color: var(--white);
        margin: 0 5px;
        width: 110px;
    }
  }
</style>

import axios from 'axios';
import router from '@/router';
import { localStorageEntries } from '@/config/storageEntries';
import { routeNames } from '@/config/routes';
import { endpoints } from '@/config/endpoints';

class AuthenticationService {
  storageKey = localStorageEntries.authenticationToken;
  authenticationToken = null;

  async login (email, password) {
    try {
      const query = `email=${email}&password=${password}`;

      const { data } = await axios.get(`${endpoints.authentication}?${query}`);

      this.setAuthenticationToken(data.content);
    } catch (error) {
      alert('Incorrect login info');
    }
  }

  async forgotPassword (email) {
    try {
      await axios.post('api/authentication/forgot-password', { email });

      alert('Password sent to mail!');
    } catch (error) {
      alert('Incorrect email information!');
    }
  }

  logout () {
    localStorage.removeItem(this.storageKey);
    this.authenticationToken = null;

    router.push({ name: routeNames.LOGIN });
  }

  getAuthenticationTokenFromStorage () {
    return localStorage.getItem(this.storageKey);
  }

  setAuthenticationToken (token) {
    localStorage.setItem(this.storageKey, token);
    this.authenticationToken = token;
  }

  isExpiredToken () {
    return false;
  }

  isAuthenticated () {
    const didTokenExpire = this.isExpiredToken();

    return this.authenticationToken ? !didTokenExpire : !didTokenExpire && !!this.getAuthenticationTokenFromStorage();
  }
};

export default new AuthenticationService();

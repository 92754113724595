export const mutations = {
  setAccounts (state, { accounts }) {
    state.accounts = accounts;
  },
  setAdminAccounts (state, { accounts }) {
    state.adminAccounts = accounts;
  },
  setCards (state, { cards }) {
    state.cards = cards;
  },
  setIsFetchingAccounts (state, { value }) {
    state.isFetchingAccounts = value;
  },
  setIsFetchingAdminAccounts (state, { value }) {
    state.isFetchingAdminAccounts = value;
  },
  setIsFetchingCards (state, { value }) {
    state.isFetchingCards = value;
  },
  setIsFetchingStatistics (state, { value }) {
    state.isFetchingStatistics = value;
  },
  addAlert (state, { alert }) {
    state.alerts.push(alert);
  },
  setFilterValue (state, { filteringParam, value }) {
    state.filters[filteringParam] = value;
  },
  setAvailableLanguages (state, { availableLanguages }) {
    state.availableLanguages = availableLanguages;
  },
  setAccountStatistics (state, { accounts }) {
    state.statistics.accounts = accounts;
  },
  setCardStatistics (state, { cards }) {
    state.statistics.cards = cards;
  },
};

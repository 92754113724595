<template>
    <div class="single-card-section-container">
        <template v-if="card">
          <div class="single-card-header">
            <div class="single-card-title">
                <router-link
                  v-if="account"
                  :to="{
                    name: routeNames.CARDS,
                    params: {
                      accountId: account.accountId,
                    },
                  }">
                ◄
                </router-link>

                <router-link
                  v-else
                  :to="{ name: routeNames.ALL_CARDS }">
                ◄
                </router-link>
                {{ account ? account.accountName + " - " : '' }}{{ card && card.cardOwnerName }}
            </div>

            <div class="section-navigation-wrapper">
                <SectionNavigation :navigation-list="singleCardSectionNavigationConfig" />
            </div>
          </div>

          <router-view :card="card" />
        </template>

        <div v-else class="text-center">
          <br/>
          <CSpinner color="info"/>
          <h5 class="text-info">Loading card information</h5>
        </div>
    </div>
</template>

<script>
import { singleCardConfig } from '@/config/sectionNavigation';
import { routeNames } from '@/config/routes';
import SectionNavigation from '@/components/admin/shared/SectionNavigation';

export default {
  name: 'SingleCard',
  components: {
    SectionNavigation,
  },
  data () {
    return {
      routeNames,
    };
  },
  props: {
    card: Object,
    account: Object,
  },
  computed: {
    singleCardSectionNavigationConfig () {
      return singleCardConfig;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .single-card-section-container {
        margin-top: $nav-item-height + $nav-border-width;
        min-width: 550px;
        max-width: 1000px;
        margin-right: 10px;
    }

    .section-navigation-wrapper {
      width: 100%;
    }

    .single-card-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: $nav-item-height;
        font-weight: 700;
        font-size: 18px;

        button {
          color: var(--white);
        }
    }

    .single-card-title a {
      text-decoration: none;
      color: var(--tago-blue);
    }
</style>

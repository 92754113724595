import { createRouter, createWebHistory } from 'vue-router';
import { routeNames, routeSecurityHandlers } from '@/config/routes';
import authenticationService from '@/services/AuthenticationService';
import userService from '@/services/UserService';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = authenticationService.isAuthenticated();

  if (isAuthenticated) {
    const token = authenticationService.getAuthenticationTokenFromStorage();
    const user = userService.createUserFromToken(token);

    userService.setCurrentUser(user);

    if (to.name === routeNames.LOGIN || !to.name) {
      // If user is authenticated, but trying to access /login or root page, redirect him to admin panel
      next({ name: routeNames.ADMIN });
    } else if (userService.hasRouteAccess(to.name)) {
      // If user is authenticated, and trying to access route which he has permission to access, let him
      next();
    } else {
      let isAllowed = false;

      if (routeSecurityHandlers[to.name]) {
        isAllowed = routeSecurityHandlers[to.name](to);
      }

      if (isAllowed) {
        next();
      } else {
        // If user is authenticated, and trying to access route which he does not have permission to access
        // Redirect to first allowed route
        next({ name: userService.getLandingRoute() });
      }
    }
  } else {
    if (to.name === routeNames.LOGIN) {
      // If user is not authenticated and trying to access /login path, let him
      next();
    } else {
      // For any other path, redirect to /login
      next({ name: routeNames.LOGIN });
    }
  }
});

export default router;

<template>
    <div class="nav-section-item-wrapper">
        <CButton
          class="nav-sidebar-section-item"
          :class="isActive ? 'active' : ''"
          @click="handleNavItemClicked">
            <font-awesome-icon :icon="icon" />
            {{ title }}
        </CButton>
    </div>
</template>

<script>
export default {
  name: 'NavSidebarItem',
  props: {
    title: String,
    isActive: Boolean,
    icon: String,
  },
  methods: {
    handleNavItemClicked () {
      this.$emit('nav-item-clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .nav-sidebar-section-item {
        width: 100%;
        height: $nav-item-height;
        border-bottom: $nav-border-width solid var(--background-grey);
        border-radius: 0;
        padding-left: 15%;
        text-align: left;
        font-weight: 500;

        svg {
          margin-right: 5px;
        }

        &:focus, &:active, &:focus-visible {
          outline-color: var(--tago-blue);
        }

        &.active {
          padding-left: 20%;
          background-color: var(--background-grey);
          border-left: 4px solid var(--tago-blue);

          svg {
            color: var(--tago-blue);
          }
        }
    }
</style>

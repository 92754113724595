<template>
    <div class="card-details-component">
      <h5>Change card name:</h5>
      <div class="card-details-container">
        <CardNameForm :card="card"/>
      </div>

      <template v-if="hasChangeCardOwnershipAccess">
        <h5>Move cards under different accounts:</h5>
        <div class="card-details-container">
          <CardOwnershipForm :card="card"/>
        </div>
      </template>
    </div>
</template>

<script>
import { features } from '@/config/features';
import userService from '@/services/UserService';
import CardNameForm from '@/components/admin/accounts/single-card-view/CardNameForm';
import CardOwnershipForm from '@/components/admin/accounts/single-card-view/CardOwnershipForm';

export default {
  name: 'CardDetails',
  components: {
    CardNameForm,
    CardOwnershipForm,
  },
  props: {
    card: Object,
  },
  computed: {
    hasChangeCardOwnershipAccess () {
      return userService.hasFeatureAccess(features.CHANGE_CARD_OWNERSHIP);
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .card-details-component {
      margin-top: 10px;
    }

    .card-details-container {
      max-width: 700px;
      background-color: var(--white);
      padding: 30px 80px 10px;
      margin-bottom: 40px;
    }

    h5 {
      padding: 0;
      margin: 5px;
      font-size: 14px;
      font-weight: 600;
    }
</style>

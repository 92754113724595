<template>
  <div class="contact-us-section-container">
    <div class="contact-us-title">
      Contact Us
    </div>

    <CForm :action="contactUsFormActionURL" method="post" enctype="multipart/form-data">
      <div class="contact-us-form-wrapper">
          <ContactUsForm />
      </div>

      <div class="contact-us-action-buttons">
        <CButton
          type="submit"
          color="info">
          Send
        </CButton>
      </div>
    </CForm>
  </div>
</template>

<script>
import ContactUsForm from '@/components/admin/contact-us/ContactUsForm';
import { getBaseUrl } from '@/helpers/getBaseUrl';

export default {
  name: 'ContactUs',
  components: {
    ContactUsForm,
  },
  computed: {
    contactUsFormActionURL () {
      const baseUrl = getBaseUrl();

      return `${baseUrl}/api/contact-us`;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';
  @import '@/assets/scss/sidebar';

  .contact-us-section-container {
    width: 50vw;
    max-width: 600px;
    min-width: 200px;
    padding-top: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .contact-us-title {
    height: $nav-item-height - 20px;
  }

  .contact-us-form-wrapper {
    background-color: var(--white);
    padding: 30px 60px;
  }

  .contact-us-action-buttons {
    text-align: right;
    margin: 10px 0;

    button {
      width: 80px;
      color: var(--white);
    }
  }
</style>

import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import CoreuiVue from '@coreui/vue';
import { addViewportMetaTag } from '@/helpers/addViewportMetaTag';
import AdminApp from './AdminApp.vue';
import PreviewApp from './PreviewApp.vue';
import store from './store';
import router from './router';
import '@/boot';

let vueApp;

if (window.location.pathname.includes('/preview')) {
  vueApp = createApp(PreviewApp);

  addViewportMetaTag();
} else {
  vueApp = createApp(AdminApp);

  vueApp.use(store);
  vueApp.use(router);
}

vueApp.component('font-awesome-icon', FontAwesomeIcon);

vueApp.use(CoreuiVue);

vueApp.mount('#app');

<template>
  <CRow class="contact-details-container">
    <CCol
      class="contact-details-form-wrapper"
      sm="7">
      <CForm
        v-if="isCardTypeFormGenerated"
        @submit.prevent="handleFormSubmit">
        <CRow
          v-for="(formItem, index) in cardTypeFormInputs"
          :key="index"
          class="form-item">
          <CFormLabel
            class="col-sm-4 col-form-label">
            {{ formItem.label }}:
          </CFormLabel>

          <template v-if="formItem.isArray">
            <CFormInput
              v-for="(inputField, index) in cardTypeFormData[formItem.key]"
              :key="index"
              type="text"
              :placeholder="`Your ${formItem.label}`"
              :value="cardTypeFormData[formItem.key][index]"
              @input="$event => cardTypeFormData[formItem.key][index] = $event"/>
          </template>

          <CFormTextarea
            v-else-if="formItem.key === 'about'"
            maxlength="200"
            rows="6"
            placeholder="About You"
            :value="cardTypeFormData[formItem.key]"
            @input="$event => cardTypeFormData[formItem.key] = $event"/>

          <CFormSelect
            v-else-if="formItem.key === 'language'"
            :options="languageSelectOptions"
            :value="cardTypeFormData.language"
            @change="$event => cardTypeFormData.language = $event"/>

          <CFormInput
            v-else
            type="text"
            :placeholder="`Your ${formItem.label}`"
            :value="cardTypeFormData[formItem.key]"
            @input="$event => cardTypeFormData[formItem.key] = $event"/>

          <div class="add-button-wrapper">
            <CButton
              v-if="formItem.isArray"
              color="dark"
              variant="outline"
              size="sm"
              type="button"
              @click="() => cardTypeFormData[formItem.key].push('')">
              + Add
            </CButton>
          </div>
        </CRow>

        <CRow
          v-if="isCardTypeFormGenerated"
          class="mb-3 action-buttons">
          <CButton
            color="info"
            size="sm"
            variant="outline"
            type="button"
            @click="handleCancel">
            Cancel
          </CButton>

          <CButton
            class="save-button"
            color="info"
            size="sm"
            type="submit">
            Save
          </CButton>
        </CRow>
      </CForm>

      <div
        v-if="!isCardTypeFormGenerated"
        class="no-active-cards-message">
        No Active Cards
      </div>
    </CCol>

    <CCol
      class="contact-details-card-types-wrapper"
      sm=4>
      <CRow>
        <CCol sm="10">
          <CFormLabel class="col-form-label">
            Your Cards:
          </CFormLabel>
        </CCol>

        <CCol sm="2">
          <CFormLabel class="col-form-label">
            Status:
          </CFormLabel>
        </CCol>
      </CRow>

      <CRow
        v-for="(cardType, index) in card.cardTypeList"
        :key="index"
        class="your-cards-item">
        <CCol sm="9">
          <CCard
            class="your-cards-card"
            :class="cardType.cardTypeId === selectedCardType.cardTypeId ? '' : 'disabled'"
            @click="selectedCardType = cardType">
            <CCardBody>
              <CRow>
                <CCol
                  sm="2"
                  class="icon-wrapper vertical-align">
                  <font-awesome-icon icon="user-circle" />
                </CCol>

                <CCol
                  class="card-text-wrapper"
                  sm="10">
                  Card Profile {{ index + 1 }}

                  <CCardText class="text-muted">
                    <small>
                      {{ cardType.isActive ? 'Active' : 'Inactive' }}
                    </small>
                  </CCardText>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol
          class="vertical-align"
          sm="2">
          <CFormSwitch
            size="lg"
            id="active-card-switch"
            :label="cardType.isActive ? 'Active' : 'Inactive'"
            :modelValue="cardType.isActive"
            :checked="cardType.isActive"
            :disabled="!!this.settingStatusToCardWithId"
            @click.prevent="handleCardStatusToggle(cardType)"/>

          <CButton
            color="danger"
            size="sm"
            type="button"
            @click="handleRemoveCardProfile(cardType)">
            <font-awesome-icon :icon="['far', 'trash-alt']" />
          </CButton>
        </CCol>

        <CCol sm="1">
          <CSpinner
            v-if="cardType.cardTypeId === settingStatusToCardWithId"
            size="sm"
            color="info"/>
        </CCol>
      </CRow>

      <CButton
        color="info"
        variant="outline"
        size="sm"
        type="button"
        @click="handleAddCardProfile">
        + Add Card Profile
      </CButton>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { routeNames } from '@/config/routes';
import cardTypeDriver from '@/drivers/CardTypeDriver';

export default {
  name: 'CardContactDetails',
  props: {
    card: Object,
  },
  data () {
    return {
      settingStatusToCardWithId: null,
      cardTypeFormData: {},
      selectedCardType: null,
    };
  },
  computed: {
    ...mapState({
      availableLanguages: state => state.availableLanguages,
    }),
    ...mapGetters(['getAccountById']),
    account () {
      return this.getAccountById({ accountId: this.card.accountId });
    },
    hasLanguagePack () {
      if (this.account) {
        return this.account.contractOptions && this.account.contractOptions.includes('multiple_language_pack');
      } else {
        return this.card.hasLanguagePack;
      }
    },
    languageSelectOptions () {
      return this.availableLanguages.map(language => ({
        label: language.name,
        value: language.language,
      }));
    },
    activeCardType () {
      return this.card.cardTypeList.find(cardType => cardType.isActive);
    },
    cardTypeFormInputs () {
      return Object.keys(this.cardTypeFormData).map(key => {
        const value = this.cardTypeFormData[key];

        return {
          key,
          value,
          label: cardTypeDriver.dataMappings[key].label,
          isArray: Array.isArray(value),
        };
      });
    },
    isCardTypeFormGenerated () {
      return Object.keys(this.cardTypeFormData).length;
    },
  },
  methods: {
    ...mapActions([
      'updateCard',
      'setActiveCard',
      'addCardType',
      'deleteCardType',
    ]),
    ...mapMutations(['addAlert']),
    handleCardStatusToggle (cardType) {
      this.settingStatusToCardWithId = cardType.cardTypeId;

      this.setActiveCard({
        cardId: cardType.cardId,
        cardTypeId: cardType.cardTypeId,
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Activated Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Card Activation Failed',
            },
          });
        })
        .finally(() => {
          this.settingStatusToCardWithId = null;
        });
    },
    handleAddCardProfile () {
      this.addCardType({ cardId: this.card.cardId })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Profile Added Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Adding Card Profile Failed',
            },
          });
        });
    },
    handleRemoveCardProfile (cardType) {
      if (window.confirm('Are you sure you want to delete this card profile?')) {
        this.deleteCardType({
          cardId: cardType.cardId,
          cardTypeId: cardType.cardTypeId,
        })
          .then(() => {
            this.addAlert({
              alert: {
                type: 'info',
                message: 'Card Profile Removed Successfully',
              },
            });
          })
          .catch(() => {
            this.addAlert({
              alert: {
                type: 'danger',
                message: 'Removing Card Profile Failed',
              },
            });
          });
      }
    },
    handleCancel () {
      this.$router.push({
        name: routeNames.SINGLE_CARD,
        params: {
          cardId: this.card.cardId,
        },
      });
    },
    handleFormSubmit () {
      this.updateCard({
        cardId: this.selectedCardType.cardId,
        cardTypeId: this.selectedCardType.cardTypeId,
        cardType: this.cardTypeFormData,
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Edited Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Card Edit Failed',
            },
          });
        });
    },
  },
  watch: {
    activeCardType: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.selectedCardType = newVal;
        } else {
          this.selectedCardType = this.card.cardTypeList[0];
        }
      },
    },
    selectedCardType: {
      immediate: true,
      handler (newVal) {
        if (!newVal) {
          this.cardTypeFormData = {};

          return;
        }

        const formDataConfig = [
          cardTypeDriver.dataMappings.fullName.clientKey,
          cardTypeDriver.dataMappings.company.clientKey,
          cardTypeDriver.dataMappings.title.clientKey,
          cardTypeDriver.dataMappings.about.clientKey,
          cardTypeDriver.dataMappings.officePhoneList.clientKey,
          cardTypeDriver.dataMappings.fax.clientKey,
          cardTypeDriver.dataMappings.mobilePhoneList.clientKey,
          cardTypeDriver.dataMappings.emailList.clientKey,
          cardTypeDriver.dataMappings.websiteList.clientKey,
          cardTypeDriver.dataMappings.addressList.clientKey,
          cardTypeDriver.dataMappings.facebook.clientKey,
          cardTypeDriver.dataMappings.instagram.clientKey,
          cardTypeDriver.dataMappings.twitter.clientKey,
          cardTypeDriver.dataMappings.linkedin.clientKey,
          cardTypeDriver.dataMappings.youtube.clientKey,
          cardTypeDriver.dataMappings.pinterest.clientKey,
          cardTypeDriver.dataMappings.tiktok.clientKey,
        ];

        if (this.hasLanguagePack) {
          formDataConfig.push(cardTypeDriver.dataMappings.language.clientKey);
        }

        this.cardTypeFormData = cardTypeDriver.createClientFormData(newVal, formDataConfig);
      },
    },
  },
};
</script>

<style>
 .form-check-input {
   cursor: pointer;
 }
</style>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';
  @import '@/assets/scss/sidebar';

  .contact-details-container {
    max-width: 900px;
  }

  .contact-details-form-wrapper {
    padding-top: 25px;
    padding-right: 30px;

    .form-item {
      background-color: var(--white);
      padding: 20px 60px 30px;
      margin-bottom: $standard-border-width;

      input,
      textarea {
        background-color: var(--background-grey);
        margin-bottom: 10px;
      }
    }

    .action-buttons {
      margin-top: 10px;
      justify-content: right;
      grid-gap: 15px;

      button {
        width: 70px;
      }

      .save-button {
        color: var(--white);
      }
    }

    .add-button-wrapper {
      padding: 0;
      text-align: right;
    }
  }

  .contact-details-card-types-wrapper {
    .icon-wrapper {
      font-size: 32px;
    }

    .card-text-wrapper {
      padding-left: 20px;
    }

    .your-cards-item {
      margin-bottom: 20px;
    }

    .your-cards-card {
      cursor: pointer;
      color: var(--white);
      border: none;
      background-color: var(--tago-blue);
      font-weight: 500;

      &.disabled {
        color: grey;
        background-color: var(--disabled);
      }
    }
  }

  .col-form-label {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .vertical-align {
    margin-top: auto;
    margin-bottom: auto;
  }

  .no-active-cards-message {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
  }
</style>

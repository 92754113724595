<template>
  <div v-if="account" class="account-login-details-section-container">
      <div class="account-login-details-content-wrapper">
          <div v-if="accountDetailsFormData" class="account-login-details-form-container">
            <CForm @submit.prevent="handleFormSubmit" autocomplete="off">
                <CRow class="mb-3">
                    <CFormLabel
                        class="col-sm-4 col-form-label"
                        for="login-email">
                        Email:
                    </CFormLabel>

                    <CCol sm="8">
                        <CFormInput
                            id="login-email"
                            name="login-email"
                            type="text"
                            placeholder="Enter email"
                            :value="accountDetailsFormData.loginEmail"
                            @change="handleInputDataChange($event, accountDriver.dataMappings.loginEmail.clientKey,)"/>
                    </CCol>
                </CRow>

                <CRow class="mb-3">
                    <CFormLabel
                        class="col-sm-4 col-form-label"
                        for="login-password">
                        Password:
                    </CFormLabel>

                    <CCol sm="8">
                        <CFormInput
                            id="login-password"
                            name="login-password"
                            type="password"
                            placeholder="Enter password"
                            :value="accountDetailsFormData.loginPassword"
                            @change="handleInputDataChange($event, accountDriver.dataMappings.loginPassword.clientKey,)"/>
                    </CCol>
                </CRow>

                <CRow class="mb-3 action-buttons">
                    <CFormLabel class="col-sm-6 col-form-label" />

                    <CButton
                        color="info"
                        size="sm"
                        variant="outline"
                        @click="handleCancel">
                        Cancel
                    </CButton>

                    <CButton
                        class="save-button"
                        color="info"
                        size="sm"
                        type="submit">
                        Save
                    </CButton>
                </CRow>
            </CForm>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { routeNames } from '@/config/routes';
import accountDriver from '@/drivers/AccountDriver';

export default {
  name: 'AccountLoginDetails',
  props: {
    account: Object,
  },
  data () {
    return {
      accountDriver,
      accountDetailsFormData: accountDriver.createClientFormData(this.account, [
        accountDriver.dataMappings.loginEmail.clientKey,
        accountDriver.dataMappings.loginPassword.clientKey,
      ]),
    };
  },
  methods: {
    ...mapActions(['updateAccountLoginDetails']),
    ...mapMutations(['addAlert']),
    handleInputDataChange (value, propertyChanged) {
      this.accountDetailsFormData[propertyChanged] = value;
    },
    handleCancel () {
      this.$router.push({
        name: routeNames.ALL_ACCOUNTS,
      });
    },
    handleFormSubmit () {
      this.updateAccountLoginDetails({
        account: {
          accountId: this.account.accountId,
          ...this.accountDetailsFormData,
        },
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Account Login Details Edited Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Account Login Details Edit Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/sidebar';
    @import '@/assets/scss/colors';

    .account-login-details-section-container {
        margin-top: $nav-border-width;
        min-width: 550px;
        max-width: 600px;
        margin-right: 10px;
    }

    .account-login-details-content-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: $standard-border-width;
        justify-content: space-between;
    }

    .account-login-details-form-container {
        margin-top: 20px;

        select {
            width: 100px;
        }
    }

    .col-form-label {
        text-align: right;
        font-size: 14px;
        font-weight: 600;
    }

    .action-buttons {
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }
</style>

<template>
    <div class="card-photo-container">
        <CForm @submit.prevent="handleSubmit">
          <CRow class="mb-3">
            <CFormLabel
              class="col-sm-4 col-form-label"
              for="card-photo">
              Add Photo:
            </CFormLabel>

            <CFormInput
              id="card-photo"
              name="card-photo"
              type="file" />
          </CRow>

          <CRow class="mb-3 action-buttons">
                <CButton
                    color="info"
                    size="sm"
                    variant="outline"
                    @click="handleCancel">
                    Cancel
                </CButton>

                <CButton
                    class="save-button"
                    color="info"
                    size="sm"
                    type="submit">
                    Save
                </CButton>
            </CRow>
        </CForm>
    </div>
</template>

<script>
import { routeNames } from '@/config/routes';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'CardPhoto',
  props: {
    card: Object,
  },
  methods: {
    ...mapActions(['addCardPhoto']),
    ...mapMutations(['addAlert']),
    handleCancel () {
      this.$router.push({
        name: routeNames.SINGLE_CARD,
        cardId: this.card.cardId,
      });
    },
    handleSubmit () {
      this.addCardPhoto({
        cardId: this.card.cardId,
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Photo Added Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Card Photo Add Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .card-photo-container {
      max-width: 700px;
      background-color: var(--white);
      padding: 30px 80px 10px;

      input {
        background-color: var(--background-grey);
      }
    }

    .col-form-label {
        padding: 0;
        font-size: 14px;
        font-weight: 600;
    }

    .action-buttons {
        justify-content: right;
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }
</style>

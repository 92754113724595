<template>
  <div class="dark-mode">
    <Alert />

    <router-view/>
  </div>
</template>

<script>
import Alert from '@/components/admin/shared/Alert';

export default {
  name: 'App',
  components: {
    Alert,
  },
};
</script>

<style lang="scss">
  @import "~@coreui/coreui/scss/coreui";

  @font-face {
   font-family: 'AzoSans';
   src: url(~@/assets/fonts/AzoSans-Regular.ttf) format('truetype');
  }

  @font-face {
   font-family: 'AzoSansBold';
   src: url(~@/assets/fonts/AzoSans-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: "Effra";
    src: url(~@/assets/fonts/Effra-Regular.ttf) format('truetype');
  }

  @font-face {
      font-family: "Effra";
      src: url(~@/assets/fonts/Effra-Bold.ttf) format('truetype');
      font-weight: bold;
  }

  html,
  body,
  #app {
    font-family: 'AzoSans';
  }
</style>

<template>
    <div class="card-link-container">
      <CRow class="mb-3">
        <CFormLabel
          class="col-form-label">
          {{ isTagoProductsManifacturer ? 'Default link' : 'Card Link:' }}
        </CFormLabel>

        <CCol class="col-sm-8 card-link-wrapper">
          <CFormInput
            v-c-tooltip="getCardLink(null)"
            readonly
            :value="getCardLink(null)"/>
        </CCol>

        <CButton
          class="col-sm-3"
          color="info"
          size="sm"
          @click="handleLinkCopy(null)">
            <font-awesome-icon icon="link" />
            Copy Link
        </CButton>
      </CRow>

      <template v-if="isTagoProductsManifacturer">
        <CRow class="mb-3">
          <CFormLabel
            class="col-form-label">
            Plastic Card Link:
          </CFormLabel>

          <CCol class="col-sm-8 card-link-wrapper">
            <CFormInput
              v-c-tooltip="getCardLink('card')"
              readonly
              :value="getCardLink('card')"/>
          </CCol>

          <CButton
            class="col-sm-3"
            color="info"
            size="sm"
            @click="handleLinkCopy('card')">
              <font-awesome-icon icon="link" />
              Copy Card Link
          </CButton>
        </CRow>

        <CRow class="mb-3">
          <CFormLabel
            class="col-form-label">
            Sticker link:
          </CFormLabel>

          <CCol class="col-sm-8 card-link-wrapper">
            <CFormInput
              v-c-tooltip="getCardLink('sticker')"
              readonly
              :value="getCardLink('sticker')"/>
          </CCol>

          <CButton
            class="col-sm-3"
            color="info"
            size="sm"
            @click="handleLinkCopy('sticker')">
              <font-awesome-icon icon="link" />
              Copy Sticker Link
          </CButton>
        </CRow>

        <CRow class="mb-3">
          <CFormLabel
            class="col-form-label">
            Virtago Link
          </CFormLabel>

          <CCol class="col-sm-8 card-link-wrapper">
            <CFormInput
              v-c-tooltip="getCardLink('virtago')"
              readonly
              :value="getCardLink('virtago')"/>
          </CCol>

          <CButton
            class="col-sm-3"
            color="info"
            size="sm"
            @click="handleLinkCopy('virtago')">
              <font-awesome-icon icon="link" />
              Copy Virtago Link
          </CButton>
        </CRow>

        <CRow class="mb-3">
          <CFormLabel
            class="col-form-label">
            Mobile Ring Link
          </CFormLabel>

          <CCol class="col-sm-8 card-link-wrapper">
            <CFormInput
              v-c-tooltip="getCardLink('mobileRing')"
              readonly
              :value="getCardLink('mobileRing')"/>
          </CCol>

          <CButton
            class="col-sm-3"
            color="info"
            size="sm"
            @click="handleLinkCopy('mobileRing')">
              <font-awesome-icon icon="link" />
              Copy Mobile Ring Link
          </CButton>
        </CRow>

        <CRow class="mb-3">
          <CFormLabel
            class="col-form-label">
            Petago Link:
          </CFormLabel>

          <CCol class="col-sm-8 card-link-wrapper">
            <CFormInput
              v-c-tooltip="getCardLink('link')"
              readonly
              :value="getCardLink('link')"/>
          </CCol>

          <CButton
            class="col-sm-3"
            color="info"
            size="sm"
            @click="handleLinkCopy('petago')">
              <font-awesome-icon icon="link" />
              Copy Petago Link
          </CButton>
        </CRow>
      </template>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { copyTextToClipboard } from '@/helpers/copyTextToClipboard';
import { features } from '@/config/features';
import userService from '@/services/UserService';

export default {
  name: 'CardLink',
  props: {
    card: Object,
  },
  computed: {
    cardLink () {
      return `${window.location.origin}/preview/${this.card.cardLink}`;
    },
    isTagoProductsManifacturer () {
      return userService.hasFeatureAccess(features.TAGO_PRODUCTS_MANUFACTURE);
    },
  },
  methods: {
    ...mapMutations(['addAlert']),
    handleLinkCopy (type) {
      const linkToCopy = this.getCardLink(type);

      copyTextToClipboard(linkToCopy);

      this.addAlert({
        alert: {
          type: 'info',
          message: 'Link Copied to Clipboard',
        },
      });
    },
    getCardLink (type) {
      return type ? `${this.cardLink}?type=${type}` : this.cardLink;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';

  .card-link-container {
    max-width: 800px;
    background-color: var(--white);
    padding: 30px 40px 10px 80px;

    button {
      width: 200px;
      color: var(--white),
    }
  }

  .col-form-label {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .card-link-wrapper {
    padding: 0;
    margin-right: 10px;

    input {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 10px;
      background-color: var(--background-grey);
      border-radius: 10px;
    }
  }
</style>

<template>
    <div class="statistics-cards-section-container">
      <DashboardCard
        v-for="(cardConfig, index) in filteredCardConfig"
        :key="index"
        :title="cardConfig.title"
        :count="cardConfig.count"
        :icon="cardConfig.icon"
        :routeName="cardConfig.routeName"
        :isFetchingInProgress="cardConfig.isFetchingInProgress"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { cardConfig } from '@/config/statistics';
import userService from '@/services/UserService';
import DashboardCard from '@/components/admin/shared/DashboardCard';

export default {
  name: 'Statistics',
  components: {
    DashboardCard,
  },
  computed: {
    ...mapState({
      accountStatisticsCount: state => state.statistics.accounts.length,
      cardStatisticsCount: state => state.statistics.cards.length,
      isFetchingStatisticsInProgress: state => state.isFetchingStatistics,
    }),
    filteredCardConfig () {
      const counts = [this.accountStatisticsCount, this.cardStatisticsCount];

      const config = [cardConfig.accountStatistics, cardConfig.cardStatistics]
        .filter(card => userService.hasFeatureAccess(card.featureName));

      return config.map((config, index) => {
        return {
          ...config,
          isFetchingInProgress: this.isFetchingStatisticsInProgress,
          count: counts[index],
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/sidebar';

  .statistics-cards-section-container {
    display: flex;
    margin-top: $nav-item-height + $nav-border-width;
  }
</style>

<template>
    <div class="mobile-preview-container">
      <div
        v-for="(cardType, index) in card.cardTypeList"
        :key="index">
        <div class="mobile-preview-label">
          {{ `Card Profile ${index + 1}` }}
          <span>{{ cardType.isActive ? '(active)' : '' }}</span>
        </div>

        <div class="mobile-preview">
          <Preview
            :card-data="cardType" />
        </div>
      </div>
    </div>
</template>

<script>
import Preview from '@/views/PreviewV2';

export default {
  name: 'MobilePreview',
  components: {
    Preview,
  },
  props: {
    card: Object,
  },
};
</script>

<style lang="scss">
  .mobile-preview-container {
    .preview-header h1{
      font-size: 24px;
    }

    .preview-header p{
      font-size: 14px !important;
    }

    .preview-header img{
      width: 80px !important;
      height: 80px !important;
    }
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';

  .mobile-preview-container {
    padding-top: 30px;
    display: flex;
    grid-gap: 30px;
  }

  .mobile-preview-label {
    font-size: 14px;
    text-align: center;
    font-weight: 500;

    span {
      font-weight: 700;
    }
  }

  .mobile-preview {
    border: 5px solid var(--white);
    border-radius: 15px;
    width: 380px;
    overflow: hidden;
  }
</style>

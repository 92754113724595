class CardTypeDriver {
    dataMappings = Object.freeze({
      isActive: {
        clientKey: 'isActive',
        serverKey: 'active',
        defaultValue: false,
      },
      title: {
        clientKey: 'title',
        serverKey: 'title',
        defaultValue: '',
        label: 'Position',
      },
      about: {
        clientKey: 'about',
        serverKey: 'about',
        defaultValue: '',
        label: 'About',
      },
      addressList: {
        clientKey: 'addressList',
        serverKey: 'address',
        icon: 'address-card',
        defaultValue: [],
        label: 'Address',
        actionType: 'maps',
      },
      cardTypeId: {
        clientKey: 'cardTypeId',
        serverKey: 'id',
      },
      company: {
        clientKey: 'company',
        serverKey: 'company',
        icon: 'building',
        defaultValue: '',
        label: 'Company',
      },
      userId: {
        clientKey: 'userId',
        serverKey: 'userId',
      },
      emailList: {
        clientKey: 'emailList',
        serverKey: 'emails',
        icon: 'envelope',
        defaultValue: [],
        label: 'Email',
        isLink: true,
        actionType: 'email',
      },
      fax: {
        clientKey: 'fax',
        serverKey: 'fax',
        icon: 'print',
        defaultValue: '',
        label: 'Fax',
        actionType: 'phone',
      },
      fullName: {
        clientKey: 'fullName',
        serverKey: 'fullName',
        defaultValue: '',
        label: 'Full Name',
      },
      cardId: {
        clientKey: 'cardId',
        serverKey: 'cardId',
      },
      mobilePhoneList: {
        clientKey: 'mobilePhoneList',
        serverKey: 'mobilePhones',
        icon: 'mobile-alt',
        defaultValue: [],
        label: 'Mobile',
        actionType: 'phone',
      },
      officePhoneList: {
        clientKey: 'officePhoneList',
        serverKey: 'officePhones',
        icon: 'phone-alt',
        defaultValue: [],
        label: 'Phone',
        actionType: 'phone',
      },
      websiteList: {
        clientKey: 'websiteList',
        serverKey: 'websites',
        icon: 'globe',
        defaultValue: [],
        label: 'Website',
        isLink: true,
        actionType: 'website',
      },
      facebook: {
        clientKey: 'facebook',
        serverKey: 'facebook',
        icon: 'facebook',
        defaultValue: [],
        label: 'Facebook',
        isLink: true,
        actionType: 'website',
      },
      instagram: {
        clientKey: 'instagram',
        serverKey: 'instagram',
        icon: 'instagram',
        defaultValue: [],
        label: 'Instagram',
        isLink: true,
        actionType: 'website',
      },
      linkedin: {
        clientKey: 'linkedin',
        serverKey: 'linkedin',
        icon: 'linkedin',
        defaultValue: [],
        label: 'LinkedIn',
        isLink: true,
        actionType: 'website',
      },
      twitter: {
        clientKey: 'twitter',
        serverKey: 'twitter',
        icon: 'twitter',
        defaultValue: [],
        label: 'Twitter',
        isLink: true,
        actionType: 'website',
      },
      youtube: {
        clientKey: 'youtube',
        serverKey: 'youtube',
        icon: 'youtube',
        defaultValue: [],
        label: 'YouTube',
        isLink: true,
        actionType: 'website',
      },
      pinterest: {
        clientKey: 'pinterest',
        serverKey: 'pinterest',
        icon: 'pinterest',
        defaultValue: [],
        label: 'Pinterest',
        isLink: true,
        actionType: 'website',
      },
      tiktok: {
        clientKey: 'tiktok',
        serverKey: 'tiktok',
        icon: 'tiktok',
        defaultValue: [],
        label: 'TikTok',
        isLink: true,
        actionType: 'website',
      },
      language: {
        clientKey: 'language',
        serverKey: 'language',
        defaultValue: 'en',
        label: 'Language',
      },
      profilePicture: {
        clientKey: 'cardProfilePicture',
        serverKey: 'profilePicture',
        defaultValue: '',
      },
      themes: {
        clientKey: 'themes',
        serverKey: 'themes',
        defaultValue: null,
      },
      themeLocationId: {
        clientKey: 'themeLocationId',
        serverKey: 'themeLocationId',
        defaultValue: '',
      },
    })

    formatServerData (responseData) {
      return Object.keys(this.dataMappings).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.clientKey] = objMap.formatServerPropertyFunction
          ? objMap.formatServerPropertyFunction(responseData[objMap.serverKey])
          : responseData[objMap.serverKey];

        return acc;
      }, {});
    }

    formatClientData (clientData) {
      return Object.keys(clientData).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.serverKey] = objMap.formatClientPropertyFunction
          ? objMap.formatClientPropertyFunction(clientData[objMap.clientKey])
          : clientData[objMap.clientKey];

        return acc;
      }, {});
    }

    createClientFormData (cardType, configList) {
      return configList.reduce((acc, curVal) => {
        const config = typeof curVal === 'string' ? { clientKey: curVal } : curVal;

        const { clientKey, formatFunction } = config;

        const value = cardType[clientKey] || this.dataMappings[clientKey].defaultValue;

        if (Array.isArray(value)) {
          const arrValue = value.length ? value : [''];

          acc[clientKey] = formatFunction ? formatFunction(value) : arrValue;
        } else {
          acc[clientKey] = formatFunction ? formatFunction(value) : value;
        }

        return acc;
      }, {});
    }
}

export default new CardTypeDriver();

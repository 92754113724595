<template>
    <div>
        <NavHeader />
        <NavSidebar />
    </div>
</template>

<script>
import NavHeader from '@/components/admin/navigation/NavHeader';
import NavSidebar from '@/components/admin/navigation/NavSidebar';

export default {
  name: 'Nav',
  components: {
    NavHeader,
    NavSidebar,
  },
};
</script>

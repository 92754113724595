<template>
  <div v-if="account" class="admin-account-details-section-container">
      <div class="admin-account-details-content-wrapper">
          <AdminAccountDetailsForm :account="account" />
      </div>
  </div>
</template>

<script>
import AdminAccountDetailsForm from '@/components/admin/settings/AdminAccountDetailsForm';

export default {
  name: 'AdminAccountDetails',
  components: {
    AdminAccountDetailsForm,
  },
  props: {
    account: Object,
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/sidebar';

    .admin-account-details-section-container {
        margin-top: $nav-border-width;
        min-width: 550px;
        max-width: 600px;
        margin-right: 10px;
    }

    .admin-account-details-content-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: $standard-border-width;
        justify-content: space-between;
    }
</style>

<template>
    <div class="settings-cards-section-container">
      <DashboardCard
        v-for="(cardConfig, index) in filteredCardConfig"
        :key="index"
        :title="cardConfig.title"
        :count="cardConfig.count"
        :icon="cardConfig.icon"
        :routeName="cardConfig.routeName"
        :isFetchingInProgress="cardConfig.isFetchingInProgress"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { cardConfig } from '@/config/settings';
import { routeNames } from '@/config/routes';
import userService from '@/services/UserService';
import DashboardCard from '@/components/admin/shared/DashboardCard';

export default {
  name: 'Settings',
  components: {
    DashboardCard,
  },
  computed: {
    ...mapState({
      adminAccountCount: state => state.adminAccounts.length,
      isFetchingAccountsInProgress: state => state.isFetchingAdminAccounts,
    }),
    filteredCardConfig () {
      const counts = [this.adminAccountCount]
        .filter((_, index) => userService.hasRouteAccess([routeNames.ALL_ADMIN_ACCOUNTS][index]));

      const isFetchingInProgress = [this.isFetchingAccountsInProgress]
        .filter((_, index) => userService.hasRouteAccess([routeNames.ALL_ADMIN_ACCOUNTS][index]));

      const config = [cardConfig.accounts]
        .filter((_, index) => userService.hasRouteAccess([routeNames.ALL_ADMIN_ACCOUNTS][index]));

      return config.map((config, index) => {
        return {
          ...config,
          isFetchingInProgress: isFetchingInProgress[index],
          count: counts[index],
        };
      }); ;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/sidebar';

  .settings-cards-section-container {
    display: flex;
    margin-top: $nav-item-height + $nav-border-width;
  }
</style>

import { features } from '@/config/features';
import { routeNames } from '@/config/routes';
import accountService from '@/services/AccountService';
import userService from '@/services/UserService';
import LanguageService from '../services/LanguageService';

export const actions = {
  fetchAccounts ({ commit }) {
    const hasAllAccountsAccess = userService.hasFeatureAccess(features.GET_ALL_ACCOUNTS);
    let servicePromise;

    if (hasAllAccountsAccess) {
      servicePromise = accountService.getAllAccounts();
    } else {
      const userId = userService.getUserId();

      servicePromise = accountService.getUserAccounts(userId);
    }

    commit('setIsFetchingAccounts', { value: true });

    return servicePromise
      .then(accounts => {
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName));

        commit('setAccounts', { accounts });
      })
      .finally(() => {
        commit('setIsFetchingAccounts', { value: false });
      });
  },

  fetchAdminAccounts ({ commit }) {
    commit('setIsFetchingAdminAccounts', { value: true });

    return accountService.getAllAdminAccounts()
      .then(accounts => {
        accounts.sort((a, b) => a.accountName.localeCompare(b.accountName));

        commit('setAdminAccounts', { accounts });
      })
      .finally(() => {
        commit('setIsFetchingAdminAccounts', { value: false });
      });
  },

  createAccount ({ dispatch }, { accountName }) {
    return accountService.createAccount(accountName)
      .then(() => {
        dispatch('fetchAccounts');
      });
  },

  createAdminAccount ({ dispatch }, { accountName }) {
    return accountService.createAdminAccount(accountName)
      .then(() => {
        dispatch('fetchAdminAccounts');
      });
  },

  updateAccount ({ dispatch }, { account }) {
    return accountService.updateAccount(account)
      .then(() => {
        dispatch('fetchAccounts');
      });
  },

  updateAdminAccount ({ dispatch }, { account }) {
    return accountService.updateAdminAccount(account)
      .then(() => {
        dispatch('fetchAdminAccounts');
      });
  },

  updateAccountLoginDetails ({ dispatch }, { account }) {
    return accountService.updateAccountLoginDetails(account)
      .then(() => {
        dispatch('fetchAccounts');
      });
  },

  updateAccountThemes ({ dispatch }, { account }) {
    return accountService.updateAccountThemes(account)
      .then(() => {
        dispatch('fetchAccounts');
      });
  },

  deleteAccount ({ dispatch }, { accountId }) {
    return accountService.deleteAccount(accountId)
      .then(() => {
        dispatch('fetchAccounts');
        dispatch('fetchCards');
      });
  },

  deleteAdminAccount ({ dispatch }, { accountId }) {
    return accountService.deleteAdminAccount(accountId)
      .then(() => {
        dispatch('fetchAdminAccounts');
      });
  },

  fetchCards ({ commit }) {
    const hasAllCardsAccess = userService.hasFeatureAccess(features.GET_ALL_CARDS);
    let servicePromise;

    if (hasAllCardsAccess) {
      servicePromise = accountService.getAllCards();
    } else {
      const userId = userService.getUserId();

      servicePromise = accountService.getUserCards(userId);
    }

    commit('setIsFetchingCards', { value: true });

    servicePromise
      .then(cards => {
        cards.sort((a, b) => a.cardOwnerName.localeCompare(b.cardOwnerName));

        commit('setCards', { cards });
      })
      .finally(() => {
        commit('setIsFetchingCards', { value: false });
      });
  },

  createCard ({ dispatch }, { accountId, cardOwnerName }) {
    return accountService.createCard(accountId, cardOwnerName)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  changeCardOwnerName ({ dispatch }, { cardId, cardOwnerName }) {
    return accountService.changeCardOwnerName(cardId, cardOwnerName)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  changeCardOwnership ({ dispatch }, { cardId, newAccountId }) {
    return accountService.changeCardOwnership(cardId, newAccountId)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  updateCard ({ dispatch }, { cardId, cardTypeId, cardType }) {
    return accountService.updateCard(cardId, cardTypeId, cardType)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  setActiveCard ({ dispatch }, { cardId, cardTypeId }) {
    return accountService.setActiveCard(cardId, cardTypeId)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  updateCardLoginDetails ({ dispatch }, { card }) {
    return accountService.updateCardLoginDetails(card)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  deleteCard ({ dispatch }, { cardId }) {
    return accountService.deleteCard(cardId)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  addCardType ({ dispatch }, { cardId }) {
    return accountService.addCardType(cardId)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  deleteCardType ({ dispatch }, { cardId, cardTypeId }) {
    return accountService.deleteCardType(cardId, cardTypeId)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  addCardPhoto ({ dispatch }, { cardId }) {
    return accountService.addCardPhoto(cardId)
      .then(() => {
        dispatch('fetchCards');
      });
  },

  saveAndroidCard (_, { cardId, color }) {
    return accountService.saveAndroidCard(cardId, color);
  },

  getAvailableLanguages ({ commit }) {
    return LanguageService.getAvailableLanguages()
      .then(availableLanguages => {
        commit('setAvailableLanguages', { availableLanguages });
      });
  },

  fetchStatistics ({ commit }) {
    commit('setIsFetchingStatistics', { value: true });
    commit('setAccountStatistics', { accounts: [] });
    commit('setCardStatistics', { cards: [] });

    return accountService.getStatistics()
      .then(({ accounts, cards }) => {
        if (accounts || cards) {
          userService.addRouteAccess(routeNames.STATISTICS);
          userService.addFeatureAccess(features.STATISTICS);
        }

        if (accounts) {
          userService.addRouteAccess(routeNames.ACCOUNT_STATISTICS);
          userService.addFeatureAccess(features.ACCOUNT_STATISTICS);

          commit('setAccountStatistics', { accounts });
        }

        if (cards) {
          userService.addRouteAccess(routeNames.CARD_STATISTICS);
          userService.addFeatureAccess(features.CARD_STATISTICS);

          commit('setCardStatistics', { cards });
        }
      })
      .finally(() => {
        commit('setIsFetchingStatistics', { value: false });
      });
  },
  uploadTexture ({ dispatch }, { account }) {
    return accountService.uploadTexture(account)
      .then(() => {
        dispatch('fetchAccounts');
      });
  },
  deleteTexture ({ dispatch }, { account, textureName }) {
    return accountService.deleteTexture(account, textureName)
      .then(() => {
        dispatch('fetchAccounts');
      });
  },
};

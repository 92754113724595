<template>
    <div
        :class="!account.isAccountEnabled && 'disabled-account'"
        class="account-item">
        <div class="account-name">
            {{ account.accountName }}
        </div>

        <div>
            {{ formatDate(account.accountCreatedAt) }}
        </div>

        <div class="action-buttons-wrapper">
            <CButton
              v-if="userCanEditAccounts"
              @click="handleAccountEdit"
              class="icon-wrapper action-button">
                <font-awesome-icon :icon="['far', 'edit']" />
            </CButton>

            <CButton
                v-if="userCanDeleteAccounts"
                class="icon-wrapper action-button"
                @click="handleAccountDelete">
                <font-awesome-icon :icon="['far', 'trash-alt']" />
            </CButton>

            <CButton
                class="view-account-button action-button"
                variant="outline"
                color="info"
                @click="handleViewAccount">
                View Account
            </CButton>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import { mapActions, mapMutations } from 'vuex';
import { routeNames } from '@/config/routes';
import { features } from '@/config/features';
import userService from '@/services/UserService';

export default {
  name: 'AccountItem',
  props: {
    account: Object,
  },
  computed: {
    userCanEditAccounts () {
      return userService.hasFeatureAccess(features.EDIT_ACCOUNT);
    },
    userCanDeleteAccounts () {
      return userService.hasFeatureAccess(features.DELETE_ACCOUNT);
    },
  },
  methods: {
    ...mapActions(['deleteAccount']),
    ...mapMutations(['addAlert']),
    formatDate (date) {
      return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
    },
    handleAccountEdit () {
      this.$router.push({
        name: routeNames.ACCOUNT_DETAILS,
        params: {
          accountId: this.account.accountId,
        },
      });
    },
    handleAccountDelete () {
      if (window.confirm('Are you sure you want to delete this account?')) {
        this.deleteAccount({ accountId: this.account.accountId })
          .then(() => {
            this.newAccountName = '';
            this.addAlert({
              alert: {
                type: 'info',
                message: 'Account Deleted Successfully',
              },
            });
          })
          .catch(() => {
            this.addAlert({
              alert: {
                type: 'danger',
                message: 'Account Delete Failed',
              },
            });
          });
      }
    },
    handleViewAccount () {
      this.$router.push({
        name: routeNames.SINGLE_ACCOUNT,
        params: {
          accountId: this.account.accountId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';
  @import '@/assets/scss/sidebar';

  .account-item {
      background-color: var(--white);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;
      width: 100%;
      font-size: 14px;
  }

  .account-name {
      text-transform: uppercase;
      margin-right: 10px;
      width: 40%;
  }

  .action-buttons-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 40%;
    max-width: 200px;

    .action-button {
        margin: 0 5px;
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        color: var(--primary-dark);
        background-color: var(--background-grey);
        border-radius: 5px;

        &:hover {
            background-color: var(--tago-blue);
        }
    }

    .view-account-button {
        font-size: 12px;
        width: 120px;
    }
  }

  .disabled-account {
    opacity: 0.65;
  }
</style>

import axios from 'axios';
import { endpoints } from '@/config/endpoints';

class LanguageService {
  async getAvailableLanguages () {
    try {
      const { data } = await axios.get(`${endpoints.languages}`);

      return data.content;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default new LanguageService();

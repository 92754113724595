import { h, resolveComponent } from 'vue';
import { routeNames } from '@/config/routes';
import store from '@/store';
import Login from '@/views/Login.vue';
import Admin from '@/views/Admin.vue';
import AllAccounts from '@/components/admin/accounts/all-accounts-view/AllAccounts';
import AllAdminAccounts from '@/components/admin/settings/AllAdminAccounts';
import SingleAdminAccount from '@/components/admin/settings/SingleAdminAccount';
import AdminAccountDetails from '@/components/admin/settings/AdminAccountDetails';
import AdminAccountDelete from '@/components/admin/settings/AdminAccountDelete';
import AllCards from '@/components/admin/cards/AllCards';
import SingleAccount from '@/components/admin/accounts/single-account-view/SingleAccount';
import SingleCard from '@/components/admin/accounts/single-card-view/SingleCard';
import CardContactDetails from '@/components/admin/accounts/single-card-view/CardContactDetails';
import CardDetails from '@/components/admin/accounts/single-card-view/CardDetails';
import CardMobilePreview from '@/components/admin/accounts/single-card-view/CardMobilePreview';
import CardLink from '@/components/admin/accounts/single-card-view/CardLink';
import CardAndroid from '@/components/admin/accounts/single-card-view/CardAndroid';
import CardPhoto from '@/components/admin/accounts/single-card-view/CardPhoto';
import CardDelete from '@/components/admin/accounts/single-card-view/CardDelete';
import CardLoginDetails from '@/components/admin/accounts/single-card-view/CardLoginDetails';
import Cards from '@/components/admin/accounts/single-account-view/Cards';
import AccountDetails from '@/components/admin/accounts/single-account-view/AccountDetails';
import AccountLoginDetails from '@/components/admin/accounts/single-account-view/AccountLoginDetails';
import AccountThemes from '@/components/admin/accounts/single-account-view/AccountThemes';
import ContactUs from '@/components/admin/contact-us/ContactUs';
import Statistics from '@/components/admin/statistics/Statistics';
import StatisticsTable from '@/components/admin/statistics/StatisticsTable';
import Settings from '@/components/admin/settings/Settings';
import Dashboard from '@/components/admin/dashboard/Dashboard';
import Help from '@/components/admin/help/Help';
import HowItWorks from '@/components/admin/how-it-works/HowItWorks';

const routes = [
  {
    path: '/',
    component: Admin,
  },
  {
    path: '/login',
    name: routeNames.LOGIN,
    component: Login,
  },
  {
    path: '/admin',
    redirect: { name: routeNames.DASHBOARD },
    name: routeNames.ADMIN,
    component: Admin,
    children: [
      {
        path: 'dashboard',
        name: routeNames.DASHBOARD,
        component: Dashboard,
      },
      {
        path: 'accounts',
        name: routeNames.ACCOUNTS,
        redirect: { name: routeNames.ALL_ACCOUNTS },
        component: {
          render () {
            return h(resolveComponent('router-view'));
          },
        },
        children: [
          {
            path: 'all',
            name: routeNames.ALL_ACCOUNTS,
            component: AllAccounts,
          },
          {
            path: ':accountId',
            name: routeNames.SINGLE_ACCOUNT,
            redirect: { name: routeNames.CARDS },
            component: SingleAccount,
            props: route => {
              const accountId = route.params.accountId;

              const accountData = store.getters.getAccountById({ accountId });

              return {
                account: accountData,
              };
            },
            children: [
              {
                path: 'cards',
                name: routeNames.CARDS,
                component: Cards,
              },
              {
                path: 'account-details',
                name: routeNames.ACCOUNT_DETAILS,
                component: AccountDetails,
              },
              {
                path: 'account-login-details',
                name: routeNames.ACCOUNT_LOGIN_DETAILS,
                component: AccountLoginDetails,
              },
              {
                path: 'account-themes',
                name: routeNames.ACCOUNT_THEMES,
                component: AccountThemes,
              },
            ],
          },
          {
            path: 'card/:cardId',
            name: routeNames.SINGLE_CARD,
            redirect: { name: routeNames.CARD_CONTACT_DETAILS },
            component: SingleCard,
            props: route => {
              const cardId = route.params.cardId;

              const cardData = store.getters.getCardById({ cardId });
              const accountData = cardData ? store.getters.getAccountById({ accountId: cardData.accountId }) : {};

              return {
                card: cardData,
                account: accountData,
              };
            },
            children: [
              {
                path: 'contact-details',
                name: routeNames.CARD_CONTACT_DETAILS,
                component: CardContactDetails,
              },
              {
                path: 'card-details',
                name: routeNames.CARD_DETAILS,
                component: CardDetails,
              },
              {
                path: 'mobile-preview',
                name: routeNames.CARD_MOBILE_PREVIEW,
                component: CardMobilePreview,
              },
              {
                path: 'card-link',
                name: routeNames.CARD_LINK,
                component: CardLink,
              },
              {
                path: 'android-card',
                name: routeNames.CARD_ANDROID,
                component: CardAndroid,
              },
              {
                path: 'card-photo',
                name: routeNames.CARD_PHOTO,
                component: CardPhoto,
              },
              {
                path: 'card-delete',
                name: routeNames.CARD_DELETE,
                component: CardDelete,
              },
              {
                path: 'login-details',
                name: routeNames.CARD_LOGIN_DETAILS,
                component: CardLoginDetails,
              },
            ],
          },
        ],
      },
      {
        path: 'statistics',
        name: routeNames.STATISTICS,
        component: Statistics,
      },
      {
        path: 'account-statistics',
        name: routeNames.ACCOUNT_STATISTICS,
        component: StatisticsTable,
        props: () => {
          return {
            title: 'Account Statistics',
            statisticData: store.state.statistics.accounts,
            entryType: 'accounts',
          };
        },
      },
      {
        path: 'card-statistics',
        name: routeNames.CARD_STATISTICS,
        component: StatisticsTable,
        props: () => {
          return {
            title: 'Card Statistics',
            statisticData: store.state.statistics.cards,
            entryType: 'cards',
          };
        },
      },
      {
        path: 'settings',
        name: routeNames.SETTINGS,
        component: Settings,
      },
      {
        path: 'admin-accounts',
        name: routeNames.ADMIN_ACCOUNTS,
        redirect: { name: routeNames.ALL_ADMIN_ACCOUNTS },
        component: {
          render () {
            return h(resolveComponent('router-view'));
          },
        },
        children: [
          {
            path: 'all',
            name: routeNames.ALL_ADMIN_ACCOUNTS,
            component: AllAdminAccounts,
          },
          {
            path: ':accountId',
            name: routeNames.SINGLE_ADMIN_ACCOUNT,
            redirect: { name: routeNames.ACCOUNT_DETAILS },
            component: SingleAdminAccount,
            props: route => {
              const accountId = route.params.accountId;

              const accountData = store.getters.getAdminAccountById({ accountId });

              return {
                account: accountData,
              };
            },
            children: [
              {
                path: 'account-details',
                name: routeNames.ADMIN_ACCOUNT_DETAILS,
                component: AdminAccountDetails,
              },
              {
                path: 'delete-account',
                name: routeNames.ADMIN_ACCOUNT_DELETE,
                component: AdminAccountDelete,
              },
            ],
          },
        ],
      },
      {
        path: 'cards',
        name: routeNames.ALL_CARDS,
        component: AllCards,
      },
      {
        path: 'how-it-works',
        name: routeNames.HOW_IT_WORKS,
        component: HowItWorks,
      },
      {
        path: 'help',
        name: routeNames.HELP,
        component: Help,
      },
      {
        path: 'contact-us',
        name: routeNames.CONTACT_US,
        component: ContactUs,
      },
    ],
  },
];

export default routes;

import { DateTime } from 'luxon';

class AccountDriver {
    dataMappings = Object.freeze({
      contactPerson: {
        clientKey: 'contactPerson',
        serverKey: 'contactPerson',
        defaultValue: '',
      },
      contractEndAt: {
        clientKey: 'contractEndAt',
        serverKey: 'contractEnd',
        defaultValue: null,
        formatClientPropertyFunction: date => DateTime.fromISO(date).invalid ? null : date,
      },
      contractStartedAt: {
        clientKey: 'contractStartedAt',
        serverKey: 'contractStarted',
        defaultValue: null,
        formatClientPropertyFunction: date => DateTime.fromISO(date).invalid ? null : date,
      },
      accountCreatedAt: {
        clientKey: 'accountCreatedAt',
        serverKey: 'createdAt',
        defaultValue: null,
      },
      isCustomerUnderControl: {
        clientKey: 'isCustomerUnderControl',
        serverKey: 'customerUnderControl',
        defaultValue: false,
        formatClientPropertyFunction: value => Boolean(Number(value)),
      },
      contractOptions: {
        clientKey: 'contractOptions',
        serverKey: 'contractOptions',
        defaultValue: [],
      },
      email: {
        clientKey: 'email',
        serverKey: 'email',
        defaultValue: '',
      },
      accountId: {
        clientKey: 'accountId',
        serverKey: 'id',
        defaultValue: '',
        formatServerPropertyFunction: id => String(id),
      },
      maintenanceModule: {
        clientKey: 'maintenanceModule',
        serverKey: 'maintenanceModule',
        defaultValue: '',
      },
      accountName: {
        clientKey: 'accountName',
        serverKey: 'name',
        defaultValue: '',
      },
      otherNotice: {
        clientKey: 'otherNotice',
        serverKey: 'otherNotice',
        defaultValue: '',
      },
      user: {
        clientKey: 'user',
        serverKey: 'user',
        defaultValue: {},
      },
      userId: {
        clientKey: 'userId',
        serverKey: 'userId',
        defaultValue: '',
      },
      loginEmail: {
        clientKey: 'loginEmail',
        serverKey: 'loginEmail',
        defaultValue: '',
      },
      loginPassword: {
        clientKey: 'loginPassword',
        serverKey: 'loginPassword',
        defaultValue: '',
      },
      isAccountEnabled: {
        clientKey: 'isAccountEnabled',
        serverKey: 'isEnabled',
        defaultValue: false,
      },
      themes: {
        clientKey: 'themes',
        serverKey: 'themes',
        defaultValue: null,
      },
    })

    formatServerData (serverData) {
      return Object.keys(this.dataMappings).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.clientKey] = objMap.formatServerPropertyFunction
          ? objMap.formatServerPropertyFunction(serverData[objMap.serverKey])
          : serverData[objMap.serverKey];

        return acc;
      }, {});
    }

    formatClientData (clientData) {
      return Object.keys(clientData).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.serverKey] = objMap.formatClientPropertyFunction
          ? objMap.formatClientPropertyFunction(clientData[objMap.clientKey])
          : clientData[objMap.clientKey];

        return acc;
      }, {});
    }

    createClientFormData (account, configList) {
      return configList.reduce((acc, curVal) => {
        const config = typeof curVal === 'string' ? { clientKey: curVal } : curVal;

        const { clientKey, formatFunction } = config;

        const value = account[clientKey] || this.dataMappings[clientKey].defaultValue;

        acc[clientKey] = formatFunction ? formatFunction(value) : value;

        return acc;
      }, {});
    }
}

export default new AccountDriver();

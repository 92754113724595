import { routeNames } from '@/config/routes';
import { features } from '@/config/features';

export const cardConfig = Object.freeze({
  accountStatistics: {
    title: 'Account Statistics',
    icon: 'user-circle',
    routeName: routeNames.ACCOUNT_STATISTICS,
    featureName: features.ACCOUNT_STATISTICS,
  },
  cardStatistics: {
    title: 'Card Statistics',
    icon: 'address-card',
    routeName: routeNames.CARD_STATISTICS,
    featureName: features.CARD_STATISTICS,
  },
});

export const statisticsTableColumnsConfig = Object.freeze({
  profileTotal: {
    key: 'profileTotal',
    tooltip: 'Number of Profile page views',
    label: 'Views',
  },
  saved: {
    key: 'saved',
    tooltip: 'Number of times profile has been saved',
    label: 'Saved',
  },
  shared: {
    key: 'shared',
    tooltip: 'Number of times profile has been shared',
    label: 'Shared',
  },
  card: {
    key: 'card',
    tooltip: 'Profile page views from scanning a card',
    label: 'Card',
  },
  virtago: {
    key: 'virtago',
    tooltip: 'Profile page views through Virtago',
    label: 'Virtago',
  },
  sticker: {
    key: 'sticker',
    tooltip: 'Profile page views from scanning a sticker',
    label: 'Sticker',
  },
  mobileRing: {
    key: 'mobileRing',
    tooltip: 'Profile page views from scanning a mobile ring',
    label: 'Ring',
  },
  petago: {
    key: 'petago',
    tooltip: 'Profile page views from scanning a pet tag',
    label: 'Petago',
  },
  other: {
    key: 'other',
    tooltip: 'Profile page views through unidentified sources',
    label: 'Other',
  },
});

<template>
    <div v-if="accountDetailsFormData" class="account-details-form-container">
        <CForm @submit.prevent="handleFormSubmit">
            <CRow class="mb-3">
                <CFormLabel
                    class="col-sm-4 col-form-label"
                    for="account-name">
                    Account Name:
                </CFormLabel>

                <CCol sm="8">
                    <CFormInput
                        id="account-name"
                        name="account-name"
                        type="text"
                        placeholder="Your Account Name"
                        :value="accountDetailsFormData.accountName"
                        @input="handleInputDataChange($event, 'accountName')"/>
                </CCol>
            </CRow>

            <CRow class="mb-3">
                <CFormLabel
                    class="col-sm-4 col-form-label"
                    for="contact-person">
                    Contact Person:
                </CFormLabel>

                <CCol sm="8">
                    <CFormInput
                        id="contact-person"
                        name="contact-person"
                        type="text"
                        placeholder="Your Full Name"
                        :value="accountDetailsFormData.contactPerson"
                        @input="handleInputDataChange($event, 'contactPerson')"/>
                </CCol>
            </CRow>

            <CRow class="mb-3">
                <CFormLabel
                    class="col-sm-4 col-form-label"
                    for="email">
                    Email:
                </CFormLabel>

                <CCol sm="8">
                    <CFormInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter Your Email Address"
                        :value="accountDetailsFormData.email"
                        @input="handleInputDataChange($event, 'email')"/>
                </CCol>
            </CRow>

            <br />

            <template v-if="canChangeContractDetails">
              <CRow class="mb-3">
                  <CFormLabel
                      class="col-sm-4 col-form-label"
                      for="customer-under-control">
                      Customer Under Contract:
                  </CFormLabel>

                  <CCol sm="8">
                      <CFormSelect
                      :options="[
                          { label: 'Yes', value: '1' },
                          { label: 'No', value: '0' },
                      ]"
                      :value="accountDetailsFormData.isCustomerUnderControl"
                      @change="handleSelectDataChange($event, 'isCustomerUnderControl')"/>
                  </CCol>
              </CRow>

              <fieldset
                  v-if="Boolean(Number(accountDetailsFormData.isCustomerUnderControl))"
                  class="row mb-3">
                  <legend class="col-form-label col-sm-4 pt-0">Contract Options</legend>
                  <CCol sm="8">
                      <CFormCheck
                          type="checkbox"
                          name="contract-options"
                          id="contract-options"
                          value="unlimited_yearly_updates"
                          label="Unlimited yearly updates"
                          :checked="accountDetailsFormData.contractOptions.includes('unlimited_yearly_updates')"
                          @change="handleCheckboxDataChange($event, 'contractOptions', 'unlimited_yearly_updates')" />

                      <CFormCheck
                          type="checkbox"
                          name="contract-options"
                          id="contract-options"
                          value="multiple_language_pack"
                          label="Multiple language pack"
                          :checked="accountDetailsFormData.contractOptions.includes('multiple_language_pack')"
                          @change="handleCheckboxDataChange($event, 'contractOptions', 'multiple_language_pack')" />

                      <CFormCheck
                          type="checkbox"
                          name="contract-options"
                          id="contract-options"
                          value="forever_updates"
                          label="Forever updates"
                          :checked="accountDetailsFormData.contractOptions.includes('forever_updates')"
                          @change="handleCheckboxDataChange($event, 'contractOptions', 'forever_updates')" />

                      <CFormCheck
                          type="checkbox"
                          name="contract-options"
                          id="contract-options"
                          value="statistics"
                          label="Statistics"
                          :checked="accountDetailsFormData.contractOptions.includes('statistics')"
                          @change="handleCheckboxDataChange($event, 'contractOptions', 'statistics')" />

                      <CFormCheck
                          type="checkbox"
                          name="contract-options"
                          id="contract-options"
                          value="themes"
                          label="Themes"
                          :checked="accountDetailsFormData.contractOptions.includes('themes')"
                          @change="handleCheckboxDataChange($event, 'contractOptions', 'themes')" />
                  </CCol>
              </fieldset>

              <br />

              <CRow class="mb-3" />

              <CRow
                v-if="Boolean(Number(accountDetailsFormData.isCustomerUnderControl))"
                class="mb-3">
                  <CFormLabel
                      class="col-sm-4 col-form-label"
                      for="contract-started">
                      Contract Started:
                  </CFormLabel>

                  <CCol sm="8">
                      <CFormInput
                          id="contract-started"
                          name="contract-started"
                          type="date"
                          placeholder="Enter date"
                          :value="accountDetailsFormData.contractStartedAt"
                          @change="handleInputDataChange($event, 'contractStartedAt')"/>
                  </CCol>
              </CRow>

              <CRow
                v-if="Boolean(Number(accountDetailsFormData.isCustomerUnderControl))"
                class="mb-3">
                  <CFormLabel
                      class="col-sm-4 col-form-label"
                      for="contract-end">
                      Contract End:
                  </CFormLabel>

                  <CCol sm="8">
                      <CFormInput
                          id="contract-end"
                          name="contract-end"
                          type="date"
                          placeholder="Enter date"
                          :value="accountDetailsFormData.contractEndAt"
                          @change="handleInputDataChange($event, 'contractEndAt')"/>
                  </CCol>
              </CRow>

              <CRow class="mb-3">
                  <CFormLabel
                      class="col-sm-4 col-form-label"
                      for="other-notice">
                      Other Notice:
                  </CFormLabel>

                  <CCol sm="8">
                      <CFormTextarea
                          rows="8"
                          id="other-notice"
                          name="other-notice"
                          placeholder="Enter notice"
                          :value="accountDetailsFormData.otherNotice"
                          @change="handleInputDataChange($event, 'otherNotice')"/>
                  </CCol>
              </CRow>

              <CRow class="mb-3">
                  <CFormLabel class="col-sm-4 col-form-label" />

                  <CCol sm="6">
                      <CFormSwitch
                          size="lg"
                          label="Disable / Enable account"
                          id="disable-account"
                          :checked="accountDetailsFormData.isAccountEnabled"
                          @change="handleInputDataChange($event, 'isAccountEnabled')"/>
                  </CCol>
              </CRow>
            </template>

            <CRow class="mb-3 action-buttons">
                <CFormLabel class="col-sm-6 col-form-label" />

                <CButton
                    color="info"
                    size="sm"
                    variant="outline"
                    @click="handleCancel">
                    Cancel
                </CButton>

                <CButton
                    class="save-button"
                    color="info"
                    size="sm"
                    type="submit">
                    Save
                </CButton>
            </CRow>
        </CForm>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { DateTime } from 'luxon';
import { routeNames } from '@/config/routes';
import { features } from '@/config/features';
import userService from '@/services/UserService';
import accountDriver from '@/drivers/AccountDriver';

export default {
  name: 'AccountDetailsForm',
  data () {
    return {
      accountDetailsFormData: accountDriver.createClientFormData(this.account, [
        'accountName',
        'contactPerson',
        'email',
        {
          clientKey: 'isCustomerUnderControl',
          formatFunction: value => value ? '1' : '0',
        },
        'contractOptions',
        {
          clientKey: 'contractStartedAt',
          formatFunction: date => DateTime.fromISO(date).toFormat('yyyy-MM-dd'),
        },
        {
          clientKey: 'contractEndAt',
          formatFunction: date => DateTime.fromISO(date).toFormat('yyyy-MM-dd'),
        },
        'otherNotice',
        'isAccountEnabled',
        'maintenanceModule',
      ]),
    };
  },
  props: {
    account: Object,
  },
  computed: {
    canChangeContractDetails () {
      return userService.hasFeatureAccess(features.EDIT_ACCOUNT_CONTRACT_DETAILS);
    },
  },
  methods: {
    ...mapActions(['updateAccount']),
    ...mapMutations(['addAlert']),
    handleInputDataChange (value, propertyChanged) {
      this.accountDetailsFormData[propertyChanged] = value;
    },
    handleSelectDataChange (optionSelected, propertyChanged) {
      this.accountDetailsFormData[propertyChanged] = Number(optionSelected) ? '1' : '0';
    },
    handleCheckboxDataChange (checkboxState, propertyChanged, value) {
      if (checkboxState) {
        this.accountDetailsFormData[propertyChanged].push(value);
      } else {
        const elementToRemoveIndex = this.accountDetailsFormData[propertyChanged].indexOf(value);

        this.accountDetailsFormData[propertyChanged].splice(elementToRemoveIndex, 1);
      }
    },
    handleCancel () {
      this.$router.push({
        name: routeNames.ALL_ACCOUNTS,
      });
    },
    handleFormSubmit () {
      this.updateAccount({
        account: {
          accountId: this.account.accountId,
          ...this.accountDetailsFormData,
        },
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Account Edited Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Account Edit Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .account-details-form-container {
        margin-top: 20px;

        select {
            width: 100px;
        }
    }

    .col-form-label {
        text-align: right;
        font-size: 14px;
        font-weight: 600;
    }

    .edit-field-button {
        border: 1px solid var(--cui-input-border-color, #b1b7c1);
        background-color: var(--white);
    }

    .action-buttons {
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }
</style>

import {
  library,
} from '@fortawesome/fontawesome-svg-core';

import {
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import {
  faSignOutAlt,
  faUser,
  faChartLine,
  faUserCircle,
  faSearch,
  faQuestionCircle,
  faEnvelope,
  faAddressCard,
  faCubes,
  faBookOpen,
  faLink,
  faPrint,
  faMobileAlt,
  faPhoneAlt,
  faGlobe,
  faBuilding,
  faCog,
  faEye,
  faEyeSlash,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faSignOutAlt,
  faUser,
  faChartLine,
  faUserCircle,
  faSearch,
  faQuestionCircle,
  faEnvelope,
  faAddressCard,
  faCubes,
  faBookOpen,
  faEdit,
  faTrashAlt,
  faLink,
  faBuilding,
  faPrint,
  faMobileAlt,
  faPhoneAlt,
  faGlobe,
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faCog,
  faEye,
  faEyeSlash,
  faInfoCircle,
);

export const endpoints = Object.freeze({
  authentication: '/api/authentication/token',
  accounts: '/api/accounts',
  adminAccounts: '/api/admin-accounts',
  managedAccounts: '/api/accounts/managed',
  cards: '/api/cards',
  managedCards: '/api/cards/managed',
  languages: '/api/languages',
  statistics: '/api/statistics',
  storage: location.hostname === 'admin.tagocards.com' ? 'https://storage.googleapis.com/themes-prod' : 'https://storage.googleapis.com/themes-dev',
});

<template>
  <div class="login-view">
      <h1 class="login-heading">Let's get started now!</h1>

      <CForm @submit.prevent="handleLogin">
        <div class="login-form-container">

            <div class="login-form-input-wrapper">
                <label for="email">Email:</label>
                <input
                    id="email"
                    type="email"
                    required
                    v-model="userCredentials.email"/>
            </div>

            <div
              v-if="!isForgetPassword"
              class="login-form-input-wrapper">
                <label for="password">Password:</label>
                <input
                    id="password"
                    type="password"
                    required
                    v-model="userCredentials.password"/>
            </div>

            <CButton
                v-if="!isForgetPassword"
                class="login-form-submit-button"
                color="info"
                size="sm"
                type="submit">
                Log In
            </CButton>

            <CButton
                v-else
                class="login-form-submit-button"
                color="info"
                size="sm"
                type="button"
                @click.prevent="handleForgotPassword">
                Send Password
            </CButton>

          <a
            class="login-form-forgot-password-text text-info"
            @click.prevent="isForgetPassword = !isForgetPassword">{{ isForgetPassword ? 'Login' : 'Forgot password?' }}</a>

          <div class="login-form-footer">
            <a href="https://tagocards.com">
              &#8592; &nbsp; Back to Main Website
            </a>
          </div>
        </div>
      </CForm>
  </div>
</template>

<script>
import authenticationService from '@/services/AuthenticationService';

export default {
  name: 'Login',
  data () {
    return {
      isForgetPassword: false,
      userCredentials: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    handleLogin () {
      authenticationService.login(this.userCredentials.email, this.userCredentials.password)
        .then(() => {
          this.$router.push({ name: 'Admin' });
        });
    },
    handleForgotPassword () {
      authenticationService.forgotPassword(this.userCredentials.email);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';

  .login-view {
    background: url("~@/assets/images/login-bg-progressive.jpeg") center center/cover no-repeat;
    background-color: rgb(10, 142, 207);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login-heading {
    width: 100%;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    color: white;
    margin-bottom: 50px;
  }

  .login-form-container {
    position: relative;
    background-color: #f0f0f0;
    display: flex;
    border-radius: 10px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    width: 500px;

    &:before {
      content: '';
      background-image: url('../assets/images/logo.png');
      background-size: 80%;
      background-position: center;
      background-repeat: no-repeat;
      width: 100px;
      height: 100px;
      background-color: #f0f0f0;
      border-radius: 50%;
      position: absolute;
      top: -30px;
    }

    .login-form-input-wrapper {
      width: 70%;
      height: 45px;
      margin: 20px;

      label {
        font-weight: 600;
      }

      input {
        border: none;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        padding: 0 10px;

        &:focus, &:active, &:focus-visible {
            outline-color: var(--tago-blue);
        }
      }
    }

    .login-form-submit-button {
      margin: 20px 0 10px;
      color: var(--white);
      min-width: 100px;
      height: 40px;
    }

    .login-form-forgot-password-text {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
    }

    .login-form-footer {
      border-top: 1px solid white;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;

      a {
        font-size: 14px;
        text-decoration: none;
        font-weight: 600;
        color: inherit;
      }

      a:hover {
        color: black;
      }
    }
  }

</style>

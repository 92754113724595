export const features = Object.freeze({
  VIEW_ACCOUNTS: 'viewAccounts',
  GET_ALL_ACCOUNTS: 'getAllAccounts',
  GET_ALL_CARDS: 'getAllCards',
  CREATE_ACCOUNT: 'createAccount',
  EDIT_ACCOUNT: 'editAccount',
  EDIT_ACCOUNT_CONTRACT_DETAILS: 'editAccountContractDetails',
  DELETE_ACCOUNT: 'deleteAccount',
  ADD_CARD: 'addCard',
  CHANGE_CARD_OWNERSHIP: 'changeCardOwnership',
  SETTINGS: 'settings',
  VIEW_ADMIN_ACCOUNTS: 'viewAdminAccounts',
  CREATE_ADMIN_ACCOUNT: 'createAdminAccount',
  STATISTICS: 'viewStatistics',
  ACCOUNT_STATISTICS: 'accountStatistics',
  CARD_STATISTICS: 'cardStatistics',
  TAGO_PRODUCTS_MANUFACTURE: 'tagoProductsManufacture',
  ACCOUNT_THEMES: 'accountThemes',
});

import { routeNames } from '@/config/routes';

export const navigationItems = Object.freeze({
  [routeNames.DASHBOARD]: {
    title: 'Dashboard',
    icon: 'cubes',
    routeName: routeNames.DASHBOARD,
  },
  [routeNames.ALL_ACCOUNTS]: {
    title: 'Accounts',
    icon: 'user-circle',
    routeName: routeNames.ACCOUNTS,
  },
  [routeNames.STATISTICS]: {
    title: 'Statistics',
    icon: 'chart-line',
    routeName: routeNames.STATISTICS,
  },
  [routeNames.HOW_IT_WORKS]: {
    title: 'How It Works',
    icon: 'book-open',
    routeName: routeNames.HOW_IT_WORKS,
  },
  [routeNames.SETTINGS]: {
    title: 'Settings',
    icon: 'cog',
    routeName: routeNames.SETTINGS,
  },
  [routeNames.HELP]: {
    title: 'Help',
    icon: 'question-circle',
    routeName: routeNames.HELP,
  },
  [routeNames.CONTACT_US]: {
    title: 'Contact Us',
    icon: 'envelope',
    routeName: routeNames.CONTACT_US,
  },
});

export const sortedNavigationItems = [
  navigationItems[routeNames.DASHBOARD],
  navigationItems[routeNames.ALL_ACCOUNTS],
  navigationItems[routeNames.STATISTICS],
  navigationItems[routeNames.HOW_IT_WORKS],
  navigationItems[routeNames.SETTINGS],
  navigationItems[routeNames.HELP],
  navigationItems[routeNames.CONTACT_US],
];

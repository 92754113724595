<template>
    <div class="nav-sidebar-container">
        <div class="nav-sidebar-sections-wrapper">
            <NavSidebarItem
                v-for="(item, index) in navigationItems"
                :key="index"
                :isActive="isMatchingRoute(item.routeName)"
                :title="item.title"
                :icon="item.icon"
                @click="() => handleNavigationChange(item)"/>
        </div>

        <div class="nav-sidebar-logout-wrapper">
            <div
                class="nav-sidebar-logout-button"
                @click="handleLogout">
                <font-awesome-icon icon="sign-out-alt" />

                <span>Log Out</span>
            </div>
        </div>
    </div>
</template>

<script>
import { sortedNavigationItems } from '@/config/sidebar';
import { mapGetters } from 'vuex';
import userService from '@/services/UserService';
import authenticationService from '@/services/AuthenticationService';
import NavSidebarItem from '@/components/admin/navigation/NavSidebarItem';

export default {
  name: 'NavSidebar',
  components: {
    NavSidebarItem,
  },
  data () {
    return {
      activeNavItem: null,
      numberOfAllowedRoutes: 1,
      intervalId: null,
    };
  },
  computed: {
    ...mapGetters(['isFetchingPermissionsBasedData']),
    allowedRoutes () {
      return this.numberOfAllowedRoutes && userService.getCurrentUserPermissions().routes;
    },
    navigationItems () {
      return sortedNavigationItems
        .filter(routeConfig => {
          return this.allowedRoutes.includes(routeConfig.routeName);
        });
    },
  },
  methods: {
    handleLogout () {
      authenticationService.logout();
    },
    handleNavigationChange (navigationItem) {
      this.activeNavItem = navigationItem;

      this.$router.push({ name: navigationItem.routeName });
    },
    isMatchingRoute (routeName) {
      return this.$route.matched.some(route => route.name === routeName);
    },
  },
  watch: {
    isFetchingPermissionsBasedData (newVal) {
      if (newVal) {
        this.intervalId = setInterval(() => {
          if (this.numberOfAllowedRoutes !== this.allowedRoutes.length) {
            this.numberOfAllowedRoutes = this.allowedRoutes.length;
          }
        }, 50);
      } else {
        setTimeout(() => {
          clearInterval(this.intervalId);
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .nav-sidebar-container {
        background-color: var(--white);
        position: fixed;
        width: $nav-sidebar-width;
        height: 100%;
        margin-top: $nav-border-width;
    }

    .nav-sidebar-sections-wrapper {
        height: calc(100% - 250px);
    }

    .nav-sidebar-logout-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        border-top: $nav-border-width solid var(--background-grey);

        .nav-sidebar-logout-button {
            cursor: pointer;
        }

        span {
            padding-left: 5px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
        }
    }
</style>

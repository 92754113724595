<template>
    <div class="single-admin-account-section-container">
        <template v-if="account">
          <div class="single-admin-account-header">
            <div class="single-admin-account-title">
                <router-link :to="{ name: routeNames.ALL_ADMIN_ACCOUNTS }">◄</router-link>
                Administrator Account / {{ account.accountName }}
            </div>

            <div class="section-navigation-wrapper">
                <SectionNavigation :navigation-list="singleAdminAccountSectionNavigationConfig" />
            </div>
          </div>

          <router-view :account="account" />
        </template>

        <div v-else class="text-center">
          <br/>
          <CSpinner color="info"/>
          <h5 class="text-info">Loading Administrator Account information</h5>
        </div>
    </div>
</template>

<script>
import { singleAdminAccountConfig } from '@/config/sectionNavigation';
import { routeNames } from '@/config/routes';
import SectionNavigation from '@/components/admin/shared/SectionNavigation';

export default {
  name: 'SingleAdminAccount',
  components: {
    SectionNavigation,
  },
  data () {
    return {
      routeNames,
    };
  },
  props: {
    account: Object,
  },
  computed: {
    singleAdminAccountSectionNavigationConfig () {
      return singleAdminAccountConfig;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .single-admin-account-section-container {
        margin-top: $nav-item-height + $nav-border-width;
        min-width: 550px;
        max-width: 1000px;
        margin-right: 10px;
    }

    .section-navigation-wrapper {
      width: 100%;
    }

    .single-admin-account-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: $nav-item-height;
        font-weight: 700;
        font-size: 18px;

        button {
          color: var(--white);
        }
    }

    .single-admin-account-title a {
      text-decoration: none;
      color: var(--tago-blue);
    }
</style>

<template>
    <div class="all-cards-section-container">
        <div class="all-cards-header">
            <div class="all-cards-title">
                Cards
                <CSpinner
                    v-if="isFetchingInProgress"
                    size="sm"
                    color="info"/>
            </div>
        </div>

         <div class="card-list-wrapper">
            <template v-if="cards.length">
                <CardItem
                    v-for="(card, index) in cards"
                    :key="index"
                    :card="card"/>
            </template>

            <div v-if="!cards.length && !isFetchingInProgress" class="no-cards-message">
                This Account Has no Cards
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CardItem from '@/components/admin/shared/CardItem';

export default {
  name: 'SingleAccount',
  components: {
    CardItem,
  },
  computed: {
    ...mapState({
      isFetchingInProgress: state => state.isFetchingCards,
      cards: state => state.cards,
    }),
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .all-cards-section-container {
        margin-top: $nav-item-height + $nav-border-width;
        min-width: 550px;
        max-width: 1000px;
        margin-right: 10px;
    }

    .all-cards-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: $nav-item-height;
        font-weight: 700;
        font-size: 18px;

        button {
          color: var(--white);
        }
    }

    .card-list-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: $standard-border-width;
        justify-content: space-between;
    }

    .no-cards-message {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
</style>

<template>
    <div class="card-item">
        <div class="card-name">
            {{ card.cardOwnerName }}
        </div>

        <div>
            {{ formatDate(card.cardCreatedAt) }}
        </div>

        <div class="action-buttons-wrapper">
            <CButton
                class="view-card-button action-button"
                variant="outline"
                color="info"
                @click="handleViewProfile">
                View Profile
            </CButton>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import { routeNames } from '@/config/routes';

export default {
  name: 'CardItem',
  props: {
    card: Object,
  },
  methods: {
    formatDate (date) {
      return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
    },
    handleViewProfile () {
      this.$router.push({
        name: routeNames.SINGLE_CARD,
        params: {
          cardId: this.card.cardId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';
  @import '@/assets/scss/sidebar';

  .card-item {
      background-color: var(--white);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;
      width: 100%;
      font-size: 14px;
  }

  .card-name {
      margin-right: 10px;
      width: 50%;
  }

  .action-buttons-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: end;
    width: 40%;
    max-width: 200px;

    .action-button {
        margin: 0 5px;
    }

    .view-card-button {
        font-size: 12px;
        width: 110px;
    }
  }
</style>

import { features } from '@/config/features';
import { routeNames } from '@/config/routes';

export const roles = Object.freeze({
  BASIC: 'BASIC',
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
});

export const userPermissions = {
  BASIC: {
    routes: [
      routeNames.DASHBOARD,
      routeNames.ALL_CARDS,
      routeNames.CONTACT_US,
      routeNames.CARDS,
      routeNames.CARD_LOGIN_DETAILS,
      routeNames.SINGLE_CARD,
      routeNames.CARD_CONTACT_DETAILS,
      routeNames.CARD_DETAILS,
      routeNames.CARD_MOBILE_PREVIEW,
      routeNames.CARD_LINK,
      routeNames.CARD_PHOTO,
    ],
    features: [],
  },
  ADMIN: {
    routes: [
      routeNames.DASHBOARD,
      routeNames.ALL_ACCOUNTS,
      routeNames.ALL_CARDS,
      routeNames.SINGLE_ACCOUNT,
      routeNames.CARDS,
      routeNames.CARD_LOGIN_DETAILS,
      routeNames.ACCOUNT_DETAILS,
      routeNames.ACCOUNT_LOGIN_DETAILS,
      routeNames.CONTACT_US,
      routeNames.SINGLE_CARD,
      routeNames.CARD_CONTACT_DETAILS,
      routeNames.CARD_DETAILS,
      routeNames.CARD_MOBILE_PREVIEW,
      routeNames.CARD_LINK,
      routeNames.CARD_PHOTO,
      routeNames.CARD_DELETE,
    ],
    features: [
      features.VIEW_ACCOUNTS,
      features.ADD_CARD,
      features.CHANGE_CARD_OWNERSHIP,
    ],
  },
  SUPER_ADMIN: {
    routes: [
      routeNames.DASHBOARD,
      routeNames.ALL_ACCOUNTS,
      routeNames.ALL_CARDS,
      routeNames.SINGLE_ACCOUNT,
      routeNames.CARDS,
      routeNames.CARD_LOGIN_DETAILS,
      routeNames.ACCOUNT_DETAILS,
      routeNames.ACCOUNT_LOGIN_DETAILS,
      routeNames.ACCOUNT_THEMES,
      routeNames.CONTACT_US,
      routeNames.SINGLE_CARD,
      routeNames.CARD_CONTACT_DETAILS,
      routeNames.CARD_DETAILS,
      routeNames.CARD_MOBILE_PREVIEW,
      routeNames.CARD_LINK,
      routeNames.CARD_ANDROID,
      routeNames.CARD_PHOTO,
      routeNames.CARD_DELETE,
      routeNames.SETTINGS,
      routeNames.ALL_ADMIN_ACCOUNTS,
      routeNames.ADMIN_ACCOUNT_DETAILS,
      routeNames.ADMIN_ACCOUNT_DELETE,
    ],
    features: [
      features.VIEW_ACCOUNTS,
      features.GET_ALL_ACCOUNTS,
      features.GET_ALL_CARDS,
      features.CREATE_ACCOUNT,
      features.EDIT_ACCOUNT,
      features.EDIT_ACCOUNT_CONTRACT_DETAILS,
      features.DELETE_ACCOUNT,
      features.ADD_CARD,
      features.CHANGE_CARD_OWNERSHIP,
      features.VIEW_ADMIN_ACCOUNTS,
      features.CREATE_ADMIN_ACCOUNT,
      features.TAGO_PRODUCTS_MANUFACTURE,
    ],
  },
};

<template>
    <div class="alert-wrapper">
        <CAlert
            v-for="(alert, index) in alerts"
            :key="index"
            :ref="hideAlertAfterDelay"
            variant="solid"
            :color="alert.type"
            dismissible
            @close="alert">
            {{ alert.message }}
        </CAlert>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Alert',
  computed: {
    ...mapState({
      alerts: state => state.alerts,
    }),
  },
  methods: {
    hideAlertAfterDelay (alert) {
      if (alert && alert.visible) {
        setTimeout(() => {
          const alertElement = alert.$el;

          if (alertElement?.children) {
            alertElement.children[0].click();
          }
        }, 3000);
      }
    },
  },
};
</script>

<style>
    .alert-dismissible button.btn-close {
        padding-top: 13px;
        padding-bottom: 13px;
    }
</style>

<style lang="scss" scoped>
    .alert-wrapper {
        left:0;
        right:0;
        margin-left: auto;
        margin-right: auto;
        position: fixed;
        max-width: 600px;
        width: 60%;

        div {
            border-radius: 20px;
            margin-top: 10px;
            text-align: center;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
</style>

<template>
  <div
    class="dashboard-card"
    @click="handleCardClick">
    <font-awesome-icon :icon="icon" />

    <div class="dashboard-card-info-wrapper">
      <span class="dashboard-card-name">{{ title }}</span>

      <CSpinner
        v-if="isFetchingInProgress"
        size="sm"
        color="info"/>

      <span
        v-else
        class="dashboard-card-number">
        {{ count }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardCard',
  props: {
    title: String,
    count: [Number, String],
    icon: String,
    routeName: String,
    isFetchingInProgress: Boolean,
  },
  methods: {
    handleCardClick () {
      this.$router.push({ name: this.routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';

 .dashboard-card {
    cursor: pointer;
    border-radius: 10px;
    padding: 20px;
    margin: 0 10px;
    width: 200px;
    background-color: var(--white);

    svg {
      color: var(--tago-blue);
      font-size: 25px;
    }
  }

  .dashboard-card-info-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: end;

    .dashboard-card-name {
      font-weight: 700;
      width: 50%;
    }
  }
</style>

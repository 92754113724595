import axios from 'axios';

const uninterceptedAxiosInstance = axios.create();

class FontService {
  async getAvailableFonts () {
    try {
      const googleFontsApiKey = 'AIzaSyDoir1hEKmsF8yuRZnpKI7mxppcc9JOSRA';
      const { data } = await uninterceptedAxiosInstance.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${googleFontsApiKey}&sort=alpha&fields=items.family`);

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default new FontService();

<template>
    <div v-if="account" class="single-account-section-container">
        <div class="single-account-header">
            <div class="single-account-title">
                <router-link
                  :to="{
                    name: routeNames.ACCOUNTS,
                  }">
                ◄
                </router-link>
                {{ account.accountName }}
                <CSpinner
                    v-if="isFetchingInProgress"
                    size="sm"
                    color="info"/>
            </div>

            <CButton
                v-if="isAddCardButtonVisible"
                size="sm"
                color="info"
                @click="showAddCardItem">
                + Add New Card
            </CButton>

            <div v-if="this.account" class="section-navigation-wrapper">
                <SectionNavigation :navigation-list="singleAccountSectionNavigationConfig" />
            </div>
        </div>

        <router-view
          :account="account"
          :is-add-card-item-displayed="isAddCardItemDisplayed"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { features } from '@/config/features';
import { singleAccountConfig } from '@/config/sectionNavigation';
import { routeNames } from '@/config/routes';
import SectionNavigation from '@/components/admin/shared/SectionNavigation';
import userService from '@/services/UserService';

export default {
  name: 'SingleAccount',
  components: {
    SectionNavigation,
  },
  props: {
    account: Object,
  },
  data () {
    return {
      routeNames,
      isAddCardItemDisplayed: false,
    };
  },
  computed: {
    ...mapState({
      isFetchingInProgress: state => state.isFetchingCards,
    }),
    isAddCardButtonVisible () {
      return userService.hasFeatureAccess(features.ADD_CARD) && this.$route.name === routeNames.CARDS;
    },
    singleAccountSectionNavigationConfig () {
      if (!this.account) {
        return singleAccountConfig;
      }

      return singleAccountConfig.map(config => {
        if (config.routeName === routeNames.ACCOUNT_THEMES && this.account?.contractOptions?.includes('themes')) {
          return {
            ...config,
            forceAllowRoute: true,
          };
        }

        return config;
      });
    },
  },
  methods: {
    showAddCardItem () {
      this.isAddCardItemDisplayed = true;
    },
    hideAddAccountItem () {
      this.isAddCardItemDisplayed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .single-account-section-container {
        margin-top: $nav-item-height + $nav-border-width;
        min-width: 550px;
        max-width: 1000px;
        margin-right: 10px;
    }

    .section-navigation-wrapper {
      width: 100%;
    }

    .single-account-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: $nav-item-height;
        font-weight: 700;
        font-size: 18px;

        button {
          color: var(--white);
        }
    }

    .single-account-title a {
      text-decoration: none;
      color: var(--tago-blue);
    }
</style>

import store from '@/store';

export const routeNames = Object.freeze({
  LOGIN: 'Login',
  ADMIN: 'Admin',
  DASHBOARD: 'Dashboard',
  ACCOUNTS: 'Accounts',
  ALL_ACCOUNTS: 'AllAccounts',
  ALL_CARDS: 'AllCards',
  SINGLE_ACCOUNT: 'SingleAccount',
  SINGLE_CARD: 'SingleCard',
  STATISTICS: 'Statistics',
  ACCOUNT_STATISTICS: 'AccountStatistics',
  CARD_STATISTICS: 'CardStatistics',
  CARDS: 'Cards',
  CARD_CONTACT_DETAILS: 'CardContactDetails',
  CARD_DETAILS: 'CardDetails',
  CARD_MOBILE_PREVIEW: 'CardMobilePreview',
  CARD_LINK: 'CardLink',
  CARD_ANDROID: 'CardAndroid',
  CARD_PHOTO: 'CardPhoto',
  CARD_DELETE: 'CardDelete',
  CARD_LOGIN_DETAILS: 'CardLoginDetails',
  ACCOUNT_DETAILS: 'AccountDetails',
  ACCOUNT_LOGIN_DETAILS: 'AccountLoginDetails',
  ACCOUNT_THEMES: 'AccountThemes',
  HOW_IT_WORKS: 'HowItWorks',
  HELP: 'Help',
  CONTACT_US: 'ContactUs',
  SETTINGS: 'Settings',
  ADMIN_ACCOUNTS: 'AdminAccounts',
  ALL_ADMIN_ACCOUNTS: 'ViewAdminAccounts',
  ADMIN_ACCOUNT_DETAILS: 'AccountAccountDetails',
  ADMIN_ACCOUNT_DELETE: 'AdminAccountDelete',
});

export const routeSecurityHandlers = {
  [routeNames.ACCOUNT_THEMES]: routeData => {
    const account = store.getters.getAccountById({ accountId: routeData.params.accountId });

    if (account?.contractOptions?.includes('themes')) {
      return true;
    }

    return false;
  },
};

<template>
    <div class="nav-header-container">
        <div class="nav-header-logo-wrapper">
            <a
                href="https://tagocards.com"
                target='_blank' />
        </div>

        <div class="nav-header-search-wrapper">
            <div class="nav-header-search-form-wrapper">
                <input
                    id="search"
                    type="text"
                    placeholder="Search"
                    v-model="searchText" />

                <CButton
                    class="nav-header-search-button"
                    color="info"
                    size="sm"
                    disabled>
                    <font-awesome-icon icon="search" />
                </CButton>
            </div>

            <div
                v-if="searchResults.length"
                class="nav-header-search-results-wrapper">
                <div class="nav-header-search-results">
                    <button
                        v-for="(searchResultItem, index) in searchResults"
                        :key="index"
                        class="nav-header-search-result-item"
                        @click="searchResultItem.handleRouteChange">
                        <h3>{{ searchResultItem.name }}</h3>

                        <h5>{{ searchResultItem.type }}</h5>
                    </button>
                </div>
            </div>
        </div>

        <div class="nav-header-user-wrapper">
            <font-awesome-icon icon="user" />

            <div class="nav-header-user-message">
                <span>Welcome,</span>

                <span>{{ username }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { routeNames } from '@/config/routes';
import userService from '@/services/UserService';

export default {
  name: 'NavHeader',
  data () {
    return {
      searchText: '',
    };
  },
  computed: {
    ...mapGetters([
      'getAccountsByName',
      'getCardsByName',
    ]),
    username () {
      return userService.getUsername();
    },
    searchResults () {
      if (this.searchText.length < 3) {
        return [];
      }

      const results = [];

      const accounts = this.getAccountsByName({ name: this.searchText });
      const cards = this.getCardsByName({ name: this.searchText });

      if (accounts.length) {
        results.push(...accounts.map(account => ({
          name: account.accountName,
          type: 'account',
          handleRouteChange: () => {
            this.searchText = '';

            this.$router.push({
              name: routeNames.SINGLE_ACCOUNT,
              params: {
                accountId: account.accountId,
              },
            });
          },
        })));
      }

      if (cards.length) {
        results.push(...cards.map(card => ({
          name: card.cardOwnerName,
          type: 'card',
          handleRouteChange: () => {
            this.searchText = '';

            this.$router.push({
              name: routeNames.SINGLE_CARD,
              params: {
                cardId: card.cardId,
              },
            });
          },
        })));
      }

      return results;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .nav-header-container {
        background-color: var(--white);
        width: 100vw;
        height: $nav-header-height;
        display: flex;
    }

    .nav-header-logo-wrapper {
        a {
            cursor: pointer;
            width: 50%;
            height: 60%;
        }
    }

    .nav-header-logo-wrapper,
    .nav-header-user-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-header-logo-wrapper {
        background: url("~@/assets/images/logo.png") center center/contain no-repeat;
        background-size: 60%;
        width: $nav-sidebar-width + $nav-border-width;
        border-right: $nav-border-width solid var(--background-grey);
    }

    .nav-header-search-wrapper {
        margin: auto;
        text-align: center;

        .nav-header-search-form-wrapper {
            width: calc(100vw - 406px);
            margin-bottom: 4px;
        }

        input {
            width: 50%;
            height: 32px;
            border: none;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            background-color: var(--background-grey);
            padding: 15px;

            &:focus, &:active, &:focus-visible {
                outline-color: var(--tago-blue);
            }
        }

        .nav-header-search-button {
            color: var(--white);
            border-radius: 0;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            width: 50px;
            padding-right: 15px;
        }

        .nav-header-search-results-wrapper {
            width: 55%;
            position: relative;
            height: 0;
            margin: 0 auto;

            .nav-header-search-results {
                width: 100%;
                border-radius: 10px;
                background-color: var(--background-grey);
                border: 1px solid var(--light-grey);
                padding: 1px 4px;
            }

            button {
                background-color: var(--white);
                width: 100%;
                border: none;
                display: flex;
                border: 1px solid var(--light-grey);
                border-radius: 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 5px 0;
                margin: 2px 0;

                &:focus, &:active, &:focus-visible {
                    outline-color: var(--tago-blue);
                }

                &:hover {
                    background-color: var(--tago-light-blue);
                }
            }

            h3,
            h5 {
                margin: 0;
            }

            h3 {
                font-size: 14px;
                font-weight: 500;
            }

            h5 {
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    .nav-header-user-wrapper {
        width: $nav-sidebar-width + $nav-border-width;

        svg {
            color: var(--tago-blue);
            border: 1px solid var(--tago-blue);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 0 5px;
        }

        .nav-header-user-message {
            padding-left: 10px;

            span {
                display: block;
                font-size: 14px;
                line-height: 1.2;

                &:first-of-type {
                    font-weight: 700;
                }
            }
        }
    }
</style>

import { roles } from '@/config/security';

class AuthenticationDriver {
    dataMappings = Object.freeze({
      username: {
        clientKey: 'username',
        serverKey: 'username',
      },
      email: {
        clientKey: 'email',
        serverKey: 'email',
      },
      id: {
        clientKey: 'id',
        serverKey: 'id',
      },
      role: {
        clientKey: 'role',
        serverKey: 'role',
        formatServerPropertyFunction: roleValue => {
          switch (roleValue) {
            case 'BASIC':
              return roles.BASIC;
            case 'ADMIN':
              return roles.ADMIN;
            case 'SUPER_ADMIN':
              return roles.SUPER_ADMIN;
            default:
              return roles.BASIC;
          }
        },
      },
    })

    formatServerData (responseData) {
      return Object.keys(this.dataMappings).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.clientKey] = objMap.formatServerPropertyFunction
          ? objMap.formatServerPropertyFunction(responseData[objMap.serverKey])
          : responseData[objMap.serverKey];

        return acc;
      }, {});
    }
}

export default new AuthenticationDriver();

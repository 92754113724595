import { routeNames } from '@/config/routes';

export const cardConfig = Object.freeze({
  accounts: {
    title: 'Accounts',
    icon: 'user-circle',
    routeName: routeNames.ALL_ACCOUNTS,
  },
  cards: {
    title: 'Cards',
    icon: 'address-card',
    routeName: routeNames.ALL_CARDS,
  },
});

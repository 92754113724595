<template>
    <div class="delete-card-container">
      <CRow class="mb-3">
        <CFormLabel
          class="col-form-label">
          Delete Account:
        </CFormLabel>

        <div class="col-sm-8 delete-card-confirmation-text">
          Are you sure you want to delete this Card Account?
        </div>

        <CButton
          class="col-sm-3 delete-button"
          color="danger"
          size="sm"
          @click="handleDelete">
          Delete
      </CButton>
      </CRow>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { routeNames } from '@/config/routes';

export default {
  name: 'CardDelete',
  props: {
    card: Object,
  },
  methods: {
    ...mapActions(['deleteCard']),
    ...mapMutations(['addAlert']),
    handleDelete () {
      if (window.confirm('Are you sure you want to delete this card?')) {
        this.deleteCard({
          cardId: this.card.cardId,
        })
          .then(() => {
            this.addAlert({
              alert: {
                type: 'info',
                message: 'Card Deleted Successfully',
              },
            });

            this.$router.push({
              name: routeNames.CARDS,
              params: {
                accountId: this.card.accountId,
              },
            });
          })
          .catch(() => {
            this.addAlert({
              alert: {
                type: 'danger',
                message: 'Card Delete Failed',
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';

  .delete-card-container {
    max-width: 700px;
    background-color: var(--white);
    padding: 30px 80px 10px;
  }

  .col-form-label {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .delete-card-confirmation-text {
    border: 1px solid lightgrey;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    background-color: var(--background-grey);
    border-radius: 10px;
    margin-right: 10px;
  }
</style>

<template>
    <div class="section-navigation-container">
        <div
            v-for="(navigationItem, index) in filteredNavigationList"
            :key="index"
            class="navigation-item">
            <router-link
                :to="{ name: navigationItem.routeName }"
                :class="isMatchingRoute(navigationItem.routeName) ? 'active' : ''">
                {{ navigationItem.label }}
            </router-link>
        </div>
    </div>
</template>

<script>
import userService from '@/services/UserService';

export default {
  name: 'SectionNavigation',
  props: {
    navigationList: Array,
  },
  computed: {
    filteredNavigationList () {
      return this.navigationList.filter(navigationItem => navigationItem.forceAllowRoute || userService.hasRouteAccess(navigationItem.routeName));
    },
  },
  methods: {
    isMatchingRoute (routeName) {
      return this.$route.matched.some(route => route.name === routeName);
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .section-navigation-container {
        display: flex;
        align-items: center;
    }

    .navigation-item {
        padding: 0 10px;
        padding-bottom: 5px;
        margin-bottom: 5px;
        line-height: 0.5;
        border-right: $standard-border-width solid var(--light-grey);

        a {
            color: var(--light-grey);
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;

            &.active {
                color: var(--tago-blue);
                text-decoration: underline;
            }
        }
    }
</style>

class AdminAccountDriver {
    dataMappings = Object.freeze({
      accountCreatedAt: {
        clientKey: 'accountCreatedAt',
        serverKey: 'createdAt',
        defaultValue: null,
      },
      accountId: {
        clientKey: 'accountId',
        serverKey: 'id',
        defaultValue: '',
        formatServerPropertyFunction: id => String(id),
      },
      accountName: {
        clientKey: 'accountName',
        serverKey: 'username',
        defaultValue: '',
      },
      loginEmail: {
        clientKey: 'loginEmail',
        serverKey: 'loginEmail',
        defaultValue: '',
      },
      loginPassword: {
        clientKey: 'loginPassword',
        serverKey: 'loginPassword',
        defaultValue: '',
      },
      isAccountEnabled: {
        clientKey: 'isAccountEnabled',
        serverKey: 'isEnabled',
        defaultValue: false,
      },
    })

    formatServerData (serverData) {
      return Object.keys(this.dataMappings).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.clientKey] = objMap.formatServerPropertyFunction
          ? objMap.formatServerPropertyFunction(serverData[objMap.serverKey])
          : serverData[objMap.serverKey];

        return acc;
      }, {});
    }

    formatClientData (clientData) {
      return Object.keys(clientData).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.serverKey] = objMap.formatClientPropertyFunction
          ? objMap.formatClientPropertyFunction(clientData[objMap.clientKey])
          : clientData[objMap.clientKey];

        return acc;
      }, {});
    }

    createClientFormData (account, configList) {
      return configList.reduce((acc, curVal) => {
        const config = typeof curVal === 'string' ? { clientKey: curVal } : curVal;

        const { clientKey, formatFunction } = config;

        const value = account[clientKey] || this.dataMappings[clientKey].defaultValue;

        acc[clientKey] = formatFunction ? formatFunction(value) : value;

        return acc;
      }, {});
    }
}

export default new AdminAccountDriver();

export const state = {
  accounts: [],
  adminAccounts: [],
  cards: [],
  statistics: {
    accounts: [],
    cards: [],
  },
  isFetchingAccounts: false,
  isFetchingAdminAccounts: false,
  isFetchingCards: false,
  isFetchingStatistics: false,
  alerts: [],
};

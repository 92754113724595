<template>
    <div
      :style="cardData.themes && `font-family: ${cardData.themes.font};`"
      class="preview-container">
        <div
          class="preview-header-background"
          :style="cardData.themes && `background-color: ${cardData.themes.headerBackgroundColor};`">
          <img
            :class="cardData.themes ? cardData.themes.headerBackgroundTexture : 'header-texture-1'"
            :src="cardData.themes ? getImageUrl(cardData.themes.headerBackgroundTexture): getImageUrl('header-texture-1')" />
        </div>
        <div class="preview-header">
            <img :src="cardData.cardProfilePicture || getIconUrl('card-photo-placeholder')" />

            <div>
              <h1
                v-if="cardData.fullName"
                :style="cardData.themes && `font-family: ${cardData.themes.font}; color: ${cardData.themes.fontColor}`"
                :class="(cardData.fullName.length > 22) && 'small-name'">{{ cardData.fullName }}</h1>

              <p :style="cardData.themes && `font-family: ${cardData.themes.font}; color: ${cardData.themes.fontColor}`">
                <span>{{ cardData.title }},&nbsp;</span>
                <span>{{ cardData.company }}</span>
              </p>
            </div>
        </div>

        <div class="preview-body">
            <div class="about">
              <h5
                :style="cardData.themes && `font-family: ${cardData.themes.font}; color: ${cardData.themes.fontColor}`">
                About
              </h5>

              <p
                :style="cardData.themes && `font-family: ${cardData.themes.font}; color: ${cardData.themes.fontColor}`"
                v-html="cardData.about || '-'" />
            </div>

            <div class="contacts">
              <h5 :style="cardData.themes && `font-family: ${cardData.themes.font}; color: ${cardData.themes.fontColor}`">Contacts</h5>

              <div class="contacts-item-wrapper">
                <div
                  v-for="(previewProperty, index) in getCardTypeConfig(cardData)"
                  :key="index"
                  class="contacts-item"
                  @click="handleLinkClick(previewProperty.value, previewProperty.actionType)">
                    <icons
                      :name="previewProperty.icon"
                      :shape="cardData.themes ? cardData.themes.iconShape : 'standard'"
                      :fill="cardData.themes ? cardData.themes.iconColor : '#2c384af2'" />

                    <label
                      :style="cardData.themes && `font-family: ${cardData.themes.font}; color: ${cardData.themes.iconColor}`"
                      v-text="previewProperty.label"/>
                </div>
              </div>
            </div>
        </div>

        <div class="preview-actions">
            <button
              :style="cardData.themes && `background-color: ${cardData.themes.buttonColor}; font-family: ${cardData.themes.font};`"
              class="share"
              @click="() => isSendMyDetailsModalVisible = true ">
              <img :src="getIconUrl('share')">Share</button>

            <a
              :href="vcfLink"
              target="_blank">
              <button
                :style="cardData.themes && `background-color: ${cardData.themes.buttonColor}; font-family: ${cardData.themes.font};`"
                class="save">
                  <img :src="getIconUrl('save')">
                  Save
              </button>
            </a>
        </div>

        <ModalSendMyDetails
          :isVisible="isSendMyDetailsModalVisible"
          :cardId="cardData.cardId"
          @modal-hide="() => isSendMyDetailsModalVisible = false"/>
    </div>
</template>

<script>
import { endpoints } from '@/config/endpoints';
import WebFont from 'webfontloader';
import cardTypeDriver from '@/drivers/CardTypeDriver';
import ModalSendMyDetails from '@/components/preview/ModalSendMyDetails';
import Icons from '@/components/admin/shared/Icons';

export default {
  name: 'Preview',
  components: {
    ModalSendMyDetails,
    Icons,
  },
  props: {
    cardData: Object,
  },
  data () {
    return {
      cardTypeDriver,
      isSendMyDetailsModalVisible: false,
    };
  },
  computed: {
    vcfLink () {
      const baseUrl = process?.env?.VUE_APP_SERVER_URL ?? '';

      return `${baseUrl}/api/cards/${this.cardData.link}/vcf`;
    },
  },
  mounted () {
    if (this.cardData?.themes?.font) {
      WebFont.load({
        google: {
          families: [this.cardData.themes.font],
        },
      });
    }
  },
  methods: {
    getIconUrl (iconName) {
      const images = require.context('@/assets/icons', false, /\.png$/);
      return images('./' + iconName + '.png');
    },
    getImageUrl (imageName = 'header-texture-1') {
      if (imageName.includes('custom')) {
        return `${endpoints.storage}/${this.cardData.themeLocationId}/${imageName}.png`;
      }

      const images = require.context('@/assets/images', false, /\.png$/);
      return images('./' + imageName + '.png');
    },
    getCardTypeConfig (cardType) {
      return [
        [cardTypeDriver.dataMappings.officePhoneList.clientKey],
        [cardTypeDriver.dataMappings.mobilePhoneList.clientKey],
        [cardTypeDriver.dataMappings.fax.clientKey],
        [cardTypeDriver.dataMappings.emailList.clientKey],
        [cardTypeDriver.dataMappings.addressList.clientKey],
        [cardTypeDriver.dataMappings.websiteList.clientKey],
        [cardTypeDriver.dataMappings.facebook.clientKey],
        [cardTypeDriver.dataMappings.instagram.clientKey],
        [cardTypeDriver.dataMappings.twitter.clientKey],
        [cardTypeDriver.dataMappings.linkedin.clientKey],
        [cardTypeDriver.dataMappings.youtube.clientKey],
        [cardTypeDriver.dataMappings.pinterest.clientKey],
        [cardTypeDriver.dataMappings.tiktok.clientKey],
      ].filter(clientProperty => {
        if (!cardType[clientProperty]) {
          return false;
        }

        return typeof cardType[clientProperty] === 'string' ? cardType[clientProperty] : cardType[clientProperty][0];
      }).map(clientProperty => {
        const value = cardType[clientProperty];

        return {
          icon: cardTypeDriver.dataMappings[clientProperty].icon,
          isLink: cardTypeDriver.dataMappings[clientProperty].isLink,
          clientKey: cardTypeDriver.dataMappings[clientProperty].clientKey,
          value: typeof value === 'string' ? value : value[0],
          label: cardTypeDriver.dataMappings[clientProperty].label,
          actionType: cardTypeDriver.dataMappings[clientProperty].actionType,
        };
      });
    },
    handleLinkClick (value, actionType) {
      switch (actionType) {
        case 'phone':
          window.open(`tel:${value}`);
          break;

        case 'email':
          window.open(`mailto:${value}`);
          break;

        case 'maps':
          break;
        default:
          if (!value.includes('http')) {
            window.open('https://' + value, '_blank');
          } else {
            window.open(value, '_blank');
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    $preview-padding: 20px;
    $preview-max-width: 500px;

    .preview-header-background {
      background-color: #00A4EF;
      height: 100px;
      text-align: right;
    }

    .preview-header-background img {
      mix-blend-mode: luminosity;
      opacity: .4;
      width: 100%;
      max-width: 500px;
      overflow: hidden;
      height: 100%;
    }

    .header-texture-1 {
      width: 60% !important;
    }

    .header-texture-3,
    .header-texture-10 {
      opacity: .6 !important;
    }

    .preview-header,
    .preview-body,
    .preview-actions {
      padding: $preview-padding;
      max-width: $preview-max-width;
      text-align: left;
      margin: 0 auto;
    }

    .preview-container {
      h1, h5 {
        font-family: 'AzoSansBold';
        font-weight: bold;
      }
    }

    .preview-header {
      margin-top: -100px;

      div {
        text-align: left;
        margin-top: auto;
      }

      img {
        position: relative;
        width: 120px;
        height: 120px;
        border-radius: 10px;
        margin-right: $preview-padding;
      }

      h1 {
        margin-top: 10px;
        font-size: 28px;
        margin-bottom: 0;
      }

      p {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .preview-body {
      text-align: left;
      margin: 0 auto;

      h5 {
        font-size: 14px;
      }

      p {
        font-size: 14px;
      }

      .contacts {
        margin: 40px 0 20px;
      }
      .contacts img {
        display: block;
        object-fit: contain;
        width: 70%;
        max-width: 40px;
      }

      .contacts-item-wrapper {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-row-gap: 20px;
        grid-column-gap: 5px;
        justify-content: space-between;
        padding: 0 10px;
        margin: 20px 0;
      }

      .contacts-item {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        width: 60px;

        label {
          font-size: 14px;
          padding-top: 5px;
          flex-basis: 100%;
          text-align: center;
        }
      }
    }

    .preview-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .share {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .save {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      a {
        color: inherit;
      }

      button {
        font-family: 'AzoSansBold';
        font-weight: bold;
        width: 100%;
        background-color: var(--tago-light-blue);
        padding: 10px;
        border: none;
        border-radius: 10px;
        font-size: 18px;
        color: white;
      }

      button > img {
        width: 20px;
        margin-right: 10px;
        margin-top: -3px;
      }
    }

    .preview-actions > * {
      flex-basis: 49.8%;
    }

    h1.small-name {
      font-size: 24px !important;
    }
</style>

<template>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'address-card'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M67.5,34c-0.2,4.1-1.4,8.3-3.7,12.1c-1.9,3.2-4,6.2-6.1,9.3c-4,6-8.1,12-12.1,18c-0.1,0.2-0.2,0.3-0.3,0.5
            c-1.6,2.3-4.2,2.3-5.7,0c-1.8-2.6-3.5-5.2-5.3-7.9c-3.8-5.7-7.7-11.4-11.5-17.1c-2.3-3.5-4.1-7.2-4.9-11.4c-1-5.2-0.1-10.2,2.4-14.9
            c3.9-7.2,10-11.4,18-12.8c12.2-2.1,24.3,5.1,28,16.9C67.1,29,67.4,31.4,67.5,34z M42.5,43.9c5.7,0,10.3-4.5,10.3-10
            s-4.6-10.1-10.3-10c-5.7,0-10.3,4.5-10.3,10.1C32.2,39.5,36.8,44,42.5,43.9z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'envelope'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <path d="M50.5,45.4c0.2,0.1,0.4,0.2,0.6,0.4c8,5.4,16,10.9,24,16.3c0.6,0.4,0.7,0.6,0.2,1.2c-0.4,0.6-1,1-1.8,1c-0.1,0-0.2,0-0.3,0
                c-20.4,0-40.9,0-61.3,0c-0.9,0-1.6-0.3-2.2-1.1c-0.3-0.4-0.3-0.7,0.2-1.1c6.2-4.2,12.3-8.3,18.4-12.5c2-1.4,4.1-2.8,6.2-4.2
                c0.9,0.8,1.8,1.5,2.7,2.3c2.9,2.4,7,2.5,10,0.3c0.7-0.5,1.3-1.1,2-1.6C49.7,46.1,50.1,45.7,50.5,45.4z"/>
            <path d="M72.5,20.8c-3.1,2.6-6.1,5.2-9,7.7c-6,5.1-11.9,10.2-17.9,15.2c-2.1,1.8-4,1.8-6.1,0c-8.8-7.5-17.6-15-26.4-22.4
                c-0.2-0.1-0.3-0.3-0.5-0.5C32.6,20.8,52.5,20.8,72.5,20.8z"/>
            <path d="M75.6,24c0,11.1,0,22,0,33.1c-7.2-4.9-14.4-9.8-21.6-14.7C61.2,36.3,68.4,30.2,75.6,24z"/>
            <path d="M9.4,57.1c0-11,0-22,0-33c7.2,6.1,14.4,12.2,21.6,18.4C23.8,47.3,16.7,52.1,9.4,57.1z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'facebook'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M48.6,60.8c0,4.3,0,8.7,0,13c0,1.3-0.4,1.8-1.8,1.8c-3.4,0-6.8,0-10.2,0c-1.5,0-2-0.4-2-1.9c0-8.7,0-17.4,0-26.1
            c0-0.6-0.1-0.8-0.8-0.8c-2.5,0-5,0-7.5,0c-1.3,0-1.8-0.5-1.8-1.8c0-3.4,0-6.7,0-10.1c0-1.3,0.5-1.8,1.8-1.8c2.5,0,5,0,7.5,0
            c0.5,0,0.6-0.1,0.6-0.6c0-2.7-0.1-5.4,0.1-8.1C34.7,20,36.2,16,39.6,13c2.3-2.1,5.1-3.2,8.2-3.5c3.7-0.4,7.4-0.1,11.1-0.1
            c0.9,0,1.4,0.6,1.4,1.7c0,1.9,0,3.7,0,5.6c0,1.3,0,2.5,0,3.8s-0.5,1.8-1.8,1.8c-2.1,0-4.2,0-6.4,0.1c-0.9,0-1.8,0.2-2.5,0.8
            c-1,0.8-1.2,1.9-1.2,3c-0.1,2.2,0,4.5,0,6.7c0,0.5,0.3,0.4,0.6,0.4c3,0,5.9,0,8.9,0c1.6,0,2,0.4,2,2c0,3.2,0,6.4,0,9.7
            c0,1.5-0.4,1.9-1.9,1.9c-3,0-5.9,0-8.9,0c-0.6,0-0.7,0.2-0.7,0.7C48.6,51.9,48.6,56.4,48.6,60.8z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'globe'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M42.5,9.4C24.3,9.4,9.4,24.3,9.4,42.5s14.8,33.1,33.1,33.1s33.1-14.8,33.1-33.1S60.8,9.4,42.5,9.4z M72,45.3
            c-0.4,4.4-1.6,8.4-3.8,12.2c-0.2,0.3-0.3,0.3-0.6,0.2c-2.4-0.8-4.9-1.3-7.4-1.8c-0.3-0.1-0.5-0.1-0.4-0.5c0.8-3.4,1.2-6.8,1.3-10.3
            c0-0.3,0.1-0.4,0.4-0.4c3.4,0,6.8,0,10.2,0C72.3,44.7,71.9,45.1,72,45.3z M44.8,58.5c3.2,0.1,6.3,0.3,9.5,0.6c0.1,0,0.2,0,0.3,0.1
            c-1.5,4-3.3,7.8-6.6,10.7c-1,0.9-2.2,1.6-3.6,2v-3.3c0-3.2,0-6.4,0-9.6C44.4,58.7,44.3,58.4,44.8,58.5z M44.4,13.4
            c1.8,0.5,3.3,1.5,4.5,2.8c2.5,2.5,4,5.6,5.3,8.8c0.1,0.4,0.3,0.7,0.4,1.2c-2,0.2-3.9,0.3-5.9,0.4c-1.3,0.1-2.6,0.1-3.9,0.1
            c-0.2,0-0.5,0.1-0.5-0.3C44.4,22.2,44.4,17.8,44.4,13.4z M62.4,25c-1.1,0.2-2.2,0.4-3.3,0.6c-0.5,0.1-0.5-0.2-0.6-0.5
            c-0.9-2.7-2.1-5.3-3.6-7.7c-0.5-0.8-1-1.5-1.6-2.2c3.8,1.2,9.1,4.9,12.3,9C64.5,24.5,63.5,24.8,62.4,25z M67.6,27.8
            c0.3-0.1,0.4-0.1,0.6,0.2c2.2,3.8,3.4,7.8,3.8,12.2c0,0.5-0.1,0.6-0.5,0.6c-1.6,0-3.3,0-4.9,0s-3.2,0-4.8,0c-0.5,0-0.7-0.1-0.7-0.6
            c-0.1-3.4-0.5-6.7-1.3-10c-0.1-0.4-0.1-0.6,0.4-0.7C62.7,29,65.2,28.5,67.6,27.8z M44.4,31.4c0-0.3,0-0.6,0.4-0.6
            c3.5-0.1,6.9-0.3,10.4-0.6c0.4,0,0.6,0,0.7,0.5c0.7,3.2,1.1,6.4,1.3,9.7c0,0.5-0.1,0.5-0.5,0.5c-1.9,0-3.9,0-5.8,0
            c-1.9,0-3.8,0-5.8,0c-0.5,0-0.6-0.1-0.6-0.6C44.4,37.3,44.4,34.3,44.4,31.4z M44.9,44.7c2,0,3.9,0,5.9,0c1.9,0,3.9,0,5.8,0
            c0.4,0,0.5,0,0.5,0.5c-0.2,3.3-0.6,6.5-1.3,9.7c-0.1,0.3-0.2,0.4-0.5,0.3c-3.5-0.4-7-0.7-10.5-0.7c-0.4,0-0.4-0.1-0.4-0.5
            c0-3,0-5.9,0-8.9C44.4,44.7,44.6,44.7,44.9,44.7z M58.4,60.4c0.2-0.6,0.4-0.7,1-0.6c2.1,0.4,4.1,0.8,6.2,1.4
            c-2.6,3.7-9.8,8.8-12.5,9C55.5,67.3,57.2,63.9,58.4,60.4z M40.5,26.2c0,0.5-0.1,0.7-0.6,0.6c-3.2-0.2-6.4-0.3-9.5-0.7
            c1.6-5.2,5.5-11.6,10.1-12.7v1C40.5,18.4,40.5,22.3,40.5,26.2z M40.5,40.3c0,0.4-0.1,0.5-0.5,0.5c-2,0-3.9,0-5.9,0
            c-1.9,0-3.9,0-5.8,0c-0.5,0-0.6-0.1-0.5-0.6c0.2-3.3,0.6-6.6,1.3-9.8c0.1-0.4,0.2-0.4,0.6-0.4c3.5,0.5,7,0.6,10.4,0.7
            c0.3,0,0.4,0.1,0.4,0.4C40.5,34.3,40.5,37.3,40.5,40.3z M28.5,20.3c-0.8,1.6-1.4,3.2-2,4.8c-0.1,0.3-0.1,0.5-0.5,0.4
            c-2.2-0.4-4.3-0.8-6.5-1.5c3.3-4,7.3-7,12.1-8.8C30.4,16.9,29.3,18.5,28.5,20.3z M24.8,29.3c0.4,0.1,0.5,0.2,0.4,0.5
            c-0.8,3.5-1.2,7-1.3,10.5c0,0.4-0.2,0.4-0.5,0.4c-1.7,0-3.4,0-5,0s-3.2,0-4.8,0c-0.4,0-0.5-0.1-0.5-0.5c0.4-4.4,1.7-8.6,3.9-12.4
            c0.2-0.3,0.3-0.3,0.6-0.2C19.9,28.4,22.3,28.9,24.8,29.3z M13.6,44.7c1.6,0,3.2,0,4.9,0c1.6,0,3.3,0,4.9,0c0.4,0,0.5,0.1,0.5,0.5
            c0.1,3.4,0.5,6.7,1.3,10c0.1,0.4,0,0.6-0.5,0.7c-2.5,0.4-4.9,0.9-7.3,1.6c-0.3,0.1-0.5,0.2-0.8-0.2c-2.1-3.7-3.3-7.7-3.7-12
            C12.9,44.8,13.1,44.7,13.6,44.7z M24.3,59.9c0.5-0.1,1-0.2,1.5-0.3c0.4-0.1,0.5,0.1,0.6,0.4c1.2,3.6,2.8,7,5.2,10c0,0,0,0.1,0,0.2
            c-3.3-0.6-10.3-5.8-12.5-9.2C20.9,60.6,22.6,60.2,24.3,59.9z M30.7,60.2c-0.1-0.3-0.2-0.7-0.4-1v-0.1c3.2-0.3,6.5-0.5,9.7-0.6
            c0.3,0,0.4,0.1,0.4,0.4c0,4.3,0,8.5,0,12.8c0,0.1,0,0.1,0,0.2c-1.8-0.5-3.2-1.5-4.5-2.8C33.5,66.5,31.9,63.4,30.7,60.2z M40,54.5
            c-3.5,0-6.9,0.2-10.4,0.7c-0.3,0-0.4,0-0.5-0.4c-0.7-3.2-1.1-6.4-1.3-9.6c0-0.5,0.1-0.5,0.5-0.5c1.9,0,3.9,0,5.8,0s3.9,0,5.8,0
            c0.4,0,0.5,0.1,0.5,0.5c0,2.9,0,5.8,0,8.7C40.5,54.4,40.4,54.5,40,54.5z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'instagram'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <path d="M42.5,15.3c9,0,10,0,13.3,0s4.9,0.5,6.4,1s2.5,1.5,3.9,2.5c1,1,2,2.5,2.5,3.9c0,1,1,3,1,6.4
                c0,3.5,0,4.4,0,13.3s0,10,0,13.3s-0.5,4.9-1,6.4s-1.5,2.5-2.5,3.9c-1,1-2.5,2-3.9,2.5c-1,0-3,1-6.4,1c-3.5,0-4.4,0-13.3,0
                s-10,0-13.3,0s-4.9-0.5-6.4-1S20.3,67,18.9,66c-1-1-2-2.5-2.5-3.9c0-1-1-3-1-6.4c0-3.5,0-4.4,0-13.3s0-10,0-13.3s0.5-4.9,1-6.4
                s1.5-2.5,2.5-3.9c1-1,2.5-2,3.9-2.5c1,0,3-1,6.4-1S33.6,15.3,42.5,15.3 M42.5,9.4c-9,0-10.5,0-13.8,0c-3.5,0-5.9,0.5-7.9,1.5
                s-3.9,2-5.9,3.9c-2,2-3,3.9-3.9,5.9c-1,2-1.5,4.4-1.5,7.9s0,4.9,0,13.8s0,10.5,0,13.8s0.5,5.9,1.5,7.9s2,3.9,3.9,5.9
                c2,2,3.9,3,5.9,3.9c2,1,4.4,1.5,7.9,1.5s4.9,0,13.8,0s10.5,0,13.8,0c3.5,0,5.9-0.5,7.9-1.5c2-1,3.9-2,5.9-3.9c2-2,3-3.9,3.9-5.9
                c1-2,1.5-4.4,1.5-7.9s0-4.9,0-13.8s0-10.5,0-13.8S75,22.7,74,20.7s-2-3.9-3.9-5.9c-2-2-3.9-3-5.9-3.9c-2-1-4.4-1.5-7.9-1.5
                C53,9.4,51.5,9.4,42.5,9.4L42.5,9.4z"/>
            <path d="M42.5,25.8c-9.5,0-17.4,7.9-17.4,17.4S33,60.6,42.5,60.6s17.4-7.9,17.4-17.4S52,25.8,42.5,25.8z M42.5,54.5
                c-5.9,0-11.3-4.9-11.3-11.3s4.9-11.3,11.3-11.3s11.3,4.9,11.3,11.3S48.9,54.5,42.5,54.5z"/>
            <circle cx="60.4" cy="25.3" r="3.9"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'linkedin'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <path d="M32.8,75.6c0-14.4,0-28.9,0-43.3c0-0.9,0-0.9,0.9-0.9c3.9,0,7.8,0,11.6,0c0.5,0,0.6,0.2,0.6,0.6c0,1.8,0,3.6,0,5.3
                c0.3,0.1,0.3-0.1,0.4-0.2c2.3-3.8,5.8-5.9,10.1-6.6c3.6-0.6,7.1-0.3,10.5,1.1c3.9,1.6,6.1,4.7,7.3,8.6c0.9,3.1,1.2,6.2,1.3,9.4
                c0,8.7,0,17.3,0,26c-4.6,0-9.1,0-13.7,0c-0.3-0.3-0.2-0.7-0.2-1.1c0-7.4,0-14.8,0-22.2c0-1.9-0.2-3.8-0.8-5.7
                c-0.9-2.6-2.9-3.8-5.5-4c-1.1-0.1-2.2,0.1-3.3,0.4c-2.5,0.7-3.8,2.4-4.5,4.8c-0.5,1.7-0.7,3.5-0.7,5.3c0,7.2,0,14.3,0,21.5
                c0,0.4,0.1,0.8-0.2,1.1C42,75.6,37.4,75.6,32.8,75.6z"/>
            <path d="M10.5,75.6c0-0.3,0-0.6,0-0.8c0-14.2,0-28.4,0-42.6c0-0.7,0.2-0.8,0.8-0.8c4.1,0,8.1,0,12.2,0c0.6,0,0.7,0.1,0.7,0.7
                c0,14.2,0,28.5,0,42.7c0,0.3,0,0.5,0,0.8C19.7,75.6,15.1,75.6,10.5,75.6z"/>
            <path d="M9.4,16.2c0.2-0.2,0.2-0.4,0.2-0.6c1-3.9,4.7-6.5,8.5-6c4.2,0.5,7.2,3.9,7.1,8.1c0,3.9-3.1,7.4-6.9,7.8
                c-4.1,0.5-7.8-2-8.8-6c-0.1-0.3,0-0.6-0.2-0.8C9.4,17.8,9.4,17,9.4,16.2z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'mobile-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <path d="M55.7,9.4c-8.8,0-17.6,0-26.4,0c-4,0-7,3-7,7c0,8.7,0,17.4,0,26.1s0,17.4,0,26.2c0,4,3,7,6.9,7c8.8,0,17.6,0,26.4,0
                c4,0,6.9-3,6.9-7c0-17.4,0-34.8,0-52.3C62.7,12.4,59.7,9.4,55.7,9.4z M55.4,72c-8.6,0-17.2,0-25.8,0c-2.3,0-3.5-1.2-3.5-3.5
                c0-8.7,0-17.3,0-26c0-8.6,0-17.2,0-25.8c0-2.4,1.6-3.6,3.6-3.6c8.6,0,17.1,0,25.7,0c2,0,3.6,1.3,3.6,3.6c0,17.2,0,34.5,0,51.7
                C59,70.7,57.3,72,55.4,72z"/>
            <path d="M42.5,63.2c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.6,3.5s-3.5-1.6-3.5-3.5C39,64.7,40.6,63.2,42.5,63.2z"/>
            <path d="M45.3,18.6c-1.1,0-2.1,0-3.2,0c-1.2,0-2-0.8-2-1.8c0-1.1,0.8-1.8,2-1.8c2.2,0,4.3,0,6.5,0c1.1,0,2,0.8,2,1.8
                s-0.8,1.8-2,1.8C47.4,18.6,46.4,18.6,45.3,18.6z"/>
            <path d="M36.4,18.6c-1,0-1.9-0.8-1.9-1.8s0.9-1.8,1.9-1.8s1.8,0.8,1.9,1.8C38.3,17.7,37.5,18.6,36.4,18.6z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'phone-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M9.4,24.9c-0.1-3.4,1.1-6.3,3.6-8.7c1.5-1.5,3-3,4.5-4.5c3.1-3.1,6.9-3.1,10-0.1c2.9,2.8,5.7,5.6,8.5,8.4
            c2.7,2.7,2.8,6.2,0.3,9c-1.6,1.8-3.3,3.4-5,5c-0.4,0.3-0.4,0.6-0.2,1c1.5,3,3.5,5.6,5.7,8.1c3.1,3.5,6.5,6.6,10.5,9.1
            c0.9,0.6,1.9,1,2.8,1.6c0.3,0.2,0.5,0,0.6-0.2c1.6-1.6,3.1-3.1,4.7-4.7c1.1-1.1,2.3-1.8,3.8-2c2.2-0.3,4.1,0.5,5.7,2
            c1.8,1.7,3.5,3.5,5.3,5.2c1,1,2.1,2.1,3.1,3.1c3,3,3,6.8,0.1,9.9c-1.7,1.8-3.4,3.4-5.1,5.2c-2.7,2.8-6,3.5-9.7,3.1
            c-5.3-0.5-10-2.5-14.6-5c-13.4-7.2-23.5-17.6-30.4-31.1c-1.9-3.5-3.3-7.3-4-11.3C9.5,27,9.4,26,9.4,24.9z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'pinterest'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M29.2,72.8c-2.2-1-4.2-2.1-6.1-3.5C15.6,63.7,11,56.2,9.7,46.9C7.3,29.6,19,13.4,36.1,10.1C54,6.6,71.1,18,74.9,35.8
            c3.5,16.3-6,32.8-21.9,38.1c-6.4,2.1-12.8,2.3-19.2,0.5c-0.5-0.1-0.6-0.3-0.3-0.7c2.1-3.2,3.2-6.8,4-10.5c0.4-1.6,0.8-3.3,1.2-4.9
            c0.1,0,0.1,0,0.1,0c2.3,3.1,5.5,4.1,9.1,3.9c5.7-0.4,9.9-3.2,12.8-8c3.2-5,4.3-10.6,3.8-16.4c-0.5-6-3.7-10.6-8.8-13.9
            c-4.5-2.9-9.4-3.7-14.6-3.2c-4.7,0.4-9.1,1.8-12.9,4.8c-4.5,3.5-7.3,8.1-8,13.8c-0.4,3.5,0,7,1.7,10.1c1.1,2,2.6,3.5,4.6,4.4
            c1.1,0.5,1.7,0.2,2-1c0.2-0.8,0.4-1.7,0.6-2.5c0.1-0.5,0.1-1-0.3-1.5c-2.6-3-2.8-6.4-2.1-10C28.4,31.7,33.8,27,41,26.3
            c2.6-0.3,5.2-0.1,7.7,0.9c4.8,1.8,7.4,5.3,7.8,10.3c0.4,4.7-0.4,9.3-2.7,13.6c-1,1.9-2.3,3.5-4.3,4.5c-1.5,0.8-3.1,1.1-4.8,0.7
            c-2.9-0.7-4.5-3.4-3.8-6.3c0.7-2.7,1.6-5.4,2.3-8.1c0.4-1.5,0.7-2.9,0.5-4.5c-0.5-4-4.2-4.8-6.7-3.5c-1.9,1-3,2.7-3.6,4.7
            c-0.8,2.7-0.6,5.3,0.4,7.9c0.2,0.4,0.2,0.8,0.1,1.3c-1.4,6-2.9,12.1-4.3,18.1c-0.5,2-0.5,4-0.5,6C29.2,72.2,29.2,72.4,29.2,72.8z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'print'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M72.5,23.3c-2.3,0-4.7,0-7,0c-0.6,0-0.7-0.1-0.7-0.7c0-2,0-4.1,0-6.1s-1-3-3-3c-6.4,0-12.8,0-19.3,0c-6.4,0-12.8,0-19.2,0
            c-2,0-3,1-3,3c0,2.1,0,4.1,0,6.2c0,0.5-0.1,0.7-0.6,0.7c-2.4,0-4.8,0-7.2,0c-1.9,0-3,1.1-3,3c0,8.5,0,16.9,0,25.4c0,2,1,3.1,3,3.1
            c2.3,0,4.7,0,7,0c0.6,0,0.7,0.2,0.7,0.7c0,4.3,0,8.7,0,13c0,2.3,1,3.2,3.2,3.2c10.1,0,20.1,0,30.2,0c2.8,0,5.5,0,8.3,0
            c1,0,1.8-0.4,2.4-1.3c0.4-0.6,0.4-1.2,0.4-1.9c0-4.4,0-8.7,0-13.1c0-0.5,0.1-0.6,0.6-0.6c2.4,0,4.8,0,7.2,0c2,0,3-1.1,3-3
            c0-8.4,0-16.9,0-25.3C75.5,24.3,74.5,23.3,72.5,23.3z M16.2,32.6c-1.3,0-2.4-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3s2.4,1.1,2.4,2.3
            C18.6,31.6,17.5,32.6,16.2,32.6z M61.4,69c-6.3,0-12.6,0-18.9,0c-6.2,0-12.5,0-18.7,0c-0.7,0-0.8-0.2-0.8-0.8c0-6.5,0-13,0-19.6
            c0-0.5,0.1-0.7,0.7-0.7c12.6,0,25.2,0,37.8,0c0.6,0,0.7,0.2,0.7,0.7c0,6.6,0,13.1,0,19.7C62.1,68.8,61.9,69,61.4,69z M61.3,23.3
            c-6.3,0-12.5,0-18.8,0s-12.5,0-18.8,0c-0.6,0-0.8-0.2-0.8-0.8c0.1-1.2,0-2.4,0-3.6c0-1.6,1-2.7,2.7-2.8c0.2,0,0.3,0,0.5,0
            c10.9,0,21.8,0,32.8,0c0.7,0,1.4,0.1,2,0.5c0.8,0.6,1.1,1.4,1.1,2.3c0,1.2,0,2.4,0,3.6C62.1,23.1,62,23.3,61.3,23.3z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'tiktok'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M44.1,9.4c3.6,0,7.2,0,10.8,0c0.2,4.7,1.8,8.7,5.2,11.9c3.1,2.9,6.7,4.4,10.9,4.6c0.5,0,0.7,0.1,0.7,0.7c0,3.2,0,6.3,0,9.5
            c0,0.5-0.1,0.7-0.6,0.6c-5.8-0.1-11-1.9-15.7-5.3c-0.1-0.1-0.2-0.2-0.3-0.2c0,0-0.1,0-0.2,0c0,0.2,0,0.5,0,0.7c0,7.7,0,15.3,0.1,23
            c0,3.7-0.7,7.2-2.6,10.3c-4.5,7.2-11,10.8-19.5,10.4C24,75.2,16.4,69,14.1,60.5c-2.9-10.7,3.4-21.9,14.1-25c2.7-0.8,5.5-1,8.3-0.8
            c0.6,0.1,0.9,0.2,0.9,0.9c0,3.2,0,6.4,0,9.5c0,0.5-0.1,0.7-0.6,0.5C30.2,44,24,49,24.4,55.7c0.2,4.9,4.2,8.9,9.2,9.2
            c4.9,0.3,9.4-3.2,10.2-7.9c0.2-0.8,0.2-1.6,0.2-2.5c0-14.8,0-29.6,0-44.4C44,9.9,44,9.6,44.1,9.4z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'twitter'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <path d="M68.5,68.8c-6.9-10-13.7-20-20.6-30c-0.2-0.3-0.3-0.5,0-0.9c3.4-3.9,6.7-7.8,10-11.6c3.1-3.6,6.1-7.1,9.2-10.7
                c-1.5,0-3.1,0-4.6,0c-0.3,0.1-0.5,0.3-0.7,0.6c-1.4,1.6-2.8,3.3-4.2,4.9c-1.9,2.1-3.7,4.3-5.5,6.4c-2.1,2.4-4.2,4.9-6.3,7.3
                c-0.3,0.3-0.4,0.3-0.6,0c-1.9-2.9-3.9-5.7-5.9-8.6c-2.2-3.2-4.5-6.5-6.7-9.7c-0.2-0.3-0.4-0.7-0.7-0.9c-5.2,0-10.4,0-15.6,0
                c0,0.2,0.2,0.3,0.3,0.5c6.6,9.7,13.3,19.3,19.9,29c0.2,0.4,0.2,0.5-0.1,0.8c-5.9,6.9-11.8,13.7-17.7,20.6c-0.8,0.9-1.6,1.9-2.5,2.9
                c1.5,0,2.9,0,4.2,0c0.3,0,0.5-0.1,0.7-0.3c4.2-4.8,8.3-9.7,12.5-14.5c1.7-2,3.4-4,5.2-6c0.1,0.2,0.2,0.3,0.3,0.4
                c4.6,6.7,9.2,13.4,13.8,20c0.2,0.3,0.4,0.4,0.7,0.4c4.9,0,9.8,0,14.7,0c0.1,0,0.3,0.1,0.4-0.1C68.7,69.1,68.6,69,68.5,68.8z
                M55.6,66c-0.3,0-0.4-0.2-0.5-0.4c-2.9-4.2-5.8-8.3-8.7-12.5C38.5,42,30.7,30.8,22.9,19.7c-0.1-0.2-0.2-0.3-0.4-0.5
                c0.4,0,0.7,0,1.1,0c1.8,0,3.6,0,5.5,0c0.4,0,0.7,0.1,0.9,0.5C37.8,30.8,45.7,42,53.5,53.3c2.9,4.1,5.8,8.2,8.6,12.4
                c0.1,0.1,0.1,0.2,0.3,0.4C60.1,66,57.8,66,55.6,66z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'standard' && name === 'youtube'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M75.3,34.4c-0.2-2.5-0.5-4.9-1.1-7.3c-0.8-3.5-3.7-6-7.5-6.4c-3.9-0.4-7.8-0.7-11.7-0.9c-4.2-0.2-8.3-0.3-12.5-0.3
            c-4.8,0-9.7,0.1-14.5,0.3c-3.3,0.1-6.7,0.4-9.9,0.8c-4.1,0.6-6.6,3-7.3,6.8c-0.6,3.2-0.9,6.4-1.1,9.6c-0.3,4.6-0.3,9.2,0.1,13.8
            c0.2,2.5,0.5,4.9,1.1,7.3c0.8,3.5,3.7,6,7.5,6.4c3.9,0.4,7.8,0.7,11.7,0.9c4.2,0.2,8.3,0.3,12.5,0.3c4.8,0,9.7-0.1,14.5-0.3
            c3.3-0.1,6.7-0.4,9.9-0.8c4.1-0.6,6.6-3,7.3-6.8c0.6-3.2,0.9-6.4,1.1-9.6C75.8,43.6,75.7,39,75.3,34.4z M35.9,52.5
            c0-6.6,0-13.2,0-19.9c5.8,3.3,11.4,6.6,17.2,9.9C47.4,45.9,41.7,49.2,35.9,52.5z"/>
    </svg>

    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'address-card'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <path class="st0" d="M61.4,36.1c-0.2,3.1-1.1,6.3-2.8,9.1c-1.4,2.4-3,4.7-4.6,7c-3,4.5-6.1,9-9.1,13.5c-0.1,0.1-0.1,0.2-0.2,0.4
            c-1.2,1.7-3.2,1.7-4.3,0c-1.3-1.9-2.6-3.9-4-5.9c-2.9-4.3-5.8-8.5-8.7-12.8c-1.8-2.6-3.1-5.4-3.7-8.6c-0.8-3.9-0.1-7.7,1.8-11.2
            c2.9-5.4,7.5-8.6,13.5-9.7c9.2-1.6,18.3,3.9,21.1,12.7C61.1,32.3,61.4,34.1,61.4,36.1z M42.6,43.6c4.3,0,7.7-3.4,7.7-7.6
            s-3.5-7.6-7.7-7.6c-4.3,0-7.7,3.4-7.7,7.6S38.3,43.6,42.6,43.6z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'envelope'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <g>
            <path class="st0" d="M48.5,44.7c0.2,0.1,0.3,0.2,0.4,0.3c6,4.1,12,8.2,18.1,12.3c0.5,0.3,0.5,0.4,0.2,0.9s-0.8,0.7-1.4,0.7
                c-0.1,0-0.2,0-0.2,0c-15.4,0-30.7,0-46.1,0c-0.7,0-1.2-0.2-1.6-0.8c-0.2-0.3-0.2-0.6,0.2-0.8c4.6-3.1,9.2-6.3,13.9-9.4
                c1.5-1,3.1-2.1,4.6-3.2c0.7,0.6,1.4,1.2,2,1.7c2.2,1.8,5.2,1.9,7.5,0.3c0.5-0.4,1-0.8,1.5-1.2C47.9,45.2,48.2,44.9,48.5,44.7z"/>
            <path class="st0" d="M65.1,26.2c-2.3,2-4.6,3.9-6.8,5.8c-4.5,3.8-9,7.6-13.5,11.5c-1.6,1.4-3,1.4-4.6,0c-6.6-5.7-13.2-11.3-19.8-17
                c-0.1-0.1-0.2-0.2-0.4-0.4C35,26.2,50,26.2,65.1,26.2z"/>
            <path class="st0" d="M67.4,28.6c0,8.3,0,16.5,0,24.9c-5.4-3.7-10.8-7.4-16.3-11C56.6,37.8,62,33.2,67.4,28.6z"/>
            <path class="st0" d="M17.6,53.5c0-8.3,0-16.5,0-24.8c5.4,4.6,10.8,9.2,16.2,13.8C28.4,46.1,23.1,49.8,17.6,53.5z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'facebook'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <path class="st0" d="M47,55.8c0,3.1,0,6.3,0,9.4c0,1-0.3,1.3-1.3,1.3c-2.5,0-4.9,0-7.4,0c-1.1,0-1.4-0.3-1.4-1.4
            c0-6.3,0-12.6,0-18.9c0-0.4-0.1-0.6-0.6-0.6c-1.8,0-3.6,0-5.4,0c-0.9,0-1.3-0.4-1.3-1.3c0-2.4,0-4.9,0-7.3c0-0.9,0.4-1.3,1.3-1.3
            c1.8,0,3.6,0,5.4,0c0.4,0,0.5-0.1,0.5-0.5c0-2-0.1-3.9,0-5.9c0.2-3.2,1.2-6.1,3.7-8.2c1.7-1.5,3.7-2.3,5.9-2.5
            c2.7-0.3,5.4-0.1,8.1-0.1c0.6,0,1,0.5,1,1.2c0,1.3,0,2.7,0,4c0,0.9,0,1.8,0,2.7c0,0.9-0.4,1.3-1.3,1.3c-1.5,0-3.1,0-4.6,0
            c-0.6,0-1.3,0.2-1.8,0.6c-0.7,0.6-0.9,1.4-0.9,2.2c0,1.6,0,3.2,0,4.8c0,0.4,0.2,0.3,0.4,0.3c2.1,0,4.3,0,6.4,0
            c1.1,0,1.4,0.3,1.4,1.5c0,2.3,0,4.7,0,7c0,1.1-0.3,1.4-1.4,1.4c-2.1,0-4.3,0-6.4,0c-0.4,0-0.5,0.1-0.5,0.5C47,49.3,47,52.5,47,55.8z
            "/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'globe'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17.1c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17H68c9.4,0,17,7.7,17,17v45.2C84.9,74.7,74.7,84.9,62.2,84.9z"/>
        <path class="st0" d="M42.5,17.6c-13.7,0-24.9,11.2-24.9,24.9s11.2,24.9,24.9,24.9s24.9-11.2,24.9-24.9S56.2,17.6,42.5,17.6z
            M64.7,44.6c-0.3,3.3-1.2,6.3-2.9,9.2c-0.1,0.2-0.2,0.3-0.5,0.2c-1.8-0.6-3.7-1-5.6-1.3c-0.2,0-0.4-0.1-0.3-0.4
            c0.6-2.5,0.9-5.1,1-7.7c0-0.2,0.1-0.3,0.3-0.3c2.5,0,5.1,0,7.6,0C64.9,44.2,64.6,44.5,64.7,44.6z M44.2,54.5
            c2.4,0.1,4.8,0.2,7.1,0.5c0.1,0,0.2,0,0.3,0.1c-1.1,3-2.5,5.9-5,8c-0.8,0.7-1.7,1.2-2.7,1.5v-2.5c0-2.4,0-4.8,0-7.2
            C44,54.7,43.9,54.5,44.2,54.5z M44,20.6c1.4,0.4,2.4,1.2,3.4,2.1c1.9,1.9,3,4.2,4,6.7c0.1,0.3,0.2,0.5,0.3,0.9
            c-1.5,0.1-2.9,0.2-4.4,0.3c-1,0.1-2,0.1-3,0.1c-0.2,0-0.4,0.1-0.4-0.2C44,27.2,44,23.9,44,20.6z M57.5,29.4
            c-0.8,0.2-1.7,0.3-2.5,0.5c-0.3,0.1-0.4-0.1-0.5-0.3c-0.7-2-1.6-4-2.7-5.8c-0.4-0.6-0.8-1.1-1.2-1.7c2.9,0.9,6.9,3.7,9.2,6.8
            C59.1,29,58.3,29.2,57.5,29.4z M61.4,31.5c0.2-0.1,0.3-0.1,0.5,0.2c1.6,2.8,2.6,5.9,2.9,9.2c0,0.3,0,0.4-0.4,0.4c-1.2,0-2.5,0-3.7,0
            s-2.4,0-3.6,0c-0.4,0-0.5-0.1-0.5-0.5c-0.1-2.5-0.4-5.1-0.9-7.6c-0.1-0.3-0.1-0.4,0.3-0.5C57.7,32.4,59.6,32,61.4,31.5z M44,34.1
            c0-0.2,0-0.4,0.3-0.4c2.6,0,5.2-0.2,7.8-0.5c0.3,0,0.4,0,0.5,0.3c0.5,2.4,0.8,4.8,0.9,7.3c0,0.3-0.1,0.4-0.4,0.4c-1.5,0-2.9,0-4.4,0
            c-1.4,0-2.9,0-4.3,0c-0.3,0-0.5,0-0.5-0.4C44,38.6,44,36.3,44,34.1z M44.3,44.2c1.5,0,3,0,4.4,0c1.5,0,2.9,0,4.4,0
            c0.3,0,0.4,0,0.4,0.4c-0.1,2.5-0.4,4.9-1,7.3c-0.1,0.2-0.1,0.3-0.4,0.3c-2.6-0.3-5.3-0.5-7.9-0.5c-0.3,0-0.3-0.1-0.3-0.3
            c0-2.2,0-4.5,0-6.7C43.9,44.2,44.1,44.2,44.3,44.2z M54.4,55.9c0.1-0.4,0.3-0.5,0.8-0.4c1.6,0.3,3.1,0.6,4.7,1.1
            c-2,2.8-7.3,6.6-9.4,6.8C52.3,61.2,53.5,58.6,54.4,55.9z M41,30.3c0,0.4-0.1,0.5-0.5,0.5c-2.4-0.1-4.8-0.2-7.2-0.5
            c1.2-3.9,4.1-8.7,7.6-9.6v0.8C41,24.4,41,27.3,41,30.3z M41,40.9c0,0.3-0.1,0.3-0.4,0.3c-1.5,0-3,0-4.4,0c-1.5,0-2.9,0-4.4,0
            c-0.4,0-0.4-0.1-0.4-0.4c0.1-2.5,0.4-4.9,1-7.4c0.1-0.3,0.2-0.3,0.4-0.3c2.6,0.3,5.2,0.5,7.9,0.6c0.3,0,0.3,0.1,0.3,0.3
            C41,36.3,41,38.6,41,40.9z M31.9,25.8c-0.6,1.2-1.1,2.4-1.5,3.6c-0.1,0.2-0.1,0.4-0.4,0.3c-1.6-0.3-3.2-0.6-4.9-1.1
            c2.5-3,5.5-5.2,9.1-6.6C33.4,23.2,32.6,24.5,31.9,25.8z M29.2,32.6c0.3,0.1,0.3,0.2,0.3,0.4c-0.6,2.6-0.9,5.2-1,7.9
            c0,0.3-0.1,0.3-0.4,0.3c-1.3,0-2.5,0-3.8,0c-1.2,0-2.4,0-3.6,0c-0.3,0-0.4-0.1-0.4-0.4c0.3-3.3,1.3-6.4,2.9-9.3
            c0.1-0.2,0.2-0.2,0.5-0.2C25.5,31.9,27.3,32.3,29.2,32.6z M20.7,44.2c1.2,0,2.4,0,3.6,0s2.5,0,3.7,0c0.3,0,0.4,0.1,0.4,0.4
            c0.1,2.5,0.4,5,1,7.5c0.1,0.3,0,0.5-0.4,0.5c-1.8,0.3-3.7,0.7-5.5,1.2c-0.2,0.1-0.4,0.1-0.6-0.1c-1.6-2.8-2.5-5.8-2.8-9
            C20.2,44.2,20.4,44.2,20.7,44.2z M28.8,55.6c0.4-0.1,0.8-0.1,1.2-0.2c0.3-0.1,0.4,0,0.5,0.3c0.9,2.7,2.1,5.3,3.9,7.6v0.1
            c-2.5-0.5-7.7-4.4-9.4-6.9C26.3,56.1,27.5,55.8,28.8,55.6z M33.6,55.8c-0.1-0.2-0.2-0.5-0.3-0.7V55c2.4-0.3,4.9-0.4,7.3-0.5
            c0.3,0,0.3,0.1,0.3,0.3c0,3.2,0,6.4,0,9.6c0,0,0,0.1,0,0.2c-1.3-0.4-2.4-1.2-3.4-2.1C35.7,60.5,34.6,58.2,33.6,55.8z M40.6,51.5
            c-2.6,0-5.2,0.2-7.8,0.5c-0.2,0-0.3,0-0.4-0.3c-0.5-2.4-0.8-4.8-1-7.2c0-0.4,0.1-0.4,0.4-0.4c1.5,0,2.9,0,4.4,0s2.9,0,4.4,0
            c0.3,0,0.4,0.1,0.4,0.4c0,2.2,0,4.4,0,6.6C41,51.4,40.9,51.5,40.6,51.5z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'instagram'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <g>
            <path class="st0" d="M42.5,22.2c6.7,0,7.5,0,10,0s3.7,0.4,4.8,0.8s1.9,1.1,2.9,1.9c0.8,0.8,1.5,1.9,1.9,2.9c0,0.8,0.8,2.3,0.8,4.8
                c0,2.7,0,3.3,0,10s0,7.5,0,10s-0.4,3.7-0.8,4.8c-0.4,1.1-1.1,1.9-1.9,2.9c-0.8,0.8-1.9,1.5-2.9,1.9c-0.8,0-2.3,0.8-4.8,0.8
                c-2.7,0-3.3,0-10,0s-7.5,0-10,0s-3.7-0.4-4.8-0.8c-1.1-0.4-1.9-1.1-2.9-1.9c-0.8-0.8-1.5-1.9-1.9-2.9c0-0.8-0.8-2.3-0.8-4.8
                c0-2.7,0-3.3,0-10s0-7.5,0-10s0.4-3.7,0.8-4.8c0.4-1.1,1.1-1.9,1.9-2.9c0.8-0.8,1.9-1.5,2.9-1.9c0.8,0,2.3-0.8,4.8-0.8
                C35,22.2,35.8,22.2,42.5,22.2 M42.5,17.7c-6.7,0-7.9,0-10.4,0c-2.7,0-4.4,0.4-6,1.1c-1.5,0.8-2.9,1.5-4.4,2.9
                c-1.5,1.5-2.3,2.9-2.9,4.4c-0.8,1.5-1.1,3.3-1.1,6s0,3.7,0,10.4s0,7.9,0,10.4s0.4,4.4,1.1,6c0.8,1.5,1.5,2.9,2.9,4.4
                c1.5,1.5,2.9,2.3,4.4,2.9c1.5,0.8,3.3,1.1,6,1.1s3.7,0,10.4,0s7.9,0,10.4,0c2.7,0,4.4-0.4,6-1.1c1.5-0.8,2.9-1.5,4.4-2.9
                c1.5-1.5,2.3-2.9,2.9-4.4c0.8-1.5,1.1-3.3,1.1-6s0-3.7,0-10.4s0-7.9,0-10.4s-0.4-4.4-1.1-6c-0.8-1.5-1.5-2.9-2.9-4.4
                c-1.5-1.5-2.9-2.3-4.4-2.9c-1.5-0.8-3.3-1.1-6-1.1C50.4,17.7,49.3,17.7,42.5,17.7L42.5,17.7z"/>
            <path class="st0" d="M42.5,30c-7.1,0-13.1,6-13.1,13.1s6,13.1,13.1,13.1s13.1-6,13.1-13.1S49.6,30,42.5,30z M42.5,51.6
                c-4.4,0-8.5-3.7-8.5-8.5s3.7-8.5,8.5-8.5s8.5,3.7,8.5,8.5S47.3,51.6,42.5,51.6z"/>
            <circle class="st0" cx="56" cy="29.7" r="2.9"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'linkedin'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <g>
            <path class="st0" d="M36.2,64.4c0-9.6,0-19.2,0-28.8c0-0.6,0-0.6,0.6-0.6c2.6,0,5.2,0,7.7,0c0.3,0,0.4,0.1,0.4,0.4
                c0,1.2,0,2.4,0,3.5c0.2,0.2,0.2,0,0.3,0c1.5-2.5,3.9-3.9,6.7-4.4c2.4-0.4,4.7-0.2,7,0.8c2.6,1.1,4.1,3.1,4.9,5.7
                c0.6,2,0.8,4.1,0.8,6.2c0,5.8,0,11.5,0,17.3c-3,0-6.1,0-9.1,0c-0.2-0.2-0.1-0.5-0.1-0.7c0-4.9,0-9.8,0-14.8c0-1.3-0.1-2.5-0.6-3.8
                c-0.6-1.7-1.9-2.5-3.7-2.7c-0.7-0.1-1.5,0-2.2,0.2c-1.6,0.5-2.5,1.6-3,3.2c-0.3,1.1-0.5,2.3-0.5,3.5c0,4.7,0,9.5,0,14.2
                c0,0.3,0.1,0.5-0.1,0.7C42.2,64.4,39.2,64.4,36.2,64.4z"/>
            <path class="st0" d="M21.3,64.4c0-0.2,0-0.4,0-0.6c0-9.4,0-18.8,0-28.3c0-0.4,0.1-0.5,0.5-0.5c2.7,0,5.4,0,8.1,0
                c0.4,0,0.5,0.1,0.5,0.5c0,9.5,0,18.9,0,28.4c0,0.2,0,0.3,0,0.5C27.5,64.4,24.4,64.4,21.3,64.4z"/>
            <path class="st0" d="M20.6,24.9c0.2-0.1,0.1-0.3,0.1-0.4c0.6-2.6,3.1-4.3,5.7-4c2.8,0.3,4.8,2.6,4.7,5.4c0,2.6-2.1,4.9-4.6,5.2
                c-2.7,0.3-5.2-1.4-5.8-4c0-0.2,0-0.4-0.2-0.5C20.6,26,20.6,25.5,20.6,24.9z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'mobile-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <g>
            <path class="st0" d="M52.3,17.6c-6.6,0-13.2,0-19.8,0c-3,0-5.2,2.2-5.2,5.3c0,6.5,0,13.1,0,19.6c0,6.6,0,13.1,0,19.7
                c0,3,2.2,5.2,5.2,5.2c6.6,0,13.3,0,19.9,0c3,0,5.2-2.3,5.2-5.2c0-13.1,0-26.2,0-39.3C57.7,19.9,55.3,17.6,52.3,17.6z M52.2,64.7
                c-6.5,0-12.9,0-19.4,0c-1.7,0-2.6-0.9-2.6-2.6c0-6.5,0-13,0-19.5s0-12.9,0-19.4c0-1.8,1.2-2.7,2.7-2.7c6.4,0,12.9,0,19.3,0
                c1.5,0,2.7,0.9,2.7,2.7c0,13,0,25.9,0,38.9C54.8,63.7,53.7,64.7,52.2,64.7z"/>
            <path class="st0" d="M42.4,58c1.5,0,2.6,1.2,2.6,2.6c0,1.5-1.2,2.6-2.7,2.6s-2.7-1.2-2.6-2.7C39.8,59.2,41,58,42.4,58z"/>
            <path class="st0" d="M44.5,24.5c-0.8,0-1.6,0-2.4,0c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.4,1.5-1.4c1.6,0,3.2,0,4.9,0
                c0.9,0,1.5,0.6,1.5,1.4s-0.6,1.4-1.5,1.4C46.2,24.5,45.3,24.5,44.5,24.5z"/>
            <path class="st0" d="M37.8,24.5c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4C39.3,23.9,38.7,24.5,37.8,24.5z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'phone-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <path class="st0" d="M18.3,29.6c-0.1-2.5,0.8-4.6,2.7-6.4c1.1-1.1,2.2-2.2,3.3-3.3c2.3-2.2,5.1-2.3,7.3-0.1c2.1,2,4.2,4.1,6.2,6.2
            c1.9,2,2,4.5,0.2,6.6c-1.1,1.3-2.4,2.5-3.7,3.7C34,36.5,34,36.7,34.2,37c1.1,2.2,2.5,4.1,4.2,5.9c2.3,2.6,4.8,4.8,7.7,6.7
            c0.7,0.4,1.4,0.7,2.1,1.1c0.2,0.1,0.3,0,0.5-0.1c1.1-1.1,2.3-2.3,3.4-3.4c0.8-0.8,1.7-1.3,2.8-1.5c1.6-0.3,3,0.3,4.1,1.5
            c1.3,1.3,2.6,2.5,3.8,3.8c0.8,0.8,1.5,1.5,2.3,2.3c2.2,2.2,2.2,5,0.1,7.3c-1.2,1.3-2.5,2.5-3.7,3.8c-1.9,2-4.4,2.5-7.1,2.3
            c-3.9-0.4-7.4-1.8-10.7-3.7c-9.8-5.3-17.2-12.9-22.2-22.8c-1.4-2.7-2.4-5.4-2.9-8.4C18.3,31,18.3,30.3,18.3,29.6z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'pinterest'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <path class="st0" d="M44.7,19c0.5,0.2,1,0.2,1.6,0.2c3.1,0.5,6.2,1.6,8.7,3.6c2,1.5,3.6,3.3,4.6,5.5c1.2,2.2,1.7,4.6,1.7,7.2
            c0,3.4-0.7,6.5-2.3,9.5c-2.2,3.9-5.5,6.7-9.9,7.8c-2.4,0.6-4.9,0.6-7.2-0.5c-1.2-0.6-2.2-1.3-2.9-2.4c-0.3,1-0.6,2.1-0.8,3.1
            c-0.6,2.1-1,4.3-2,6.3c-1,2.2-2.3,4.3-3.7,6.4c-0.1,0.1-0.2,0.3-0.3,0.2c-0.1,0.1-0.1-0.2-0.1-0.4c-0.5-2.9-0.7-5.9-0.5-8.8
            c0.1-1.5,0.7-3,1-4.6c0.8-3.5,1.7-7.1,2.7-10.6c0.1-0.2,0-0.5,0-0.7c-0.9-2.6-1.2-5.1,0.1-7.7c0.8-1.6,2-2.7,3.7-3
            c2.4-0.5,4.4,1,4.5,3.5c0.1,1.3-0.2,2.6-0.6,3.8c-0.6,2-1.2,3.8-1.7,5.8c-0.9,3.4,1.7,5.7,4.6,5.5c3.3-0.2,5.5-2,7.1-4.8
            c1.3-2.2,1.9-4.6,2-7.2c0.1-1.9-0.1-3.6-0.7-5.3c-1-2.8-3-4.9-5.7-6c-4.3-1.9-8.6-1.7-12.8,0.3c-3.7,1.9-5.9,5-6.6,9.2
            c-0.5,2.9,0.2,5.7,2.1,8c0.2,0.3,0.3,0.7,0.2,1c-0.2,0.9-0.5,2-0.7,2.9c-0.2,0.7-0.5,0.8-1.2,0.6c-1.5-0.7-2.7-1.9-3.5-3.1
            c-2.1-3.1-2.9-6.7-2.2-10.5c1.3-7.2,5.7-11.5,12.5-13.7c1.5-0.5,3-0.7,4.5-0.8c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.3,0
            c0.1,0.1,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.5,0,0.9,0,1.4,0c0.1,0.1,0.1,0,0.2,0H44h0.1h0.1
            c0.1,0,0.1,0,0.2,0C44.5,19,44.6,19,44.7,19z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'print'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <path class="st0" d="M65.1,27.9c-1.8,0-3.5,0-5.3,0c-0.4,0-0.6-0.1-0.6-0.5c0-1.5,0-3.1,0-4.6s-0.8-2.3-2.3-2.3c-4.8,0-9.7,0-14.5,0
            s-9.6,0-14.4,0c-1.5,0-2.3,0.8-2.3,2.3c0,1.6,0,3.1,0,4.7c0,0.4-0.1,0.5-0.5,0.5c-1.8,0-3.6,0-5.4,0c-1.4,0-2.2,0.8-2.2,2.3
            c0,6.4,0,12.7,0,19.1c0,1.5,0.8,2.3,2.3,2.3c1.8,0,3.5,0,5.3,0c0.4,0,0.5,0.1,0.5,0.6c0,3.3,0,6.5,0,9.8c0,1.7,0.7,2.4,2.4,2.4
            c7.6,0,15.2,0,22.7,0c2.1,0,4.2,0,6.2,0c0.8,0,1.4-0.3,1.8-1c0.3-0.4,0.3-0.9,0.3-1.4c0-3.3,0-6.6,0-9.9c0-0.4,0.1-0.5,0.5-0.5
            c1.8,0,3.6,0,5.4,0c1.5,0,2.3-0.8,2.3-2.3c0-6.3,0-12.7,0-19C67.5,28.7,66.7,27.9,65.1,27.9z M22.8,35c-1,0-1.8-0.8-1.8-1.8
            s0.8-1.7,1.8-1.7s1.8,0.8,1.8,1.8C24.6,34.2,23.8,35,22.8,35z M56.8,62.3c-4.7,0-9.5,0-14.2,0s-9.4,0-14.1,0c-0.5,0-0.6-0.1-0.6-0.6
            c0-4.9,0-9.8,0-14.7c0-0.4,0.1-0.5,0.5-0.5c9.5,0,18.9,0,28.4,0c0.4,0,0.5,0.1,0.5,0.5c0,4.9,0,9.9,0,14.8
            C57.3,62.2,57.2,62.3,56.8,62.3z M56.8,27.9c-4.7,0-9.4,0-14.1,0s-9.4,0-14.1,0c-0.5,0-0.6-0.1-0.6-0.6c0-0.9,0-1.8,0-2.7
            c0-1.2,0.8-2,2-2.1c0.1,0,0.3,0,0.4,0c8.2,0,16.4,0,24.6,0c0.5,0,1.1,0.1,1.5,0.4c0.6,0.4,0.8,1,0.8,1.7c0,0.9,0,1.8,0,2.7
            C57.4,27.8,57.2,28,56.8,27.9z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'tiktok'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17.1c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17H68c9.4,0,17,7.7,17,17v45.2C84.9,74.7,74.7,84.9,62.2,84.9z"/>
        <path class="st0" d="M43.6,18.3c2.6,0,5.3,0,7.9,0c0.1,3.4,1.4,6.3,3.8,8.7c2.2,2.1,4.9,3.3,8,3.4c0.4,0,0.5,0.1,0.5,0.5
            c0,2.3,0,4.6,0,6.9c0,0.4-0.1,0.5-0.5,0.5c-4.2-0.1-8.1-1.4-11.5-3.9c-0.1-0.1-0.2-0.1-0.2-0.2h-0.1c0,0.2,0,0.3,0,0.5
            c0,5.6,0,11.2,0.1,16.8c0,2.7-0.5,5.2-1.9,7.5c-3.2,5.4-8,8-14.2,7.7c-6.5-0.3-12-4.8-13.8-11.1c-2.1-7.8,2.5-16.1,10.3-18.3
            c2-0.6,4-0.8,6.1-0.6c0.4,0,0.6,0.1,0.6,0.7c0,2.3,0,4.7,0,7c0,0.4-0.1,0.5-0.5,0.4c-4.7-1.2-9.2,2.5-9,7.3c0.2,3.6,3.1,6.5,6.8,6.8
            c3.6,0.2,6.9-2.3,7.5-5.8c0.1-0.6,0.1-1.2,0.1-1.8c0-10.8,0-21.7,0-32.5C43.6,18.6,43.6,18.4,43.6,18.3z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'twitter'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17h50.9c9.4,0,17,7.7,17,17v45.2C84.8,74.7,74.6,84.9,62.2,84.9z"/>
        <g>
            <path class="st0" d="M63.6,63.6C58.1,55.5,52.5,47.5,47,39.4c-0.2-0.3-0.2-0.4,0-0.7c2.7-3.1,5.4-6.2,8.1-9.4
                c2.5-2.9,4.9-5.7,7.4-8.6c-1.2,0-2.5,0-3.7,0c-0.2,0.1-0.4,0.3-0.5,0.5c-1.1,1.3-2.2,2.6-3.4,3.9c-1.5,1.7-3,3.4-4.5,5.2
                c-1.7,2-3.4,3.9-5.1,5.9c-0.2,0.3-0.3,0.2-0.5,0c-1.6-2.3-3.2-4.6-4.7-6.9c-1.8-2.6-3.6-5.2-5.4-7.8c-0.2-0.3-0.3-0.5-0.6-0.7
                c-4.2,0-8.4,0-12.6,0c0,0.2,0.1,0.3,0.2,0.4c5.3,7.8,10.7,15.5,16,23.3c0.2,0.3,0.2,0.4-0.1,0.7c-4.8,5.5-9.5,11-14.3,16.6
                c-0.6,0.7-1.3,1.5-2,2.3c1.2,0,2.3,0,3.4,0c0.2,0,0.4-0.1,0.5-0.2c3.3-3.9,6.7-7.8,10-11.7c1.4-1.6,2.8-3.2,4.2-4.8
                c0.1,0.1,0.2,0.2,0.3,0.4c3.7,5.4,7.4,10.7,11.1,16.1c0.2,0.2,0.3,0.3,0.6,0.3c3.9,0,7.9,0,11.8,0c0.1,0,0.2,0.1,0.4-0.1
                C63.8,63.9,63.7,63.7,63.6,63.6z M53.2,61.4c-0.2,0-0.3-0.2-0.4-0.3c-2.3-3.4-4.7-6.7-7-10.1C39.5,42,33.2,33,27,24
                c-0.1-0.1-0.2-0.3-0.3-0.4c0.3,0,0.6,0,0.9,0c1.5,0,2.9,0,4.4,0c0.3,0,0.5,0.1,0.7,0.4c6.3,9,12.6,18.1,19,27.1
                c2.3,3.3,4.6,6.6,7,10c0.1,0.1,0.1,0.2,0.2,0.3C56.9,61.3,55.1,61.3,53.2,61.4z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'semi-rounded' && name === 'youtube'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <path d="M62.2,84.9H17.1c-9.4,0-17-7.7-17-17V17.1c0-9.4,7.7-17,17-17H68c9.4,0,17,7.7,17,17v45.2C84.9,74.7,74.7,84.9,62.2,84.9z"/>
        <path class="st0" d="M67.2,36.4c-0.2-1.8-0.4-3.7-0.8-5.5c-0.6-2.7-2.8-4.5-5.6-4.8c-2.9-0.3-5.8-0.6-8.8-0.7
            c-3.1-0.1-6.3-0.2-9.4-0.2c-3.6,0-7.3,0.1-10.9,0.2c-2.5,0.1-5,0.3-7.5,0.6c-3.1,0.5-5,2.3-5.5,5.1c-0.4,2.4-0.7,4.8-0.8,7.2
            c-0.3,3.4-0.2,6.9,0.1,10.3c0.2,1.8,0.4,3.7,0.8,5.5c0.6,2.7,2.8,4.5,5.6,4.8c2.9,0.3,5.8,0.6,8.8,0.7c3.1,0.1,6.3,0.2,9.4,0.2
            c3.6,0,7.3-0.1,10.9-0.2c2.5-0.1,5-0.3,7.5-0.6c3.1-0.5,5-2.3,5.5-5.1c0.4-2.4,0.7-4.8,0.8-7.2C67.5,43.3,67.5,39.8,67.2,36.4z
            M37.6,50c0-5,0-9.9,0-14.9c4.3,2.5,8.6,5,13,7.5C46.2,45.1,41.9,47.5,37.6,50z"/>
    </svg>

    <svg :fill="fill" v-if="shape === 'rounded' && name === 'address-card'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M61.3,36.1c-0.2,3.1-1.1,6.3-2.8,9.1c-1.4,2.4-3,4.7-4.6,7c-3,4.5-6.1,9-9.1,13.5c-0.1,0.1-0.1,0.2-0.2,0.4
            c-1.2,1.7-3.2,1.7-4.3,0c-1.3-1.9-2.6-3.9-4-5.9c-2.9-4.3-5.8-8.5-8.7-12.8c-1.8-2.6-3.1-5.4-3.7-8.6c-0.8-3.9-0.1-7.7,1.8-11.2
            c2.9-5.4,7.5-8.6,13.5-9.7c9.2-1.6,18.3,3.9,21.1,12.7C61,32.3,61.3,34.1,61.3,36.1z M42.5,43.6c4.3,0,7.7-3.4,7.7-7.6
            s-3.5-7.6-7.7-7.6c-4.3,0-7.7,3.4-7.7,7.6S38.2,43.6,42.5,43.6z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'envelope'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <g>
            <path class="st0" d="M48.4,44.6c0.2,0.1,0.3,0.2,0.4,0.3c5.9,4,11.7,8,17.7,12c0.5,0.3,0.5,0.4,0.2,0.9c-0.3,0.5-0.8,0.7-1.4,0.7
                c-0.1,0-0.2,0-0.2,0c-15,0-30,0-45,0c-0.7,0-1.2-0.2-1.6-0.8c-0.2-0.3-0.2-0.6,0.2-0.8c4.5-3,9-6.2,13.6-9.2c1.5-1,3-2.1,4.5-3.1
                c0.7,0.6,1.4,1.2,2,1.7c2.1,1.8,5.1,1.9,7.3,0.3c0.5-0.4,1-0.8,1.5-1.2C47.8,45.1,48.1,44.8,48.4,44.6z"/>
            <path class="st0" d="M64.6,26.6c-2.2,2-4.5,3.8-6.6,5.7c-4.4,3.7-8.8,7.4-13.2,11.2c-1.6,1.4-2.9,1.4-4.5,0
                c-6.4-5.6-12.9-11-19.3-16.6c-0.1-0.1-0.2-0.2-0.4-0.4C35.2,26.6,49.8,26.6,64.6,26.6z"/>
            <path class="st0" d="M66.8,28.9c0,8.1,0,16.1,0,24.3c-5.3-3.6-10.6-7.2-15.9-10.7C56.3,37.9,61.6,33.4,66.8,28.9z"/>
            <path class="st0" d="M18.2,53.2c0-8.1,0-16.1,0-24.2c5.3,4.5,10.6,9,15.8,13.5C28.7,46,23.5,49.6,18.2,53.2z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'facebook'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M46.9,55.8c0,3.1,0,6.3,0,9.4c0,1-0.3,1.3-1.3,1.3c-2.5,0-4.9,0-7.4,0c-1.1,0-1.4-0.3-1.4-1.4
            c0-6.3,0-12.6,0-18.9c0-0.4-0.1-0.6-0.6-0.6c-1.8,0-3.6,0-5.4,0c-0.9,0-1.3-0.4-1.3-1.3c0-2.4,0-4.9,0-7.3c0-0.9,0.4-1.3,1.3-1.3
            c1.8,0,3.6,0,5.4,0c0.4,0,0.5-0.1,0.5-0.5c0-2-0.1-3.9,0-5.9c0.2-3.2,1.2-6.1,3.7-8.2c1.7-1.5,3.7-2.3,5.9-2.5
            c2.7-0.3,5.4-0.1,8.1-0.1c0.6,0,1,0.5,1,1.2c0,1.3,0,2.7,0,4c0,0.9,0,1.8,0,2.7c0,0.9-0.4,1.3-1.3,1.3c-1.5,0-3.1,0-4.6,0
            c-0.6,0-1.3,0.2-1.8,0.6c-0.7,0.6-0.9,1.4-0.9,2.2c0,1.6,0,3.2,0,4.8c0,0.4,0.2,0.3,0.4,0.3c2.1,0,4.3,0,6.4,0
            c1.1,0,1.4,0.3,1.4,1.5c0,2.3,0,4.7,0,7c0,1.1-0.3,1.4-1.4,1.4c-2.1,0-4.3,0-6.4,0c-0.4,0-0.5,0.1-0.5,0.5
            C46.9,49.3,46.9,52.5,46.9,55.8z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'globe'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M42.5,17.6c-13.7,0-24.9,11.2-24.9,24.9s11.2,24.9,24.9,24.9s24.9-11.2,24.9-24.9S56.2,17.6,42.5,17.6z
            M64.7,44.6c-0.3,3.3-1.2,6.3-2.9,9.2c-0.1,0.2-0.2,0.3-0.5,0.2c-1.8-0.6-3.7-1-5.6-1.3c-0.2,0-0.4-0.1-0.3-0.4
            c0.6-2.5,0.9-5.1,1-7.7c0-0.2,0.1-0.3,0.3-0.3c2.5,0,5.1,0,7.6,0C64.9,44.2,64.6,44.5,64.7,44.6z M44.2,54.5
            c2.4,0.1,4.8,0.2,7.1,0.5c0.1,0,0.2,0,0.3,0.1c-1.1,3-2.5,5.9-5,8c-0.8,0.7-1.7,1.2-2.7,1.5v-2.5c0-2.4,0-4.8,0-7.2
            C44,54.7,43.9,54.5,44.2,54.5z M44,20.6c1.4,0.4,2.4,1.2,3.4,2.1c1.9,1.9,3,4.2,4,6.7c0.1,0.3,0.2,0.5,0.3,0.9
            c-1.5,0.1-2.9,0.2-4.4,0.3c-1,0.1-2,0.1-3,0.1c-0.2,0-0.4,0.1-0.4-0.2C44,27.2,44,23.9,44,20.6z M57.5,29.4
            c-0.8,0.2-1.7,0.3-2.5,0.5c-0.3,0.1-0.4-0.1-0.5-0.3c-0.7-2-1.6-4-2.7-5.8c-0.4-0.6-0.8-1.1-1.2-1.7c2.9,0.9,6.9,3.7,9.2,6.8
            C59.1,29,58.3,29.2,57.5,29.4z M61.4,31.5c0.2-0.1,0.3-0.1,0.5,0.2c1.6,2.8,2.6,5.9,2.9,9.2c0,0.3,0,0.4-0.4,0.4c-1.2,0-2.5,0-3.7,0
            s-2.4,0-3.6,0c-0.4,0-0.5-0.1-0.5-0.5c-0.1-2.5-0.4-5.1-0.9-7.6c-0.1-0.3-0.1-0.4,0.3-0.5C57.7,32.4,59.6,32,61.4,31.5z M44,34.1
            c0-0.2,0-0.4,0.3-0.4c2.6,0,5.2-0.2,7.8-0.5c0.3,0,0.4,0,0.5,0.3c0.5,2.4,0.8,4.8,0.9,7.3c0,0.3-0.1,0.4-0.4,0.4c-1.5,0-2.9,0-4.4,0
            c-1.4,0-2.9,0-4.3,0c-0.3,0-0.5,0-0.5-0.4C44,38.6,44,36.3,44,34.1z M44.3,44.2c1.5,0,3,0,4.4,0c1.5,0,2.9,0,4.4,0
            c0.3,0,0.4,0,0.4,0.4c-0.1,2.5-0.4,4.9-1,7.3c-0.1,0.2-0.1,0.3-0.4,0.3c-2.6-0.3-5.3-0.5-7.9-0.5c-0.3,0-0.3-0.1-0.3-0.3
            c0-2.2,0-4.5,0-6.7C43.9,44.2,44.1,44.2,44.3,44.2z M54.4,55.9c0.1-0.4,0.3-0.5,0.8-0.4c1.6,0.3,3.1,0.6,4.7,1.1
            c-2,2.8-7.3,6.6-9.4,6.8C52.3,61.2,53.5,58.6,54.4,55.9z M41,30.3c0,0.4-0.1,0.5-0.5,0.5c-2.4-0.1-4.8-0.2-7.2-0.5
            c1.2-3.9,4.1-8.7,7.6-9.6v0.8C41,24.4,41,27.3,41,30.3z M41,40.9c0,0.3-0.1,0.3-0.4,0.3c-1.5,0-3,0-4.4,0c-1.5,0-2.9,0-4.4,0
            c-0.4,0-0.4-0.1-0.4-0.4c0.1-2.5,0.4-4.9,1-7.4c0.1-0.3,0.2-0.3,0.4-0.3c2.6,0.3,5.2,0.5,7.9,0.6c0.3,0,0.3,0.1,0.3,0.3
            C41,36.3,41,38.6,41,40.9z M31.9,25.8c-0.6,1.2-1.1,2.4-1.5,3.6c-0.1,0.2-0.1,0.4-0.4,0.3c-1.6-0.3-3.2-0.6-4.9-1.1
            c2.5-3,5.5-5.2,9.1-6.6C33.4,23.2,32.6,24.5,31.9,25.8z M29.2,32.6c0.3,0.1,0.3,0.2,0.3,0.4c-0.6,2.6-0.9,5.2-1,7.9
            c0,0.3-0.1,0.3-0.4,0.3c-1.3,0-2.5,0-3.8,0c-1.2,0-2.4,0-3.6,0c-0.3,0-0.4-0.1-0.4-0.4c0.3-3.3,1.3-6.4,2.9-9.3
            c0.1-0.2,0.2-0.2,0.5-0.2C25.5,31.9,27.3,32.3,29.2,32.6z M20.7,44.2c1.2,0,2.4,0,3.6,0s2.5,0,3.7,0c0.3,0,0.4,0.1,0.4,0.4
            c0.1,2.5,0.4,5,1,7.5c0.1,0.3,0,0.5-0.4,0.5c-1.8,0.3-3.7,0.7-5.5,1.2c-0.2,0.1-0.4,0.1-0.6-0.1c-1.6-2.8-2.5-5.8-2.8-9
            C20.2,44.2,20.4,44.2,20.7,44.2z M28.8,55.6c0.4-0.1,0.8-0.1,1.2-0.2c0.3-0.1,0.4,0,0.5,0.3c0.9,2.7,2.1,5.3,3.9,7.6v0.1
            c-2.5-0.5-7.7-4.4-9.4-6.9C26.3,56.1,27.5,55.8,28.8,55.6z M33.6,55.8c-0.1-0.2-0.2-0.5-0.3-0.7V55c2.4-0.3,4.9-0.4,7.3-0.5
            c0.3,0,0.3,0.1,0.3,0.3c0,3.2,0,6.4,0,9.6c0,0,0,0.1,0,0.2c-1.3-0.4-2.4-1.2-3.4-2.1C35.7,60.5,34.6,58.2,33.6,55.8z M40.6,51.5
            c-2.6,0-5.2,0.2-7.8,0.5c-0.2,0-0.3,0-0.4-0.3c-0.5-2.4-0.8-4.8-1-7.2c0-0.4,0.1-0.4,0.4-0.4c1.5,0,2.9,0,4.4,0s2.9,0,4.4,0
            c0.3,0,0.4,0.1,0.4,0.4c0,2.2,0,4.4,0,6.6C41,51.4,40.9,51.5,40.6,51.5z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'instagram'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <g>
            <path class="st0" d="M42.5,22.1c6.7,0,7.5,0,10,0s3.7,0.4,4.8,0.8s1.9,1.1,2.9,1.9c0.8,0.8,1.5,1.9,1.9,2.9c0,0.8,0.8,2.3,0.8,4.8
                c0,2.7,0,3.3,0,10s0,7.5,0,10s-0.4,3.7-0.8,4.8c-0.4,1.1-1.1,1.9-1.9,2.9c-0.8,0.8-1.9,1.5-2.9,1.9c-0.8,0-2.3,0.8-4.8,0.8
                c-2.7,0-3.3,0-10,0s-7.5,0-10,0s-3.7-0.4-4.8-0.8c-1.1-0.4-1.9-1.1-2.9-1.9c-0.8-0.8-1.5-1.9-1.9-2.9c0-0.8-0.8-2.3-0.8-4.8
                c0-2.7,0-3.3,0-10s0-7.5,0-10s0.4-3.7,0.8-4.8c0.4-1.1,1.1-1.9,1.9-2.9c0.8-0.8,1.9-1.5,2.9-1.9c0.8,0,2.3-0.8,4.8-0.8
                C35,22.1,35.8,22.1,42.5,22.1 M42.5,17.6c-6.7,0-7.9,0-10.4,0c-2.7,0-4.4,0.4-6,1.1c-1.5,0.8-2.9,1.5-4.4,2.9
                c-1.5,1.5-2.3,2.9-2.9,4.4c-0.8,1.5-1.1,3.3-1.1,6s0,3.7,0,10.4s0,7.9,0,10.4s0.4,4.4,1.1,6c0.8,1.5,1.5,2.9,2.9,4.4
                c1.5,1.5,2.9,2.3,4.4,2.9c1.5,0.8,3.3,1.1,6,1.1s3.7,0,10.4,0s7.9,0,10.4,0c2.7,0,4.4-0.4,6-1.1c1.5-0.8,2.9-1.5,4.4-2.9
                c1.5-1.5,2.3-2.9,2.9-4.4c0.8-1.5,1.1-3.3,1.1-6s0-3.7,0-10.4s0-7.9,0-10.4s-0.4-4.4-1.1-6c-0.8-1.5-1.5-2.9-2.9-4.4
                c-1.5-1.5-2.9-2.3-4.4-2.9c-1.5-0.8-3.3-1.1-6-1.1C50.4,17.6,49.3,17.6,42.5,17.6L42.5,17.6z"/>
            <path class="st0" d="M42.5,29.9c-7.1,0-13.1,6-13.1,13.1s6,13.1,13.1,13.1s13.1-6,13.1-13.1S49.6,29.9,42.5,29.9z M42.5,51.5
                c-4.4,0-8.5-3.7-8.5-8.5s3.7-8.5,8.5-8.5S51,38.2,51,43S47.3,51.5,42.5,51.5z"/>
            <circle class="st0" cx="56" cy="29.6" r="2.9"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'linkedin'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <g>
            <path class="st0" d="M36.1,64.5c0-9.6,0-19.2,0-28.8c0-0.6,0-0.6,0.6-0.6c2.6,0,5.2,0,7.7,0c0.3,0,0.4,0.1,0.4,0.4
                c0,1.2,0,2.4,0,3.5c0.2,0.2,0.2,0,0.3,0c1.5-2.5,3.9-3.9,6.7-4.4c2.4-0.4,4.7-0.2,7,0.8c2.6,1.1,4.1,3.1,4.9,5.7
                c0.6,2,0.8,4.1,0.8,6.2c0,5.8,0,11.5,0,17.3c-3,0-6.1,0-9.1,0c-0.2-0.2-0.1-0.5-0.1-0.7c0-4.9,0-9.8,0-14.8c0-1.3-0.1-2.5-0.6-3.8
                c-0.6-1.7-1.9-2.5-3.7-2.7c-0.7-0.1-1.5,0-2.2,0.2c-1.6,0.5-2.5,1.6-3,3.2c-0.3,1.1-0.5,2.3-0.5,3.5c0,4.7,0,9.5,0,14.2
                c0,0.3,0.1,0.5-0.1,0.7C42.1,64.5,39.1,64.5,36.1,64.5z"/>
            <path class="st0" d="M21.2,64.5c0-0.2,0-0.4,0-0.6c0-9.4,0-18.8,0-28.3c0-0.4,0.1-0.5,0.5-0.5c2.7,0,5.4,0,8.1,0
                c0.4,0,0.5,0.1,0.5,0.5c0,9.5,0,18.9,0,28.4c0,0.2,0,0.3,0,0.5C27.4,64.5,24.3,64.5,21.2,64.5z"/>
            <path class="st0" d="M20.5,25c0.2-0.1,0.1-0.3,0.1-0.4c0.6-2.6,3.1-4.3,5.7-4c2.8,0.3,4.8,2.6,4.7,5.4c0,2.6-2.1,4.9-4.6,5.2
                c-2.7,0.3-5.2-1.4-5.8-4c0-0.2,0-0.4-0.2-0.5C20.5,26.1,20.5,25.6,20.5,25z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'mobile-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <g>
            <path class="st0" d="M52.4,17.6c-6.6,0-13.2,0-19.8,0c-3,0-5.2,2.2-5.2,5.3c0,6.5,0,13.1,0,19.6c0,6.6,0,13.1,0,19.7
                c0,3,2.2,5.2,5.2,5.2c6.6,0,13.3,0,19.9,0c3,0,5.2-2.3,5.2-5.2c0-13.1,0-26.2,0-39.3C57.7,19.9,55.4,17.6,52.4,17.6z M52.2,64.7
                c-6.5,0-12.9,0-19.4,0c-1.7,0-2.6-0.9-2.6-2.6c0-6.5,0-13,0-19.5s0-12.9,0-19.4c0-1.8,1.2-2.7,2.7-2.7c6.4,0,12.9,0,19.3,0
                c1.5,0,2.7,0.9,2.7,2.7c0,13,0,25.9,0,38.9C54.9,63.7,53.7,64.7,52.2,64.7z"/>
            <path class="st0" d="M42.5,58c1.5,0,2.6,1.2,2.6,2.6c0,1.5-1.2,2.6-2.7,2.6s-2.7-1.2-2.6-2.7C39.9,59.2,41.1,58,42.5,58z"/>
            <path class="st0" d="M44.6,24.5c-0.8,0-1.6,0-2.4,0c-0.9,0-1.5-0.6-1.5-1.4s0.6-1.4,1.5-1.4c1.6,0,3.2,0,4.9,0
                c0.9,0,1.5,0.6,1.5,1.4s-0.6,1.4-1.5,1.4C46.2,24.5,45.4,24.5,44.6,24.5z"/>
            <path class="st0" d="M37.9,24.5c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4C39.4,23.9,38.7,24.5,37.9,24.5z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'phone-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M18.3,29.7c-0.1-2.5,0.8-4.6,2.7-6.4c1.1-1.1,2.2-2.2,3.3-3.3c2.3-2.2,5.1-2.3,7.3-0.1c2.1,2,4.2,4.1,6.2,6.2
            c1.9,2,2,4.5,0.2,6.6c-1.1,1.3-2.4,2.5-3.7,3.7c-0.3,0.2-0.3,0.4-0.1,0.7c1.1,2.2,2.5,4.1,4.2,5.9c2.3,2.6,4.8,4.8,7.7,6.7
            c0.7,0.4,1.4,0.7,2.1,1.1c0.2,0.1,0.3,0,0.5-0.1c1.1-1.1,2.3-2.3,3.4-3.4c0.8-0.8,1.7-1.3,2.8-1.5c1.6-0.3,3,0.3,4.1,1.5
            c1.3,1.3,2.6,2.5,3.8,3.8c0.8,0.8,1.5,1.5,2.3,2.3c2.2,2.2,2.2,5,0.1,7.3c-1.2,1.3-2.5,2.5-3.7,3.8c-1.9,2-4.4,2.5-7.1,2.3
            c-3.9-0.4-7.4-1.8-10.7-3.7c-9.8-5.3-17.2-12.9-22.2-22.8c-1.4-2.7-2.4-5.4-2.9-8.4C18.3,31.1,18.3,30.4,18.3,29.7z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'pinterest'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M44.6,19c0.5,0.2,1,0.2,1.6,0.2c3.1,0.5,6.2,1.6,8.7,3.6c2,1.5,3.6,3.3,4.6,5.5c1.2,2.2,1.7,4.6,1.7,7.2
            c0,3.4-0.7,6.5-2.3,9.5c-2.2,3.9-5.5,6.7-9.9,7.8c-2.4,0.6-4.9,0.6-7.2-0.5c-1.2-0.6-2.2-1.3-2.9-2.4c-0.3,1-0.6,2.1-0.8,3.1
            c-0.6,2.1-1,4.3-2,6.3c-1,2.2-2.3,4.3-3.7,6.4c-0.1,0.1-0.2,0.3-0.3,0.2C32,66,32,65.7,32,65.5c-0.5-2.9-0.7-5.9-0.5-8.8
            c0.1-1.5,0.7-3,1-4.6c0.8-3.5,1.7-7.1,2.7-10.6c0.1-0.2,0-0.5,0-0.7c-0.9-2.6-1.2-5.1,0.1-7.7c0.8-1.6,2-2.7,3.7-3
            c2.4-0.5,4.4,1,4.5,3.5c0.1,1.3-0.2,2.6-0.6,3.8c-0.6,2-1.2,3.8-1.7,5.8c-0.9,3.4,1.7,5.7,4.6,5.5c3.3-0.2,5.5-2,7.1-4.8
            c1.3-2.2,1.9-4.6,2-7.2c0.1-1.9-0.1-3.6-0.7-5.3c-1-2.8-3-4.9-5.7-6c-4.3-1.9-8.6-1.7-12.8,0.3c-3.7,1.9-5.9,5-6.6,9.2
            c-0.5,2.9,0.2,5.7,2.1,8c0.2,0.3,0.3,0.7,0.2,1c-0.2,0.9-0.5,2-0.7,2.9c-0.2,0.7-0.5,0.8-1.2,0.6c-1.5-0.7-2.7-1.9-3.5-3.1
            c-2.1-3.1-2.9-6.7-2.2-10.5c1.3-7.2,5.7-11.5,12.5-13.7c1.5-0.5,3-0.7,4.5-0.8c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.3,0
            c0.1,0.1,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.5,0,0.9,0,1.4,0c0.1,0.1,0.1,0,0.2,0h0.1H44h0.1
            c0.1,0,0.1,0,0.2,0C44.4,19,44.5,19,44.6,19z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'print'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M65,28c-1.8,0-3.5,0-5.3,0c-0.4,0-0.6-0.1-0.6-0.5c0-1.5,0-3.1,0-4.6s-0.8-2.3-2.3-2.3c-4.8,0-9.7,0-14.5,0
            s-9.6,0-14.4,0c-1.5,0-2.3,0.8-2.3,2.3c0,1.6,0,3.1,0,4.7c0,0.4-0.1,0.5-0.5,0.5c-1.8,0-3.6,0-5.4,0c-1.4,0-2.2,0.8-2.2,2.3
            c0,6.4,0,12.7,0,19.1c0,1.5,0.8,2.3,2.3,2.3c1.8,0,3.5,0,5.3,0c0.4,0,0.5,0.1,0.5,0.6c0,3.3,0,6.5,0,9.8c0,1.7,0.7,2.4,2.4,2.4
            c7.6,0,15.2,0,22.7,0c2.1,0,4.2,0,6.2,0c0.8,0,1.4-0.3,1.8-1c0.3-0.4,0.3-0.9,0.3-1.4c0-3.3,0-6.6,0-9.9c0-0.4,0.1-0.5,0.5-0.5
            c1.8,0,3.6,0,5.4,0c1.5,0,2.3-0.8,2.3-2.3c0-6.3,0-12.7,0-19C67.4,28.8,66.6,28,65,28z M22.7,35.1c-1,0-1.8-0.8-1.8-1.8
            s0.8-1.7,1.8-1.7s1.8,0.8,1.8,1.8C24.5,34.3,23.7,35.1,22.7,35.1z M56.7,62.4c-4.7,0-9.5,0-14.2,0s-9.4,0-14.1,0
            c-0.5,0-0.6-0.1-0.6-0.6c0-4.9,0-9.8,0-14.7c0-0.4,0.1-0.5,0.5-0.5c9.5,0,18.9,0,28.4,0c0.4,0,0.5,0.1,0.5,0.5c0,4.9,0,9.9,0,14.8
            C57.2,62.3,57.1,62.4,56.7,62.4z M56.7,28c-4.7,0-9.4,0-14.1,0s-9.4,0-14.1,0c-0.5,0-0.6-0.1-0.6-0.6c0-0.9,0-1.8,0-2.7
            c0-1.2,0.8-2,2-2.1c0.1,0,0.3,0,0.4,0c8.2,0,16.4,0,24.6,0c0.5,0,1.1,0.1,1.5,0.4c0.6,0.4,0.8,1,0.8,1.7c0,0.9,0,1.8,0,2.7
            C57.3,27.9,57.1,28.1,56.7,28z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'tiktok'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M43.6,18.3c2.6,0,5.3,0,7.9,0c0.1,3.4,1.4,6.3,3.8,8.7c2.2,2.1,4.9,3.3,8,3.4c0.4,0,0.5,0.1,0.5,0.5
            c0,2.3,0,4.6,0,6.9c0,0.4-0.1,0.5-0.5,0.5c-4.2-0.1-8.1-1.4-11.5-3.9c-0.1-0.1-0.2-0.1-0.2-0.2h-0.1c0,0.2,0,0.3,0,0.5
            c0,5.6,0,11.2,0.1,16.8c0,2.7-0.5,5.2-1.9,7.5c-3.2,5.4-8,8-14.2,7.7c-6.5-0.3-12-4.8-13.8-11.1c-2.1-7.8,2.5-16.1,10.3-18.3
            c2-0.6,4-0.8,6.1-0.6c0.4,0,0.6,0.1,0.6,0.7c0,2.3,0,4.7,0,7c0,0.4-0.1,0.5-0.5,0.4c-4.7-1.2-9.2,2.5-9,7.3c0.2,3.6,3.1,6.5,6.8,6.8
            c3.6,0.2,6.9-2.3,7.5-5.8c0.1-0.6,0.1-1.2,0.1-1.8c0-10.8,0-21.7,0-32.5C43.6,18.6,43.6,18.4,43.6,18.3z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'twitter'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <g>
            <path class="st0" d="M63.4,63.7c-5.5-8.1-11.1-16.1-16.6-24.2c-0.2-0.3-0.2-0.4,0-0.7c2.7-3.1,5.4-6.2,8.1-9.4
                c2.5-2.9,4.9-5.7,7.4-8.6c-1.2,0-2.5,0-3.7,0c-0.2,0.1-0.4,0.3-0.5,0.5c-1.1,1.3-2.2,2.6-3.4,3.9c-1.5,1.7-3,3.4-4.5,5.2
                c-1.7,2-3.4,3.9-5.1,5.9c-0.2,0.3-0.3,0.2-0.5,0c-1.6-2.3-3.2-4.6-4.7-6.9c-1.8-2.6-3.6-5.2-5.4-7.8c-0.2-0.3-0.3-0.5-0.6-0.7
                c-4.2,0-8.4,0-12.6,0c0,0.2,0.1,0.3,0.2,0.4c5.3,7.8,10.7,15.5,16,23.3c0.2,0.3,0.2,0.4-0.1,0.7c-4.8,5.5-9.5,11-14.3,16.6
                c-0.6,0.7-1.3,1.5-2,2.3c1.2,0,2.3,0,3.4,0c0.2,0,0.4-0.1,0.5-0.2c3.3-3.9,6.7-7.8,10-11.7c1.4-1.6,2.8-3.2,4.2-4.8
                c0.1,0.1,0.2,0.2,0.3,0.4c3.7,5.4,7.4,10.7,11.1,16.1c0.2,0.2,0.3,0.3,0.6,0.3c3.9,0,7.9,0,11.8,0c0.1,0,0.2,0.1,0.4-0.1
                C63.6,64,63.5,63.8,63.4,63.7z M53,61.5c-0.2,0-0.3-0.2-0.4-0.3c-2.3-3.4-4.7-6.7-7-10.1c-6.3-9-12.6-18-18.8-27
                c-0.1-0.1-0.2-0.3-0.3-0.4c0.3,0,0.6,0,0.9,0c1.5,0,2.9,0,4.4,0c0.3,0,0.5,0.1,0.7,0.4c6.3,9,12.6,18.1,19,27.1
                c2.3,3.3,4.6,6.6,7,10c0.1,0.1,0.1,0.2,0.2,0.3C56.7,61.4,54.9,61.4,53,61.5z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'rounded' && name === 'youtube'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M67.2,36.4c-0.2-1.8-0.4-3.7-0.8-5.5c-0.6-2.7-2.8-4.5-5.6-4.8c-2.9-0.3-5.8-0.6-8.8-0.7
            c-3.1-0.1-6.3-0.2-9.4-0.2c-3.6,0-7.3,0.1-10.9,0.2c-2.5,0.1-5,0.3-7.5,0.6c-3.1,0.5-5,2.3-5.5,5.1c-0.4,2.4-0.7,4.8-0.8,7.2
            c-0.3,3.4-0.2,6.9,0.1,10.3c0.2,1.8,0.4,3.7,0.8,5.5c0.6,2.7,2.8,4.5,5.6,4.8c2.9,0.3,5.8,0.6,8.8,0.7c3.1,0.1,6.3,0.2,9.4,0.2
            c3.6,0,7.3-0.1,10.9-0.2c2.5-0.1,5-0.3,7.5-0.6c3.1-0.5,5-2.3,5.5-5.1c0.4-2.4,0.7-4.8,0.8-7.2C67.5,43.3,67.5,39.8,67.2,36.4z
            M37.6,50c0-5,0-9.9,0-14.9c4.3,2.5,8.6,5,13,7.5C46.2,45.1,41.9,47.5,37.6,50z"/>
    </svg>

    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'address-card'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <path class="st0" d="M42.5,66.1l-1.4-1.8c-0.6-0.7-13.9-18.2-13.9-30.1c0-8.4,6.9-15.3,15.3-15.3s15.3,6.9,15.3,15.3
                c0,11.6-13.3,29.4-13.9,30.1L42.5,66.1L42.5,66.1L42.5,66.1z"/>
            <rect x="37.3" y="29.4" width="10.5" height="10.5"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'envelope'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <path class="st0" d="M49.6,41.9l10.6-9.4v19.6L49.6,41.9C49.6,41.9,49.6,41.9,49.6,41.9z M37.5,43.7l2.1,1.8
                c0.9,0.9,2.1,1.2,2.9,1.2c0.9,0,2.1-0.3,2.9-1.2l2.1-1.8L58.1,54H27L37.5,43.7C37.5,43.7,37.5,43.7,37.5,43.7z M43.7,43.4
                c-0.6,0.6-1.8,0.6-2.6,0L27,30.8h30.8L43.7,43.4C43.7,43.4,43.7,43.4,43.7,43.4z M24.9,32.5l10.6,9.4L24.9,52.2V32.5
                C24.9,32.5,24.9,32.5,24.9,32.5z M22.3,28.4v28.4h40.5V28.4H22.3z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'facebook'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <path class="st0" d="M45.5,62.5h-8.2V42.5h-4.1v-7h4.1v-4.1c0-5.6,2.3-9.1,9.1-9.1h5.6v7h-3.5c-2.6,0-2.6,0.9-2.6,2.6v3.5h6.2
                l-0.6,7h-5.6v19.9l0,0H45.5z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'globe'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <path class="st0" d="M45.7,63h-6.5l1.2-2.6v-2.3h4.4v2.3L45.7,63L45.7,63z M22.3,26.7H63v28.2H22.3V26.7L22.3,26.7z M66,58.1V23.8
                H19.1v34.3h18.2v1.8l-2.3,6.5h15.2l-2.3-6.2v-1.8H66V58.1z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'instagram'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <g>
                <path class="st0" d="M42.5,26.1c5.3,0,5.9,0,7.9,0s2.9,0.3,3.8,0.6c0.9,0.3,1.5,0.9,2.3,1.5c0.6,0.6,1.2,1.5,1.5,2.3
                    c0,0.6,0.6,1.8,0.6,3.8c0,2.1,0,2.6,0,7.9s0,5.9,0,7.9s-0.3,2.9-0.6,3.8s-0.9,1.5-1.5,2.3c-0.6,0.6-1.5,1.2-2.3,1.5
                    c-0.6,0-1.8,0.6-3.8,0.6c-2.1,0-2.6,0-7.9,0s-5.9,0-7.9,0s-2.9-0.3-3.8-0.6c-0.9-0.3-1.5-0.9-2.3-1.5c-0.6-0.6-1.2-1.5-1.5-2.3
                    c0-0.6-0.6-1.8-0.6-3.8c0-2.1,0-2.6,0-7.9s0-5.9,0-7.9s0.3-2.9,0.6-3.8c0.3-0.9,0.9-1.5,1.5-2.3c0.6-0.6,1.5-1.2,2.3-1.5
                    c0.6,0,1.8-0.6,3.8-0.6S37.2,26.1,42.5,26.1 M42.5,22.6c-5.3,0-6.2,0-8.2,0c-2.1,0-3.5,0.3-4.7,0.9c-1.2,0.6-2.3,1.2-3.5,2.3
                    c-1.2,1.2-1.8,2.3-2.3,3.5c-0.6,1.2-0.9,2.6-0.9,4.7c0,2.1,0,2.9,0,8.2s0,6.2,0,8.2s0.3,3.5,0.9,4.7c0.6,1.2,1.2,2.3,2.3,3.5
                    c1.2,1.2,2.3,1.8,3.5,2.3c1.2,0.6,2.6,0.9,4.7,0.9c2.1,0,2.9,0,8.2,0s6.2,0,8.2,0c2.1,0,3.5-0.3,4.7-0.9c1.2-0.6,2.3-1.2,3.5-2.3
                    c1.2-1.2,1.8-2.3,2.3-3.5c0.6-1.2,0.9-2.6,0.9-4.7s0-2.9,0-8.2s0-6.2,0-8.2s-0.3-3.5-0.9-4.7c-0.6-1.2-1.2-2.3-2.3-3.5
                    c-1.2-1.2-2.3-1.8-3.5-2.3c-1.2-0.6-2.6-0.9-4.7-0.9C48.7,22.6,47.8,22.6,42.5,22.6C42.5,22.6,42.5,22.6,42.5,22.6z"/>
                <path class="st0" d="M42.5,32.3c-5.6,0-10.3,4.7-10.3,10.3s4.7,10.3,10.3,10.3s10.3-4.7,10.3-10.3S48.1,32.3,42.5,32.3z
                    M42.5,49.3c-3.5,0-6.7-2.9-6.7-6.7s2.9-6.7,6.7-6.7s6.7,2.9,6.7,6.7S46.3,49.3,42.5,49.3z"/>
                <circle class="st0" cx="53.1" cy="32" r="2.3"/>
            </g>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'linkedin'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <g>
                <rect x="23.2" y="33.7" class="st0" width="8.8" height="28.2"/>
                <path class="st0" d="M27.6,29.9c-2.9,0-5-2.3-5-5s2.3-5,5-5s5,2.3,5,5S30.2,29.9,27.6,29.9z"/>
                <path class="st0" d="M64.5,61.9h-8.8V48.1c0-3.2,0-7.3-4.4-7.3S46,44.3,46,48.1v13.8h-8.8V33.7h8.5v3.8l0,0
                    c1.2-2.3,4.1-4.4,8.2-4.4c8.8,0,10.6,5.9,10.6,13.5V61.9C64.5,61.9,64.5,61.9,64.5,61.9z"/>
            </g>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'mobile-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <g>
            <path class="st0" d="M51,21.2c-5.6,0-11.3,0-16.9,0c-2.6,0-4.5,1.9-4.5,4.5c0,5.6,0,11.2,0,16.7s0,11.2,0,16.8
                c0,2.6,1.9,4.5,4.4,4.5c5.6,0,11.3,0,16.9,0c2.6,0,4.4-1.9,4.4-4.5c0-11.2,0-22.3,0-33.6C55.5,23.2,53.6,21.2,51,21.2z M50.8,61.4
                c-5.5,0-11,0-16.6,0c-1.5,0-2.2-0.8-2.2-2.2c0-5.6,0-11.1,0-16.7c0-5.5,0-11,0-16.6c0-1.5,1-2.3,2.3-2.3c5.5,0,11,0,16.5,0
                c1.3,0,2.3,0.8,2.3,2.3c0,11,0,22.1,0,33.2C53.1,60.6,52.1,61.4,50.8,61.4z"/>
            <path class="st0" d="M42.6,55.7c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.3,2.2s-2.2-1-2.2-2.2S41.3,55.7,42.6,55.7z"/>
            <path class="st0" d="M44.4,27.1c-0.7,0-1.3,0-2.1,0S41,26.6,41,25.9s0.5-1.2,1.3-1.2c1.4,0,2.8,0,4.2,0c0.7,0,1.3,0.5,1.3,1.2
                c0,0.6-0.5,1.2-1.3,1.2C45.7,27.1,45.1,27.1,44.4,27.1z"/>
            <path class="st0" d="M38.6,27.1c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.6-1.2,1.2-1.2s1.2,0.5,1.2,1.2C39.9,26.6,39.4,27.1,38.6,27.1z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'phone-alt'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <circle cx="42.5" cy="42.5" r="42.5"/>
            <path class="st0" d="M22.9,54.5c1.2,3.2,3.8,6.7,5,7.9c2.6-0.3,11.4-2.3,22-12.9c10.6-10.8,12.3-19.6,12.9-22.3
                c-1.5-1.2-4.7-3.5-7.9-4.7l-2.9,6.7l2.6,4.4c0.3,0.6,0.3,1.2,0,1.8c-2.1,3.2-15.5,17-18.8,19.1c-0.6,0.3-1.2,0.3-1.8,0l-4.4-2.6
                l-6.5,2.9l0,0L22.9,54.5z M27,66c-0.6,0-0.9,0-1.2-0.6c0,0-5.3-5.9-6.7-11.4c0-0.9,0-1.8,0.9-2.1l8.8-3.8h1.5l4.1,2.6
                c3.8-2.9,13.5-12.9,16.4-16.4l-2.6-4.1c0-0.6-0.3-1.2,0-1.5l3.8-8.8c0.3-0.9,1.2-1.2,2.1-0.9c5.6,1.2,11.1,6.2,11.4,6.5
                c0.3,0,0.6,0.9,0.6,1.2c0,0.6,0,11.4-13.8,25.2S27.6,66,27,66C27,66,27,66,27,66z"/>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'pinterest'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M44.6,22.3c0.4,0.2,0.9,0.2,1.4,0.2c2.7,0.4,5.3,1.4,7.5,3.1c1.7,1.3,3.1,2.8,4,4.7c1,1.9,1.5,4,1.5,6.2
            c0,2.9-0.6,5.6-2,8.2c-1.9,3.4-4.7,5.8-8.5,6.7c-2.1,0.5-4.2,0.5-6.2-0.4c-1-0.5-1.9-1.1-2.5-2.1c-0.3,0.9-0.5,1.8-0.7,2.7
            c-0.5,1.8-0.9,3.7-1.7,5.4c-0.9,1.9-2,3.7-3.2,5.5c-0.1,0.1-0.2,0.3-0.3,0.2c-0.2,0-0.2-0.2-0.2-0.4c-0.4-2.5-0.6-5.1-0.4-7.6
            c0.1-1.3,0.6-2.6,0.9-4c0.7-3,1.5-6.1,2.3-9.1c0.1-0.2,0-0.4,0-0.6c-0.8-2.2-1-4.4,0.1-6.6c0.7-1.4,1.7-2.3,3.2-2.6
            c2.1-0.4,3.8,0.9,3.9,3c0.1,1.1-0.2,2.2-0.5,3.3c-0.5,1.7-1,3.3-1.5,5c-0.8,2.9,1.5,4.9,4,4.7c2.8-0.2,4.7-1.7,6.1-4.1
            c1.1-1.9,1.6-4,1.7-6.2c0.1-1.6-0.1-3.1-0.6-4.6c-0.9-2.4-2.6-4.2-4.9-5.2c-3.7-1.6-7.4-1.5-11,0.3c-3.2,1.6-5.1,4.3-5.7,7.9
            c-0.4,2.5,0.2,4.9,1.8,6.9c0.2,0.3,0.3,0.6,0.2,0.9c-0.2,0.8-0.4,1.7-0.6,2.5c-0.2,0.6-0.4,0.7-1,0.5c-1.3-0.6-2.3-1.6-3-2.7
            c-1.8-2.7-2.5-5.8-1.9-9c1.1-6.2,4.9-9.9,10.8-11.8c1.3-0.4,2.6-0.6,3.9-0.7c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.3,0
            c0.1,0.1,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2,0c0.1,0.1,0.1,0,0.2,0h0.1h0.1h0.1
            c0.1,0,0.1,0,0.2,0C44.4,22.3,44.5,22.3,44.6,22.3z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'print'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M61.7,30.1c-1.5,0-3,0-4.5,0c-0.4,0-0.4-0.1-0.4-0.4c0-1.3,0-2.6,0-3.9s-0.6-1.9-1.9-1.9c-4.1,0-8.2,0-12.4,0
            c-4.1,0-8.2,0-12.3,0c-1.3,0-1.9,0.6-1.9,1.9s0,2.6,0,4c0,0.3-0.1,0.4-0.4,0.4c-1.5,0-3.1,0-4.6,0c-1.2,0-1.9,0.7-1.9,1.9
            c0,5.5,0,10.8,0,16.3c0,1.3,0.6,2,1.9,2c1.5,0,3,0,4.5,0c0.4,0,0.4,0.1,0.4,0.4c0,2.8,0,5.6,0,8.3c0,1.5,0.6,2.1,2.1,2.1
            c6.5,0,12.9,0,19.4,0c1.8,0,3.5,0,5.3,0c0.6,0,1.2-0.3,1.5-0.8c0.3-0.4,0.3-0.8,0.3-1.2c0-2.8,0-5.6,0-8.4c0-0.3,0.1-0.4,0.4-0.4
            c1.5,0,3.1,0,4.6,0c1.3,0,1.9-0.7,1.9-1.9c0-5.4,0-10.8,0-16.2C63.7,30.7,63,30.1,61.7,30.1z M25.6,36.1c-0.8,0-1.5-0.6-1.5-1.5
            c0-0.8,0.6-1.5,1.5-1.5s1.5,0.7,1.5,1.5C27.2,35.4,26.5,36.1,25.6,36.1z M54.6,59.4c-4,0-8.1,0-12.1,0s-8,0-12,0
            c-0.4,0-0.5-0.1-0.5-0.5c0-4.2,0-8.3,0-12.6c0-0.3,0.1-0.4,0.4-0.4c8.1,0,16.2,0,24.3,0c0.4,0,0.4,0.1,0.4,0.4c0,4.2,0,8.4,0,12.6
            C55.1,59.3,54.9,59.4,54.6,59.4z M54.6,30.1c-4,0-8,0-12.1,0s-8,0-12.1,0c-0.4,0-0.5-0.1-0.5-0.5c0.1-0.8,0-1.5,0-2.3
            c0-1,0.6-1.7,1.7-1.8c0.1,0,0.2,0,0.3,0c7,0,14,0,21,0c0.4,0,0.9,0.1,1.3,0.3c0.5,0.4,0.7,0.9,0.7,1.5c0,0.8,0,1.5,0,2.3
            C55.1,30,55,30.1,54.6,30.1z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'tiktok'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M43.5,21.3c2.3,0,4.6,0,6.9,0c0.1,3,1.2,5.6,3.3,7.6c2,1.9,4.3,2.8,7,3c0.3,0,0.4,0.1,0.4,0.4c0,2.1,0,4,0,6.1
            c0,0.3-0.1,0.4-0.4,0.4c-3.7-0.1-7.1-1.2-10.1-3.4c-0.1-0.1-0.1-0.1-0.2-0.1h-0.1c0,0.1,0,0.3,0,0.4c0,4.9,0,9.8,0.1,14.8
            c0,2.4-0.4,4.6-1.7,6.6c-2.9,4.6-7.1,6.9-12.5,6.7c-5.7-0.3-10.6-4.2-12.1-9.7c-1.9-6.9,2.2-14.1,9-16c1.7-0.5,3.5-0.6,5.3-0.5
            c0.4,0.1,0.6,0.1,0.6,0.6c0,2.1,0,4.1,0,6.1c0,0.3-0.1,0.4-0.4,0.3c-4.2-1-8.2,2.2-8,6.5c0.1,3.1,2.7,5.7,5.9,5.9
            c3.1,0.2,6-2.1,6.5-5.1c0.1-0.5,0.1-1,0.1-1.6c0-9.5,0-19,0-28.5C43.4,21.6,43.4,21.4,43.5,21.3z"/>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'twitter'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <g>
            <g id="Illustration">
                <circle cx="42.5" cy="42.5" r="42.5"/>
            </g>
            <g id="RGB">
                <path class="st0" d="M46.3,39.6l14.7-17h-3.5L44.9,37.2l-10-14.7H23.2l15.2,22.3L23.2,62.8h3.5l13.5-15.5l10.6,15.5h11.7
                    L46.6,39.6l0,0L46.3,39.6z M41.6,44.9l-1.5-2.3L27.9,24.9h5.3l10,14.1l1.5,2.3l12.9,18.5h-5.3L41.6,44.9
                    C41.6,44.9,41.6,44.9,41.6,44.9z"/>
            </g>
        </g>
    </svg>
    <svg :fill="fill" v-if="shape === 'shape-1' && name === 'youtube'"
        width="60" height="60" viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
        <circle cx="42.5" cy="42.5" r="42.5"/>
        <path class="st0" d="M65.7,33.1c0,0-0.6-3.2-1.8-4.7c-1.8-1.8-3.8-1.8-4.7-2.1c-6.7-0.6-16.7-0.6-16.7-0.6l0,0c0,0-10,0-16.7,0.6
            c-0.9,0-2.9,0-4.7,2.1c-1.5,1.5-1.8,4.7-1.8,4.7s-0.6,3.8-0.6,7.6v3.5c0,3.8,0.6,7.6,0.6,7.6s0.6,3.2,1.8,4.7
            c1.8,1.8,4.1,1.8,5.3,2.1c3.8,0.3,16.1,0.6,16.1,0.6s10,0,16.7-0.6c0.9,0,2.9,0,4.7-2.1c1.5-1.5,1.8-4.7,1.8-4.7s0.6-3.8,0.6-7.6
            v-3.5C66.3,36.9,65.7,33.1,65.7,33.1C65.7,33.1,65.7,33.1,65.7,33.1z M37.5,48.7V35.2l12.9,6.7L37.5,48.7
            C37.5,48.7,37.5,48.7,37.5,48.7z"/>
    </svg>
</template>

<script>
export default {
  name: 'Icons',
  props: {
    fill: {
      type: String,
      default: '#2c384af2',
    },
    shape: {
      type: String,
      default: 'standard',
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
    .st0{
        fill:#FFFFFF;
    }
</style>

export const getters = {
  getAccountById: state => ({ accountId }) => {
    return state.accounts.find(account => account.accountId === accountId);
  },
  getAdminAccountById: state => ({ accountId }) => {
    return state.adminAccounts.find(account => account.accountId === accountId);
  },
  getAccountsByName: state => ({ name }) => {
    return state.accounts.filter(account => account.accountName.toLowerCase().includes(name.toLowerCase()));
  },
  getCardsById: state => ({ accountId }) => {
    return state.cards.filter(card => card.accountId === accountId);
  },
  getCardsByName: state => ({ name }) => {
    return state.cards.filter(cards => cards.cardOwnerName.toLowerCase().includes(name.toLowerCase()));
  },
  getCardById: state => ({ cardId }) => {
    return state.cards.find(card => card.cardId === cardId);
  },
  isFetchingPermissionsBasedData: state => {
    return state.isFetchingStatistics;
  },
};

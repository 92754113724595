<template>
    <div v-if="accountDetailsFormData" class="account-details-form-container">
        <CForm @submit.prevent="handleFormSubmit">
            <CRow class="mb-3">
                <CFormLabel
                    class="col-sm-4 col-form-label"
                    for="account-name">
                    Username:
                </CFormLabel>

                <CCol sm="8">
                    <CFormInput
                        id="account-name"
                        name="account-name"
                        type="text"
                        placeholder="Username"
                        :value="accountDetailsFormData.accountName"
                        @input="handleInputDataChange($event, 'accountName')"/>
                </CCol>
            </CRow>

            <br />

            <CRow class="mb-3">
                <CFormLabel
                    class="col-sm-4 col-form-label"
                    for="email">
                    Email:
                </CFormLabel>

                <CCol sm="8">
                    <CFormInput
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        :value="accountDetailsFormData.loginEmail"
                        @input="handleInputDataChange($event, 'loginEmail')"/>
                </CCol>
            </CRow>

            <br />

            <CRow class="mb-3">
                <CFormLabel
                    class="col-sm-4 col-form-label"
                    for="password">
                    Password:
                </CFormLabel>

                <CCol sm="8">
                    <CFormInput
                        ref="passwordField"
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        :value="accountDetailsFormData.loginPassword"
                        @input="handleInputDataChange($event, 'loginPassword')"/>

                        <button
                          class="toggle-password-visibility"
                          type="button"
                          @click="handlePasswordVisibilityToggle">
                            <font-awesome-icon :icon=" showPassword ? 'eye-slash' : 'eye'" />
                          </button>
                </CCol>
            </CRow>

            <br />

            <CRow class="mb-3">
                <CFormLabel class="col-sm-4 col-form-label" />

                <CCol sm="6">
                    <CFormSwitch
                        size="lg"
                        label="Disable / Enable account"
                        id="disable-account"
                        :checked="accountDetailsFormData.isAccountEnabled"
                        @change="handleInputDataChange($event, 'isAccountEnabled')"/>
                </CCol>
            </CRow>

            <br />

            <CRow class="mb-3 action-buttons">
                <CFormLabel class="col-sm-6 col-form-label" />

                <CButton
                    color="info"
                    size="sm"
                    variant="outline"
                    @click="handleCancel">
                    Cancel
                </CButton>

                <CButton
                    class="save-button"
                    color="info"
                    size="sm"
                    :disabled="
                      !accountDetailsFormData.accountName ||
                      !accountDetailsFormData.loginEmail ||
                      !accountDetailsFormData.loginPassword
                    "
                    type="submit">
                    Save
                </CButton>
            </CRow>
        </CForm>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { routeNames } from '@/config/routes';
import adminAccountDriver from '@/drivers/AdminAccountDriver';

export default {
  name: 'AccountDetailsForm',
  data () {
    return {
      accountDetailsFormData: adminAccountDriver.createClientFormData(this.account, [
        'accountName',
        'loginEmail',
        'loginPassword',
        'isAccountEnabled',
      ]),
      showPassword: false,
    };
  },
  props: {
    account: Object,
  },
  methods: {
    ...mapActions(['updateAdminAccount']),
    ...mapMutations(['addAlert']),
    handleInputDataChange (value, propertyChanged) {
      this.accountDetailsFormData[propertyChanged] = value;
    },
    handleCheckboxDataChange (checkboxState, propertyChanged, value) {
      if (checkboxState) {
        this.accountDetailsFormData[propertyChanged].push(value);
      } else {
        const elementToRemoveIndex = this.accountDetailsFormData[propertyChanged].indexOf(value);

        this.accountDetailsFormData[propertyChanged].splice(elementToRemoveIndex, 1);
      }
    },
    handlePasswordVisibilityToggle () {
      this.showPassword = !this.showPassword;
      this.$refs.passwordField.$el.type = this.showPassword ? 'text' : 'password';
    },
    handleCancel () {
      this.$router.push({
        name: routeNames.ALL_ADMIN_ACCOUNTS,
      });
    },
    handleFormSubmit () {
      this.updateAdminAccount({
        account: {
          accountId: this.account.accountId,
          ...this.accountDetailsFormData,
        },
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Administrator Account Edited Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Administrator Account Edit Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .account-details-form-container {
        margin-top: 20px;

        select {
            width: 100px;
        }
    }

    .col-form-label {
        text-align: right;
        font-size: 14px;
        font-weight: 600;
    }

    .edit-field-button {
        border: 1px solid var(--cui-input-border-color, #b1b7c1);
        background-color: var(--white);
    }

    .action-buttons {
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }

    button.toggle-password-visibility {
      float: right;
      margin: -32px 10px 0 0;
      font-size: 20px;
      padding: 0;
      height: 26px;
      border: none;
      background-color: inherit;
      color: var(--tago-light-blue);

      &:hover {
        color: var(--tago-blue);
      }
    }
</style>

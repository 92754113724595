<template>
    <div class="dashboard-cards-section-container">
      <DashboardCard
        v-for="(cardConfig, index) in filteredCardConfig"
        :key="index"
        :title="cardConfig.title"
        :count="cardConfig.count"
        :icon="cardConfig.icon"
        :routeName="cardConfig.routeName"
        :isFetchingInProgress="cardConfig.isFetchingInProgress"/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { cardConfig } from '@/config/dashboard';
import { routeNames } from '@/config/routes';
import userService from '@/services/UserService';
import DashboardCard from '@/components/admin/shared/DashboardCard';

export default {
  name: 'Dashboard',
  components: {
    DashboardCard,
  },
  computed: {
    ...mapState({
      accountCount: state => state.accounts.length,
      cardCount: state => state.cards.length,
      isFetchingAccountsInProgress: state => state.isFetchingAccounts,
      isFetchingCardsInProgress: state => state.isFetchingCards,
    }),
    filteredCardConfig () {
      const counts = [this.accountCount, this.cardCount]
        .filter((_, index) => userService.hasRouteAccess([routeNames.ALL_ACCOUNTS, routeNames.ALL_CARDS][index]));

      const isFetchingInProgress = [this.isFetchingAccountsInProgress, this.isFetchingCardsInProgress]
        .filter((_, index) => userService.hasRouteAccess([routeNames.ALL_ACCOUNTS, routeNames.ALL_CARDS][index]));

      const config = [cardConfig.accounts, cardConfig.cards]
        .filter((_, index) => userService.hasRouteAccess([routeNames.ALL_ACCOUNTS, routeNames.ALL_CARDS][index]));

      return config.map((config, index) => {
        return {
          ...config,
          isFetchingInProgress: isFetchingInProgress[index],
          count: counts[index],
        };
      }); ;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/sidebar';

  .dashboard-cards-section-container {
    display: flex;
    margin-top: $nav-item-height + $nav-border-width;
  }
</style>

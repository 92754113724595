import axios from 'axios';
import authenticationService from '@/services/AuthenticationService';

axios.interceptors.request.use(config => {
  const token = authenticationService.getAuthenticationTokenFromStorage();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(response => {
  if (response.data?.message !== 'Success') {
    const errorMessage = response.data?.message ? response.data.message : 'request succeded with message missing';

    throw new Error(errorMessage);
  }

  return response;
}, error => {
  if (error.response.status === 401) {
    authenticationService.logout();
  }

  return Promise.reject(error);
});

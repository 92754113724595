<template>
    <CModal :visible="isVisible" @close="handleHide">
        <CForm @submit.prevent="handleSubmit">
            <CModalHeader>
                <CModalTitle>Send My Details</CModalTitle>
            </CModalHeader>

            <CModalBody>
                <div class="send-my-details-form-wrapper">
                        <div class="send-my-details-form-input-wrapper">
                            <CFormLabel for="name">Full Name</CFormLabel>
                            <CFormInput
                                required
                                type="text"
                                placeholder="Your Full Name"
                                @input="handleInputChange($event, 'name')"/>
                        </div>

                        <div class="send-my-details-form-input-wrapper">
                            <CFormLabel for="company">Company</CFormLabel>
                            <CFormInput
                                type="text"
                                placeholder="Your Company"
                                @input="handleInputChange($event, 'company')"/>
                        </div>

                        <div class="send-my-details-form-input-wrapper">
                            <CFormLabel for="position">Position</CFormLabel>
                            <CFormInput
                                type="text"
                                placeholder="Your Company Position"
                                @input="handleInputChange($event, 'position')"/>
                        </div>

                        <div class="send-my-details-form-input-wrapper">
                            <CFormLabel for="phone">Mobile Phone</CFormLabel>
                            <CFormInput
                                required
                                type="text"
                                placeholder="Your Mobile Phone"
                                @input="handleInputChange($event, 'phone')"/>
                        </div>

                        <div class="send-my-details-form-input-wrapper">
                            <CFormLabel for="email">Email</CFormLabel>
                            <CFormInput
                                required
                                type="email"
                                placeholder="Your Email Address"
                                @input="handleInputChange($event, 'email')"/>
                        </div>
                </div>
            </CModalBody>

            <CModalFooter>
                <CButton
                    type="button"
                    color="secondary"
                    @click="handleHide">
                    Cancel
                </CButton>

                <CButton
                    type="submit"
                    color="info">
                    Send
                </CButton>
            </CModalFooter>
        </CForm>
    </CModal>
</template>

<script>
import { getBaseUrl } from '@/helpers/getBaseUrl';
import axios from 'axios';

export default {
  name: 'ModalSendMyDetails',
  emits: ['modal-hide'],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cardId: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      alerts: [],
      formData: {
        name: '',
        company: '',
        position: '',
        phone: '',
        email: '',
        cardId: this.cardId,
      },
    };
  },
  computed: {
    sendMyDetailsFormActionURL () {
      const baseUrl = getBaseUrl();

      return `${baseUrl}/send-my-details`;
    },
  },
  methods: {
    handleHide () {
      this.$emit('modal-hide');
    },
    handleInputChange (val, propertyName) {
      this.formData[propertyName] = val;
    },
    handleSubmit () {
      axios.post('/api/send-my-details', this.formData)
        .then(() => {
          this.handleHide();
          window.alert('Details Successfully Sent');
        })
        .catch(() => {
          window.alert('Sending Details Failed');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .send-my-details-form-input-wrapper {
        text-align: left;
        margin-bottom: 10px;
    }

    label {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
    }

    input {
        background-color: var(--background-grey);
        border: none;
    }
</style>

import cardTypeDriver from '@/drivers/CardTypeDriver';

class CardDriver {
    dataMappings = Object.freeze({
      accountId: {
        clientKey: 'accountId',
        serverKey: 'accountId',
        defaultValue: '',
        formatServerPropertyFunction: id => String(id),
      },
      contractOptions: {
        clientKey: 'hasLanguagePack',
        serverKey: 'hasLanguagePack',
        defaultValue: false,
      },
      cardLink: {
        clientKey: 'cardLink',
        serverKey: 'link',
        defaultValue: '',
      },
      cardCreatedAt: {
        clientKey: 'cardCreatedAt',
        serverKey: 'createdAt',
        defaultValue: '',
      },
      cardId: {
        clientKey: 'cardId',
        serverKey: 'id',
        defaultValue: '',
        formatServerPropertyFunction: id => String(id),
      },
      cardOwnerName: {
        clientKey: 'cardOwnerName',
        serverKey: 'name',
        defaultValue: '',
      },
      userId: {
        clientKey: 'userId',
        serverKey: 'userId',
        defaultValue: '',
      },
      cardTypeList: {
        clientKey: 'cardTypeList',
        serverKey: 'cards',
        defaultValue: '',
        formatServerPropertyFunction: cardTypeList => cardTypeList.map(cardType => cardTypeDriver.formatServerData(cardType)),
      },
      cardLoginEmail: {
        clientKey: 'cardLoginEmail',
        serverKey: 'loginEmail',
        defaultValue: '',
      },
      cardLoginPassword: {
        clientKey: 'cardLoginPassword',
        serverKey: 'loginPassword',
        defaultValue: '',
      },
      cardPhotoURL: {
        clientKey: 'cardProfilePicture',
        serverKey: 'profilePicture',
        defaultValue: '',
      },
    })

    formatServerData (responseData) {
      return Object.keys(this.dataMappings).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.clientKey] = objMap.formatServerPropertyFunction
          ? objMap.formatServerPropertyFunction(responseData[objMap.serverKey])
          : responseData[objMap.serverKey];

        return acc;
      }, {});
    }

    formatClientData (clientData) {
      return Object.keys(clientData).reduce((acc, curVal) => {
        const objMap = this.dataMappings[curVal];

        acc[objMap.serverKey] = objMap.formatClientPropertyFunction
          ? objMap.formatClientPropertyFunction(clientData[objMap.clientKey])
          : clientData[objMap.clientKey];

        return acc;
      }, {});
    }

    createClientFormData (card, configList) {
      return configList.reduce((acc, curVal) => {
        const config = typeof curVal === 'string' ? { clientKey: curVal } : curVal;

        const { clientKey, formatFunction } = config;

        const value = card[clientKey] || this.dataMappings[clientKey].defaultValue;

        acc[clientKey] = formatFunction ? formatFunction(value) : value;

        return acc;
      }, {});
    }
}

export default new CardDriver();

<template>
    <CForm @submit.prevent="handleSubmit">
        <CRow class="mb-3">
            <CFormLabel
                class="col-sm-4 col-form-label"
                for="card-owner-name">
                Card Name:
            </CFormLabel>

            <CFormInput
                id="card-owner-name"
                name="card-owner-name"
                type="text"
                placeholder="Enter Name"
                :value="cardOwnerName"
                @input="handleCardOwnerNameChange"/>
        </CRow>

        <CRow class="mb-3 action-buttons">
            <CButton
                class="save-button"
                color="info"
                size="sm"
                type="submit">
                Save
            </CButton>
        </CRow>
    </CForm>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'CardNameForm',
  data () {
    return {
      cardOwnerName: this.card.cardOwnerName,
    };
  },
  props: {
    card: Object,
  },
  methods: {
    ...mapActions(['changeCardOwnerName']),
    ...mapMutations(['addAlert']),
    handleCardOwnerNameChange (value) {
      this.cardOwnerName = value;
    },
    handleSubmit () {
      this.changeCardOwnerName({
        cardId: this.card.cardId,
        cardOwnerName: this.cardOwnerName,
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Card Name Updated Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Card Name Update Failed',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    input {
        background-color: var(--background-grey);
    }

    .col-form-label {
        padding: 0;
        font-size: 14px;
        font-weight: 600;
    }

    .action-buttons {
        justify-content: right;
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }
</style>

import { routeNames } from '@/config/routes';

export const singleAccountConfig = Object.freeze([
  {
    label: 'Card List',
    routeName: routeNames.CARDS,
  },
  {
    label: 'Account Details',
    routeName: routeNames.ACCOUNT_DETAILS,
  },
  {
    label: 'Account Login Details',
    routeName: routeNames.ACCOUNT_LOGIN_DETAILS,
  },
  {
    label: 'Themes',
    routeName: routeNames.ACCOUNT_THEMES,
  },
]);

export const singleCardConfig = Object.freeze([
  {
    label: 'Contact Details',
    routeName: routeNames.CARD_CONTACT_DETAILS,
  },
  {
    label: 'Card Details',
    routeName: routeNames.CARD_DETAILS,
  },
  {
    label: 'Mobile Preview',
    routeName: routeNames.CARD_MOBILE_PREVIEW,
  },
  {
    label: 'Card Link',
    routeName: routeNames.CARD_LINK,
  },
  {
    label: 'Android Card',
    routeName: routeNames.CARD_ANDROID,
  },
  {
    label: 'Add Photo',
    routeName: routeNames.CARD_PHOTO,
  },
  {
    label: 'Delete Card',
    routeName: routeNames.CARD_DELETE,
  },
  {
    label: 'Login Details',
    routeName: routeNames.CARD_LOGIN_DETAILS,
  },
]);

export const singleAdminAccountConfig = Object.freeze([
  {
    label: 'Account Details',
    routeName: routeNames.ADMIN_ACCOUNT_DETAILS,
  },
  {
    label: 'Delete Account',
    routeName: routeNames.ADMIN_ACCOUNT_DELETE,
  },
]);

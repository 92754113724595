<template>
    <div class="copyright-container">
        <div class="copyright">
            tagocards.com
        </div>
    </div>
</template>

<script>
export default {
  name: 'Copyright',
};
</script>

<style lang="scss" scoped>
    .copyright-container {
        .copyright {
            display: flex;
            flex-direction: row;
            font-size: 10px;
            font-weight: 600;
        }

        .copyright:before, .copyright:after{
            content: "";
            flex: 1 1;
            border-bottom: 1px solid;
            margin: auto;
        }

        .copyright:before {
            margin-right: 10px;
        }

        .copyright:after {
            margin-left: 10px;
        }
    }
</style>

<template>
    <div
        :class="!account.isAccountEnabled && 'disabled-account'"
        class="account-item">
        <div class="account-name">
            {{ account.accountName }}
        </div>

        <div>
            {{ formatDate(account.accountCreatedAt) }}
        </div>

        <div class="action-buttons-wrapper">
            <CButton
                class="view-account-button action-button"
                variant="outline"
                color="info"
                @click="handleViewAccount">
                Account Details
            </CButton>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon';
import { routeNames } from '@/config/routes';
import { features } from '@/config/features';
import userService from '@/services/UserService';

export default {
  name: 'AdminAccountItem',
  props: {
    account: Object,
  },
  computed: {
    userCanEditAccounts () {
      return userService.hasFeatureAccess(features.EDIT_ADMIN_ACCOUNT);
    },
  },
  methods: {
    formatDate (date) {
      return DateTime.fromISO(date).toFormat('dd.MM.yyyy');
    },
    handleViewAccount () {
      this.$router.push({
        name: routeNames.ADMIN_ACCOUNT_DETAILS,
        params: {
          accountId: this.account.accountId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';
  @import '@/assets/scss/sidebar';

  .account-item {
      background-color: var(--white);
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px 30px;
      width: 100%;
      font-size: 14px;
  }

  .account-name {
      text-transform: uppercase;
      margin-right: 10px;
      width: 40%;
  }

  .view-account-button {
      font-size: 12px;
      width: 120px;
}

  .disabled-account {
    opacity: 0.65;
  }
</style>

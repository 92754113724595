<template>
  <div class="contact-us-form">
        <div class="contact-us-form-input-wrapper">
            <CFormLabel for="name">Name</CFormLabel>
            <CFormInput
                id="name"
                name="name"
                type="text"
                placeholder="Your Full Name"/>
        </div>

        <div class="contact-us-form-input-wrapper">
            <CFormLabel for="phone-number">Phone Number</CFormLabel>
            <CFormInput
                id="phone-number"
                name="phoneNumber"
                type="text"
                placeholder="Enter Your Phone Number"/>
        </div>

        <div class="contact-us-form-input-wrapper">
            <CFormLabel for="email">Email</CFormLabel>
            <CFormInput
                id="email"
                name="email"
                type="text"
                placeholder="Enter Your Email Address"/>
        </div>

        <div class="contact-us-form-input-wrapper">
            <CFormLabel for="reported-issue">Reported Issue:</CFormLabel>
            <CFormTextarea
                rows="8"
                id="reported-issue"
                name="reportedIssue"
                placeholder="Write a Report"/>
        </div>

        <div class="contact-us-form-input-wrapper">
            <CFormLabel for="attachment">Attachment:</CFormLabel>
            <h5>Accepted File Formats: TXT, PDF, DOC, DOCX, XLS, XLSX, GIF, JPG, JPEG</h5>

            <CFormInput
                id="attachment"
                name="attachment"
                class="attachment"
                type="file" />
        </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUsForm',
  data () {
    return {
      numberOfFileInputs: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .contact-us-form {
        display: grid;
        grid-gap: 20px;

        label {
            font-size: 14px;
            font-weight: 700;
        }

        input, textarea {
            background-color: var(--background-grey);
            border: none;
        }

        .attachment {
            margin-bottom: 10px;
        }

        h5 {
            font-size: 12px;
        }
    }
</style>

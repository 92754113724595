<template>
    <div class="accounts-main-section-container">
        <div class="accounts-main-header">
            <div class="accounts-main-title">
                Accounts
                <CSpinner
                  v-if="isFetchingInProgress"
                  size="sm"
                  color="info"/>
            </div>

            <CButton
                v-if="userCanCreateAccounts"
                size="sm"
                color="info"
                @click="showCreateAccountItem">
                + Create New Account
            </CButton>
        </div>

        <div class="account-list-wrapper">
            <template v-if="accounts.length">
              <CFormCheck
                label="Show disabled accounts"
                :checked="shouldShowDisabledAccounts"
                @change="shouldShowDisabledAccounts = $event"/>

              <AccountItem
                  v-for="(account, index) in filteredAccounts"
                  :key="index"
                  :account="account"/>
            </template>

            <div v-if="!accounts.length && !isFetchingInProgress" class="no-accounts-message">
              This Profile Has no Accounts
            </div>

            <CreateAccountItem v-if="userCanCreateAccounts && isCreateAccountItemDisplayed" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { features } from '@/config/features';
import AccountItem from '@/components/admin/accounts/all-accounts-view/AccountItem';
import CreateAccountItem from '@/components/admin/accounts/all-accounts-view/CreateAccountItem';
import userService from '@/services/UserService';

export default {
  name: 'AllAccounts',
  components: {
    AccountItem,
    CreateAccountItem,
  },
  data () {
    return {
      isCreateAccountItemDisplayed: false,
      shouldShowDisabledAccounts: false,
    };
  },
  computed: {
    ...mapState({
      accounts: state => state.accounts,
      isFetchingInProgress: state => state.isFetchingAccounts,
    }),
    userCanCreateAccounts () {
      return userService.hasFeatureAccess(features.CREATE_ACCOUNT);
    },
    filteredAccounts () {
      if (this.shouldShowDisabledAccounts) {
        return this.accounts;
      } else {
        return this.accounts.filter(account => account.isAccountEnabled);
      }
    },
  },
  methods: {
    showCreateAccountItem () {
      this.isCreateAccountItemDisplayed = true;
    },
    hideCreateAccountItem () {
      this.isCreateAccountItemDisplayed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .accounts-main-section-container {
        margin-top: $nav-item-height + $nav-border-width;
        min-width: 550px;
        max-width: 1000px;
        margin-right: 10px;
    }

    .accounts-main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $nav-item-height;
        font-weight: 700;
        font-size: 18px;

        button {
            color: var(--white);
        }
    }

    .account-list-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: $standard-border-width;
        justify-content: space-between;
    }

    .no-accounts-message {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
</style>

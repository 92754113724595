<template>
  <div class="wrapper">
    <div class="preview-content-container">
      <Preview
        v-if="cardData"
        :card-data="cardData"
        />

      <div
        v-else
        class="spinner-wrapper">
        <h2>LOADING CARD DETAILS</h2>
        <CSpinner
          color="info"/>
      </div>
    </div>
  </div>

  <div class="copyright-wrapper">
    <Copyright />
  </div>
</template>

<script>
import Preview from '@/views/PreviewV2';
import Copyright from '@/components/preview/Copyright';
import accountService from '@/services/AccountService';

export default {
  name: 'PreviewApp',
  components: {
    Preview,
    Copyright,
  },
  data () {
    return {
      cardData: null,
    };
  },
  mounted () {
    const id = window.location.pathname.split('/').pop();

    accountService.getCardTypeById(id)
      .then(response => {
        this.cardData = {
          ...response,
          link: id,
        };
      });
  },
};
</script>

<style lang="scss">
  html,
  body,
  #app {
    height: 100%;
  }
</style>

<style lang="scss">
  @import "~@coreui/coreui/scss/coreui";
  @import '@/assets/scss/colors';

  .wrapper {
    background-color: #fcfcfc;
    min-height: 100%;
  }

  .preview-content-container {
    text-align: center;
    overflow: auto;
    padding-bottom: 20px;
  }

  .copyright-wrapper {
    position: relative;
    margin-top: -20px;
    height: 20px;
    clear: both;
  }

  .spinner-wrapper {
    color: var(--tago-blue);
    position: absolute;
    top: 45%;
    width: 100%;
  }
</style>

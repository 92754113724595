class User {
  constructor (username, email, role, id) {
    this.username = username;
    this.email = email;
    this.role = role;
    this.id = id;
  }

  getUsername () {
    return this.username;
  }

  getEmail () {
    return this.email;
  }

  getRole () {
    return this.role;
  }

  getId () {
    return this.id;
  }
}

export default User;

<template>
    <div class="admin-view">
        <Nav/>

        <div class="admin-view-content">
            <router-view/>

            <div class="admin-copyright-placeholder" />

            <div class="admin-copyright">
                &copy; Tago Cards. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { features } from '@/config/features';
import userService from '@/services/UserService';
import Nav from '@/components/admin/navigation/Nav';

export default {
  name: 'Admin',
  components: {
    Nav,
  },
  methods: {
    ...mapActions([
      'fetchAccounts',
      'fetchAdminAccounts',
      'fetchCards',
      'fetchStatistics',
      'getAvailableLanguages',
    ]),
  },
  mounted () {
    if (userService.hasFeatureAccess(features.VIEW_ACCOUNTS)) {
      this.fetchAccounts();
    }

    if (userService.hasFeatureAccess(features.VIEW_ADMIN_ACCOUNTS)) {
      this.fetchAdminAccounts();
    }

    this.fetchStatistics();

    this.fetchCards();
    this.getAvailableLanguages();
  },
};
</script>

<style lang="scss">
    @import '@/assets/scss/colors';

    body {
        background-color: var(--body-color);
        overflow-x: hidden;
    }
</style>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';

    .admin-view-content {
        margin-left: 230px;
    }

    .admin-copyright-placeholder {
        height: 30px;
    }

    .admin-copyright {
        $font-size: 14px;
        $copyright-top-difference: $font-size * 2;

        width: 70%;
        height: 30px;
        text-align: center;
        background-color: var(--background-grey);
        position: fixed;
        top: calc(100vh - #{$copyright-top-difference});
        font-size: $font-size;
    }
</style>

<template>
  <div v-if="account" class="account-themes-section-container">
      <div class="account-themes-content-wrapper">
        <div class="account-themes-form-container">
            <CForm @submit.prevent="handleFormSubmit" autocomplete="off">
              <div class="account-themes-form-wrapper">
                <div class="account-themes-form-inputs">
                  <CRow class="mb-3">
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="font">
                          Font:
                      </CFormLabel>

                      <CCol sm="8">
                        <CFormSelect
                          :options="fonts"
                          :value="cardTypePlaceholder.themes.font"
                          @change="loadFont"/>
                      </CCol>
                  </CRow>

                  <CRow class="mb-3">
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="font-color">
                          Font color:
                      </CFormLabel>

                      <CCol sm="2">
                        <CFormInput
                          type="color"
                          :value="cardTypePlaceholder.themes.fontColor"
                          @input="handleFontColorInputChange"/>
                      </CCol>

                      <CCol
                        sm="6">
                        <CFormInput
                          readonly
                          :value="cardTypePlaceholder.themes.fontColor" />
                      </CCol>
                  </CRow>

                  <CRow class="mb-3">
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="header-background-color">
                          Theme color:
                      </CFormLabel>

                      <CCol sm="2">
                        <CFormInput
                          type="color"
                          :value="cardTypePlaceholder.themes.headerBackgroundColor"
                          @input="handleThemeColorInputChange"/>
                      </CCol>

                      <CCol
                        sm="6">
                        <CFormInput
                          readonly
                          :value="cardTypePlaceholder.themes.headerBackgroundColor" />
                      </CCol>
                  </CRow>

                  <div
                    class="textures-container mb-3">
                      <div class="texture-label">
                        Texture:
                      </div>

                      <div
                        v-for="(texture, index) in textures"
                        :key="index"
                        class="texture-item"
                        :class="cardTypePlaceholder.themes.headerBackgroundTexture === texture.name ? 'selected' : ''"
                        @click="handleTextureChange(texture)">
                        <div
                          class="texture-field-wrapper"
                          :style="`background-color:${cardTypePlaceholder.themes.headerBackgroundColor}`">
                          <img
                            class="texture-field"
                            :src="getImageUrl(texture)" />

                            <CButton
                              v-if="texture.isUserUploaded"
                              class="texture-delete"
                              size="sm"
                              type="button"
                              @click.stop="handleDeleteTexture(texture)">
                              <font-awesome-icon :icon="['far', 'trash-alt']" />
                            </CButton>
                        </div>
                      </div>
                  </div>

                  <CForm @submit.prevent="handleUploadTexture">
                    <CRow>
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="custom-texture">
                          Upload texture:
                      </CFormLabel>
                    </CRow>

                    <CRow>
                      <CCol sm="9">
                        <CFormInput
                          type="file"
                          id="custom-texture"
                          name="custom-texture" />
                      </CCol>

                      <CCol sm="3">
                        <CButton
                          class="upload-texture-button"
                          color="info"
                          size="sm"
                          type="submit">
                          Upload
                      </CButton>
                      </CCol>
                    </CRow>
                    <p>Accepted Format: 800x300 .png</p>
                  </CForm>

                  <CRow class="mb-3">
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="font">
                          Icon Shape:
                      </CFormLabel>

                      <CCol sm="8">
                        <CFormSelect
                          :options="['standard', 'semi-rounded', 'rounded', 'shape-1']"
                          :value="cardTypePlaceholder.themes.iconShape"
                          @change="handleIconShapeChange"/>
                      </CCol>
                  </CRow>

                  <CRow class="mb-3">
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="icon-color">
                          Icon color:
                      </CFormLabel>

                      <CCol sm="2">
                        <CFormInput
                          type="color"
                          :value="cardTypePlaceholder.themes.iconColor"
                          @input="handleIconColorChange"/>
                      </CCol>

                      <CCol
                        sm="6">
                        <CFormInput
                          readonly
                          :value="cardTypePlaceholder.themes.iconColor" />
                      </CCol>
                  </CRow>

                  <CRow class="mb-3">
                      <CFormLabel
                          class="col-sm-4 col-form-label"
                          for="button-color">
                          Button color:
                      </CFormLabel>

                      <CCol sm="2">
                        <CFormInput
                          type="color"
                          :value="cardTypePlaceholder.themes.buttonColor"
                          @input="handleButtonColorInputChange"/>
                      </CCol>

                      <CCol
                        sm="6">
                        <CFormInput
                          readonly
                          :value="cardTypePlaceholder.themes.buttonColor" />
                      </CCol>
                  </CRow>
                </div>

                <div class="mobile-preview mb-3">
                  <Preview :card-data="cardTypePlaceholder" />
                </div>

              </div>

              <CRow class="col-sm-12 ml-3 mb-3 action-buttons">
                  <CButton
                      color="info"
                      size="sm"
                      variant="outline"
                      @click="handleCancel">
                      Cancel
                  </CButton>

                  <CButton
                      class="save-button"
                      color="info"
                      size="sm"
                      type="submit">
                      Save
                  </CButton>
              </CRow>
            </CForm>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { routeNames } from '@/config/routes';
import { endpoints } from '@/config/endpoints';
import FontService from '@/services/FontService';
import WebFont from 'webfontloader';
import Preview from '@/views/PreviewV2';

export default {
  name: 'AccountThemes',
  props: {
    account: Object,
  },
  components: {
    Preview,
  },
  data () {
    return {
      headerBackgroundTexture: '',
      fonts: ['Default', 'Effra'],
      alreadyLoadedFonts: ['Default', 'Effra'],
      cardTypePlaceholder: {
        cardId: '123',
        fullName: 'Name Surname',
        title: 'Position',
        company: 'Company Name',
        about: 'Text about card user.',
        twitter: 'https://tagocards.com/',
        linkedin: 'https://tagocards.com/',
        facebook: 'https://tagocards.com/',
        instagram: 'https://tagocards.com/',
        themes: {
          font: '',
          fontColor: '#2c384af2',
          headerBackgroundColor: '#00A4EF',
          headerBackgroundTexture: 'header-texture-3',
          iconShape: 'standard',
          iconColor: '#2c384af2',
          buttonColor: '#00A4EF',
        },
        themeLocationId: this.account.accountId,
      },
      selectedTexture: {
        name: 'header-texture-1',
        isUserUploaded: false,
        exists: true,
      },
      defaultTextures: [...Array(11).keys()].map((_, index) => ({
        name: `header-texture-${index + 1}`,
        isUserUploaded: false,
        exists: true,
      })),
      userUploadedTextures: [...Array(5).keys()].map((_, index) => ({
        name: `custom-header-texture-${index + 1}`,
        isUserUploaded: true,
        exists: false,
      })),
    };
  },
  created () {
    FontService.getAvailableFonts()
      .then(data => {
        this.fonts = [...this.fonts, ...data.items.map(font => ({
          label: font.family,
          value: font.family,
        }))];
      });
  },
  mounted () {
    this.validateTextures();
  },
  computed: {
    textures () {
      return [...this.defaultTextures, ...this.userUploadedTextures].filter(texture => texture.exists);
    },
  },
  watch: {
    'account.themes': {
      handler (value) {
        this.cardTypePlaceholder.themes.font = value.font;
        this.cardTypePlaceholder.themes.fontColor = value.fontColor;
        this.cardTypePlaceholder.themes.headerBackgroundColor = value.headerBackgroundColor;
        this.cardTypePlaceholder.themes.headerBackgroundTexture = value.headerBackgroundTexture;
        this.cardTypePlaceholder.themes.iconShape = value.iconShape;
        this.cardTypePlaceholder.themes.iconColor = value.iconColor;
        this.cardTypePlaceholder.themes.buttonColor = value.buttonColor;

        this.selectedTexture = {
          name: value.headerBackgroundTexture,
          isUserUploaded: value.headerBackgroundTexture.includes('custom'),
          exists: true,
        };
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['updateAccountThemes', 'uploadTexture', 'deleteTexture']),
    ...mapMutations(['addAlert']),
    handleCancel () {
      this.$router.push({
        name: routeNames.ALL_ACCOUNTS,
      });
    },
    handleFormSubmit () {
      this.updateAccountThemes({
        account: {
          accountId: this.account.accountId,
          themes: {
            font: this.cardTypePlaceholder.themes.font,
            fontColor: this.cardTypePlaceholder.themes.fontColor,
            headerBackgroundTexture: this.cardTypePlaceholder.themes.headerBackgroundTexture,
            headerBackgroundColor: this.cardTypePlaceholder.themes.headerBackgroundColor,
            iconShape: this.cardTypePlaceholder.themes.iconShape,
            iconColor: this.cardTypePlaceholder.themes.iconColor,
            buttonColor: this.cardTypePlaceholder.themes.buttonColor,
          },
        },
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Account Login Details Edited Successfully',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Account Login Details Edit Failed',
            },
          });
        });
    },
    handleUploadTexture () {
      this.uploadTexture({
        account: {
          accountId: this.account.accountId,
        },
      }).then(() => {
        this.addAlert({
          alert: {
            type: 'info',
            message: 'Texture Uploaded Successfully',
          },
        });
        this.validateTextures();
      }).catch(() => {
        this.addAlert({
          alert: {
            type: 'danger',
            message: 'Texture Upload Failed',
          },
        });
      });
    },
    handleDeleteTexture (texture) {
      if (window.confirm('Are you sure you want to delete this texture?')) {
        this.deleteTexture({
          account: {
            accountId: this.account.accountId,
          },
          textureName: `${texture.name}.png`,
        }).then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Texture Deleted Successfully',
            },
          });
          this.validateTextures();
        }).catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Texture Delete Failed',
            },
          });
        });
      }
    },
    handleFontColorInputChange (value) {
      this.cardTypePlaceholder.themes.fontColor = value;
    },
    handleThemeColorInputChange (value) {
      this.cardTypePlaceholder.themes.headerBackgroundColor = value;
    },
    handleIconShapeChange (value) {
      this.cardTypePlaceholder.themes.iconShape = value;
    },
    handleIconColorChange (value) {
      this.cardTypePlaceholder.themes.iconColor = value;
    },
    handleButtonColorInputChange (value) {
      this.cardTypePlaceholder.themes.buttonColor = value;
    },
    handleTextureChange (texture) {
      this.cardTypePlaceholder.themes.headerBackgroundTexture = texture.name;
      this.selectedTexture = texture;
    },
    loadFont (value) {
      this.cardTypePlaceholder.themes.font = value;

      if (!this.alreadyLoadedFonts.includes(value)) {
        WebFont.load({
          google: {
            families: [value],
          },
          loading: () => this.alreadyLoadedFonts.push(value),
        });
      }
    },
    getImageUrl (texture) {
      if (texture.isUserUploaded) {
        return `${endpoints.storage}/${this.account.accountId}/${texture.name}.png`;
      }

      const images = require.context('@/assets/images', false, /\.png$/);
      return images('./' + texture.name + '.png');
    },
    validateTextures () {
      this.userUploadedTextures.forEach(texture => {
        if (!texture.exists) {
          const img = new Image();

          img.onload = () => {
            texture.exists = true;
          };

          img.src = `${endpoints.storage}/${this.account.accountId}/${texture.name}.png`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/sidebar';
    @import '@/assets/scss/colors';

    .account-themes-section-container {
        margin-top: $nav-border-width;
        max-width: 860px;
        margin-right: 10px;
    }

    .account-themes-content-wrapper {
        display: flex;
        flex-direction: column;
        grid-gap: $standard-border-width;
        justify-content: space-between;
    }

    .account-themes-form-container {
        margin-top: 20px;

        select {
            width: 100%;
        }
    }

    .account-themes-form-wrapper {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
    }

    .action-buttons {
        justify-content: center;
        text-align: center;
        grid-gap: 15px;

        button {
            width: 70px;
        }

        .save-button {
            color: var(--white);
        }
    }

    .upload-texture-button {
      width: 100%;
      height: 100%;
      color: var(--white);
    }

    .account-themes-form-inputs {
      width: 420px;

      p {
        font-size: 12px;
        padding: 5px;
      }
    }

    .mobile-preview {
      border: 5px solid var(--white);
      border-radius: 15px;
      width: 420px;
      height: 100%;
      overflow: hidden;
    }

    .textures-container {
      align-items: center;
      display: grid;
      grid-gap: 5px;
      grid-template-columns: max-content;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .texture-field-wrapper {
      height: 50px;
      overflow: hidden;
      border-radius: 5px;
      position: relative;
    }

    .texture-delete {
      position: absolute;
      bottom: 1px;
      right: 2px;

      &:hover {
        background-color: rgb(229, 83, 83);
      }
    }

    .texture-item img {
      height: 60px;
    }

    .selected {
      box-sizing: content-box;
      box-shadow: 0px 0px 0px 2px blue;
      border-radius: 5px;
    }

    .texture-field {
      mix-blend-mode: luminosity;
      overflow: hidden;
      width: 100%;
    }

    .texture-checkbox-wrapper {
      display: flex;
      justify-content: end;
      align-items: center;
    }
</style>

<template>
    <div class="card-android-container">
      <CRow class="mb-3">
        <CFormLabel
          class="col-form-label">
          Color (hex):
        </CFormLabel>

        <CCol class="col-sm-8 card-android-wrapper">
          <CFormInput
            readonly
            :value="color"/>
        </CCol>

        <CButton
          class="col-sm-3"
          color="info"
          size="sm"
          @click="handleCardSave">
            Save
        </CButton>

        <CFormInput
          type="color"
          :value="color"
          @input="handleColorInputChange"/>
      </CRow>
    </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
  name: 'CardAndroid',
  data () {
    return {
      color: '#ffffff',
    };
  },
  props: {
    card: Object,
  },
  methods: {
    ...mapMutations(['addAlert']),
    ...mapActions(['saveAndroidCard']),
    handleCardSave () {
      this.saveAndroidCard({
        cardId: this.card.cardId,
        color: this.color,
      })
        .then(() => {
          this.addAlert({
            alert: {
              type: 'info',
              message: 'Mail with Google Wallet token sent',
            },
          });
        })
        .catch(() => {
          this.addAlert({
            alert: {
              type: 'danger',
              message: 'Save failed',
            },
          });
        });
    },
    handleColorInputChange (value) {
      this.color = value;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/colors';

  .card-android-container {
    max-width: 700px;
    background-color: var(--white);
    padding: 30px 80px 10px;

    button {
      color: var(--white),
    }
  }

  .col-form-label {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
  }

  .card-android-wrapper {
    padding: 0;
    margin-right: 10px;

    input {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 10px;
      background-color: var(--background-grey);
      border-radius: 10px;
    }
  }

  input[type=color] {
    margin-top: 10px;
  }
</style>

<template>
    <div class="statistics-table-section-container">
        <div class="statistics-header">
            <div class="statistics-title">
                <router-link :to="{ name: routeNames.STATISTICS }">◄</router-link>
                {{ title }}
            </div>

            <img
              src="~@/assets/icons/refresh-icon.png"
              class="refresh"
              variant="ghost"
              color="info"
              @click="handleReloadData" />
        </div>

        <CRow>
          <CCol>
            <div class="actions-wrapper">
              <div
                v-if="isEditMode"
                class="edit-mode-wrapper">
                <CButton
                  variant="ghost"
                  color="info"
                  @click="handleSelectAllEditMode">
                  Select All
                </CButton>

                <CButton
                  variant="ghost"
                  color="info"
                  @click="handleCancelEditMode">
                  Cancel
                </CButton>

                <CButton
                  variant="outline"
                  color="info"
                  @click="handleApplyEditMode">
                  Apply
                </CButton>
              </div>

              <div>
                <CButton
                  v-if="!isEditMode"
                  variant="outline"
                  color="info"
                  @click="toggleEditMode(true)">
                  Edit Table
                </CButton>
              </div>

              <div>
                <a :href="saveExcelLink" target="_blank">
                  <CButton
                    variant="outline"
                    color="info">
                    Save Excel
                  </CButton>
                </a>
              </div>
            </div>

            <table>
              <thead>
                <th v-if="isEditMode" />
                <th />

                <th
                v-for="(column, index) in visibleColumns"
                :key="index">
                  {{ statisticsTableColumnsConfig[column].label }} <span v-c-tooltip="statisticsTableColumnsConfig[column].tooltip">&#128712;</span></th>
              </thead>

              <thead v-if="isEditMode">
                <th />
                <th />

                <th
                  class="filter-cell"
                  v-for="(column, index) in visibleColumns"
                  :key="index">
                  <CFormCheck
                    :checked="filteredColumns.includes(column)"
                    @change="toggleColumnVisibility(column, $event)"/>
                </th>
              </thead>

              <tbody>
                <tr
                  v-for="(data, index) in filteredStatisticData"
                  :key="index">
                  <td
                    class="filter-cell"
                    v-if="isEditMode">
                    <CFormCheck
                      :ref="`checkbox-${data.id}`"
                      :checked="filteredEntries.includes(data.id)"
                      @change="toggleEntryVisibility(data.id, $event)"/>
                  </td>

                  <td
                    class="name-cell"
                    v-text="data.name" />

                  <td
                    v-for="(column, index) in visibleColumns"
                    :key="index"
                    v-text="data.statistics[statisticsTableColumnsConfig[column].key]" />
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
    </div>
</template>

<script>
import { routeNames } from '@/config/routes';
import { mapActions } from 'vuex';
import { statisticsTableColumnsConfig } from '@/config/statistics.js';

export default {
  name: 'StatisticsTable',
  props: {
    title: String,
    statisticData: Array,
    entryType: String,
  },
  data () {
    return {
      statisticsTableColumnsConfig,
      routeNames,
      isEditMode: false,
      filteredEntries: null,
      filteredEntriesCache: [],
      allColumns: [
        statisticsTableColumnsConfig.profileTotal.key,
        statisticsTableColumnsConfig.saved.key,
        statisticsTableColumnsConfig.shared.key,
        statisticsTableColumnsConfig.card.key,
        statisticsTableColumnsConfig.virtago.key,
        statisticsTableColumnsConfig.sticker.key,
        statisticsTableColumnsConfig.mobileRing.key,
        statisticsTableColumnsConfig.petago.key,
        statisticsTableColumnsConfig.other.key,
      ],
      filteredColumnsCache: [],
      filteredColumns: null,
    };
  },
  computed: {
    filteredStatisticData () {
      if (this.isEditMode || !this.filteredEntries) {
        return this.statisticData;
      }

      return this.statisticData ? this.statisticData.filter(el => this.filteredEntries.includes(el.id)) : [];
    },
    saveExcelLink () {
      const entries = this.filteredEntries || this.statisticData.map(el => el.id);

      const baseUrl = process?.env?.VUE_APP_SERVER_URL ?? '';

      return `${baseUrl}/api/statistics/excel/${this.entryType}?columns=${this.visibleColumns.join(',')}&ids=${entries.join(',')}`;
    },
    visibleColumns () {
      if (this.isEditMode || !this.filteredColumns) {
        return this.allColumns;
      }

      return this.filteredColumns;
    },
  },
  methods: {
    ...mapActions([
      'fetchStatistics',
    ]),
    toggleEditMode (val) {
      if (val && !this.filteredEntries) {
        this.filteredEntries = this.statisticData.map(el => el.id);
      }

      if (val && !this.filteredColumns) {
        this.filteredColumns = [...this.allColumns];
      }

      if (val) {
        this.filteredEntriesCache = [...this.filteredEntries];
        this.filteredColumnsCache = [...this.filteredColumns];
      }

      this.isEditMode = val;
    },
    toggleEntryVisibility (id, isVisible) {
      const entryIndex = this.filteredEntries.indexOf(id);

      if ((entryIndex === -1) && isVisible) {
        this.filteredEntries.push(id);
      } else if ((entryIndex !== -1) && !isVisible) {
        this.filteredEntries.splice(entryIndex, 1);
      }
    },
    toggleColumnVisibility (key, isVisible) {
      const columnIndex = this.filteredColumns.indexOf(key);

      if ((columnIndex === -1) && isVisible) {
        this.filteredColumns.push(key);
      } else if ((columnIndex !== -1) && !isVisible) {
        this.filteredColumns.splice(columnIndex, 1);
      }
    },
    handleSelectAllEditMode () {
      this.filteredEntries = this.statisticData.map(el => {
        this.$refs[`checkbox-${el.id}`].$el.checked = true;
        return el.id;
      });
    },
    handleCancelEditMode () {
      this.filteredEntries = [...this.filteredEntriesCache];
      this.filteredColumns = [...this.filteredColumnsCache];

      this.toggleEditMode(false);
    },
    handleApplyEditMode () {
      this.toggleEditMode(false);
    },
    handleReloadData () {
      this.fetchStatistics();
    },
  },
};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/colors';
    @import '@/assets/scss/sidebar';

    .statistics-table-section-container {
        margin-top: $nav-item-height + $nav-border-width;
        min-width: 550px;
        max-width: 1000px;
        margin-right: 10px;
    }

    .statistics-header {
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: $nav-item-height;
        font-weight: 700;
        font-size: 18px;
    }

    .statistics-title a {
      text-decoration: none;
      color: var(--tago-blue);
    }

    table {
      text-align: center;
      width: 100%;
      border-collapse: separate;
      border-spacing: 2px;
    }

    th {
      user-select: none;
      opacity: .6;
      font-size: 12px;
      font-weight: 400;

      span {
        cursor: pointer;
      }
    }

    .filter-cell {
      padding: 5px;
      width: 10px;
    }

    .name-cell {
      text-align: left;
      width: 200px;
    }

    td {
      padding: 12px 10px 10px;
      background-color: white;
      font-size: 14px;
    }

    .profile-views-checkbox {
      margin-top: 10px;
    }

    .edit-mode-wrapper {
      margin: 0;

      button {
        margin-right: 10px;
      }
    }

    .actions-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .refresh {
      width: 20px;
      margin-right: 10px;
      border: none;
      cursor: pointer;
      transition: transform .5s ease-in-out;

      &:hover {
        transform: rotate(180deg);
      }
    }
</style>
